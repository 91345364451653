import React, { Dispatch, SetStateAction } from 'react';
import * as s from './NewsStyled';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface NewsProps {
  items: any[],
  page: number,
  setPage: Dispatch<SetStateAction<number>>;
  handleModal: (item: any) => void;
  dvCd: string;
  setDvCd: Dispatch<SetStateAction<string>>;
  texts: {
    news_topSubtitle: string,
    Industry: string,
    Culture: string,
  }
}

const NewsPresenter = (props: NewsProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/403/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>DOUBLT NEWS</s.TopTitle>
          <s.TopSubtitle>{props.texts.news_topSubtitle}</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>
      <s.FilterArea>
        <s.FilterItem active={props.dvCd === ''} onClick={() => {props.setDvCd('');}}>ALL</s.FilterItem>
        <s.FilterItem active={props.dvCd === 'business'} onClick={() => {props.setDvCd('business');}}>{props.texts.Industry}</s.FilterItem>
        <s.FilterItem active={props.dvCd === 'culture'} onClick={() => {props.setDvCd('culture');}}>{props.texts.Culture}</s.FilterItem>
      </s.FilterArea>
      <s.ItemArea>
        {
          props.items.map((item: any, index: number) => {
            return (
              <s.Item heightSeed={4} onClick={() => props.handleModal(item)}>
                {
                  moment(item.news_reg_dt).isAfter(moment(new Date()).add(-1, 'days')) ?
                    <s.NewItemWrapper>
                      <s.NewItem>NEW</s.NewItem>
                      <s.RibbonBottom/>
                    </s.NewItemWrapper>
                  : null
                }
                <s.ItemContentWrapper>
                <s.ItemDate>{moment(item.news_reg_dt).format('YY.MM')}</s.ItemDate>
                <s.ItemDay>{moment(item.news_reg_dt).format('DD')}</s.ItemDay>
                {
                  item.thumbnail ? 
                  <s.ItemInnerContent>
                    
                    <s.ItemThumbnail src={item.thumbnail}/>
                    <s.ItemTitle>{item.title}</s.ItemTitle>
                  </s.ItemInnerContent>
                  : 
                  <s.ItemInnerContent style={{justifyContent: 'center'}}>
                    <s.ItemTitle>{item.title}</s.ItemTitle>
                    <s.ItemContent>{item.prevContent}</s.ItemContent>
                  </s.ItemInnerContent>
                }
                </s.ItemContentWrapper>
              </s.Item>
              )
          })
        }
      </s.ItemArea>
      <s.ItemMoreWrapper>
          <s.ItemMore onClick={() => {props.setPage(props.page + 1);}}>MORE</s.ItemMore>
      </s.ItemMoreWrapper>
    </s.Container>
  );
};

export default NewsPresenter;
