import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
  useCallback,
} from "react";
import { projectApi } from "../../api/api-project";

import MainPresenter from "./MainPresenter";

import * as util from "../../util/util";
import { useRecoilValue, useRecoilState } from "recoil";

import * as recoilItem from "../../util/recoilitem";
import { qnaApi } from "../../api/api-qna";

import korean from '../../util/korean.json';
import english from '../../util/english.json';

interface MainProps {
  setShowExplainArea: Dispatch<SetStateAction<boolean>>;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setModalItem: Dispatch<SetStateAction<any>>;
  setModalPicture: Dispatch<SetStateAction<any[]>>;
}

const MainContainer = (props: MainProps) => {
  const [currentCarousel, setCurrentCarousel] = useState<number>(0);
  const [projects, setProjects] = useState<any>([]);

  const userInfo = useRecoilValue(recoilItem.userInfo);
  const [userNm, setUserNm] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const [currentBusinessFocus, setCurrentBusinessFocus] = useState<number>(-1);
  const [scroll, setScroll] = useState(0);
  const [openReference, setOpenReference] = useState(false);
  const [openBusiness, setOpenBusiness] = useState(false);
  const [openPartners, setOpenPartners] = useState(false);

  const referenceRef = useRef<HTMLDivElement>(null);
  const businessRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);

  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    main_Missing_Project: '',
    main_Registration_success: '',
    main_Registration_fail: '',
    main_carouselItems_description1: '',
    main_carouselItems_description2: '',
    main_carouselItems_description3: '',
    main_carouselItems_description4: '',
    main_view_projects: '',
    main_view_Employee_Benefits: '',
    Physical_Security_Development: '',
    Development_of_Industrial_Safety_AI_System: '',
    IT_Infra: '',
    jikgu: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);  

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScroll(position);
  };

  useEffect(() => {
    // getProjects();

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (referenceRef.current) {
      if (
        scroll >=
          referenceRef.current.offsetTop -
            referenceRef.current.offsetHeight / 3 &&
        !openReference
      ) {
        setOpenReference(true);
      }
    }

    if (businessRef.current) {
      if (
        scroll >=
          businessRef.current.offsetTop -
            businessRef.current.offsetHeight / 3 &&
        !openBusiness
      ) {
        setOpenBusiness(true);
      }
    }

    if (partnersRef.current) {
      if (
        scroll >=
          partnersRef.current.offsetTop -
            partnersRef.current.offsetHeight / 3 &&
        !openPartners
      ) {
        setOpenPartners(true);
      }
    }
  }, [scroll]);

  // const getProjects = async () => {
  //   let params = {
  //     page: '0'
  //   };

  //   let result = await projectApi.getProjectList(params);
  //   if (result.data?.data.rsltCd === '00') {
  //     let count = result.data.data.list.length > 8 ? 8 : result.data.data.list.length;
  //     let rsltList = [];
  //     for (let i = 0; i < count; i++) {
  //       rsltList.push(result.data.data.list[i]);
  //     }
  //     setProjects([...rsltList]);
  //   }
  // }

  const handleModal = async (item: any) => {
    let params = {
      referenceId: item.reference_id,
    };

    let result = await projectApi.getProjectDetail(params);
    if (result.data?.data.rsltCd === "00") {
      props.setModalPicture(
        result.data.data.item.images.map((item: any) => item.image_path)
      );
      props.setModalItem(result.data.data.item);
      props.setModalVisible(true);
    } else {
      util.makeMsg(
        `${texts.main_Missing_Project}`,
        "error"
      );
    }
  };

  const registerQna = async () => {
    let params: any = {};

    if (userInfo.userNm && userInfo.userEmail) {
      params.userNm = userInfo.userNm;
      params.userEmail = userInfo.userEmail;
    } else {
      params.userNm = userNm;
      params.userEmail = userEmail;
    }

    params.content = content;

    let res = await qnaApi.addQna(params);
    if (res.data?.data.rsltCd === "00") {
      util.makeMsg(
        `${texts.main_Registration_success}`,
        "success"
      );
      setUserNm("");
      setUserEmail("");
      setContent("");
    } else {
      util.makeMsg(`${texts.main_Registration_fail}`, "error");
    }
  };

  return (
    <>
      <MainPresenter
        currentCarousel={currentCarousel}
        setCurrentCarousel={setCurrentCarousel}
        projects={projects}
        handleModal={handleModal}
        userInfo={userInfo}
        userNm={userNm}
        setUserNm={setUserNm}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        content={content}
        setContent={setContent}
        registerQna={registerQna}
        currentBusinessFocus={currentBusinessFocus}
        setCurrentBusinessFocus={setCurrentBusinessFocus}
        scroll={scroll}
        referenceRef={referenceRef}
        businessRef={businessRef}
        partnersRef={partnersRef}
        openReference={openReference}
        openBusiness={openBusiness}
        openPartners={openPartners}
        texts={texts}
      />
    </>
  );
};

MainContainer.defaultProps = {};

export default MainContainer;
