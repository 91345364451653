import React, { ReactChild, useEffect, useState } from 'react'
import { FaArrowRight, FaCaretLeft, FaCaretRight, FaChevronLeft, FaChevronRight, FaCross } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import korean from '../../util/korean.json';
import english from '../../util/english.json';
import { useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';

interface ReferenceModalProps {
  isOpen: boolean,
  onClose: any,
  showExplainArea: boolean,
  pictures: any[] // base64 string array
  title?: any,
  content?: any,
}

const ReferenceModal = (props: ReferenceModalProps) => {
  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    company_name: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  return (
    <ReferenceModalStyle isOpen={props.isOpen} onClick={props.onClose}>
      <ModalInner onClick={(e) => {e.stopPropagation()}}>
        <CloseButton onClick={props.onClose}><MdClose size={'100%'} color={'#FFFFFF'}/></CloseButton>
        <ImageArea>
          {
            props.pictures.length === 1 ?
            <ImageWrapper>
              <ImageItem src={props.pictures[0]}/>
            </ImageWrapper>
            : props.pictures.length > 1 ?
            <Carousel dynamicHeight={true}
                      autoPlay={true} 
                      infiniteLoop={true} 
                      showThumbs={false} 
                      swipeable={true} 
                      stopOnHover={false}
                      swipeScrollTolerance={15}
                      emulateTouch={true}
                      showStatus={false}
                      showArrows={true}
                      renderArrowPrev={(clickHandler, hasNext, label) => {
                        return (
                          <ButtonLeftArea>
                            <FaChevronLeft 
                              onClick={clickHandler} 
                              size={'100%'} 
                              color={'#1A2D56'}/>
                          </ButtonLeftArea>
                        )
                      }}
                      renderArrowNext={(clickHandler, hasNext, label) => {
                        return (
                          <ButtonRightArea>
                            <FaChevronRight 
                              onClick={clickHandler} 
                              size={'100%'} 
                             color={'#1A2D56'}/>
                          </ButtonRightArea>
                        )
                      }}
                      interval={5000}>
              {
                props.pictures.map((item: any) => {
                  return (
                    <ImageWrapper>
                      <ImageItem src={item}/>
                    </ImageWrapper>
                  )
                })
              }
            </Carousel>
            : null
          }
        </ImageArea>
        {
          props.showExplainArea ?
          <ExplainArea>
            <ExplainTitle>{props.title}</ExplainTitle>
            <ExplainAuthor>
              <ExplainAuthorImg src={'https://picsum.photos/400'}/>
              <ExplainAuthorName>{texts.company_name}</ExplainAuthorName>
            </ExplainAuthor>
            <ExplainContent readOnly value={props.content}/>
          </ExplainArea>
          : null
        }
      </ModalInner>
    </ReferenceModalStyle>
  )
}

const CloseButton = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
    z-index: 999999;
  }
`;

const ReferenceModalStyle = styled.div<{isOpen: boolean}>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  z-index: 999999999;
`;

const ModalInner = styled.div`
  width: auto;
  height: auto;
  overflow: hidden;
  background-color: #000000;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1.5px solid #4D4D4D;
  box-shadow: 0 0 3rem #1A2D56;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageArea = styled.div`
  width: 67vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 30vh;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;


const ImageItem = styled.img`
  object-fit: contain;
  width: 65vw;
  height: 68vh;

  @media screen and (max-width: 768px) {
    width: 85vw;
    height: 25vh;
  }
`;

const ExplainArea = styled.div`
  width: 23vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  padding: 2rem;

  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 45vh;
  }
`;

const ButtonRightArea = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  z-index: 999;
  
  @media screen and (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ButtonLeftArea = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  left: 1rem;
  top: 50%;
  z-index: 999;

  @media screen and (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ExplainTitle = styled.div`
  width: 100%;
  font-size: 3.4rem;
  overflow: visible;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const ExplainAuthor = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const ExplainAuthorImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 5rem;
  border: 1px solid #005670;
  box-shadow: 0 0 1rem #1A2D56;

  @media screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 1rem;
  }
`;

const ExplainAuthorName = styled.div`
  font-size: 1.6rem;
  color: #FFFFFF;
  margin: 0 1rem;
  font-family: 'GMarketSans';

  @media screen and (max-width: 768px) {
    position: absolute;
    right: 4rem;
    margin: 0;
    font-size: 1.2rem;
  }
`;

const ExplainContent = styled.textarea`
  resize: none;
  width: 100%;
  min-height: 50%;
  font-size: 1.6rem;
  color: #FFFFFF;
  padding: 3rem 1rem;
  cursor: default;
  border: 0;
  caret-color: transparent;
  background-color: transparent;
  box-shadow: 0 -0.2rem 0.3rem -0.1rem #1A2D56;
  border-top: 1px solid #1A2D56;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 2rem 0;
    margin: 0.5rem 0;
    border-top: 0.5px solid #1A2D56;
  }
`;

const ExplainContentBorder = styled.div`
  display: flex;
  width: 100%;
  height: 0.1rem;
  background-color: #FFFFFF;
  
`;

export default ReferenceModal