import React, { Dispatch, SetStateAction } from 'react';
import * as s from './NewsCreateStyled';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MdClose } from 'react-icons/md';

interface NewsCreateProps {
  thumbnail: any;
  setThumbnail: Dispatch<SetStateAction<any>>;
  categoryId: number;
  setCategoryId: Dispatch<SetStateAction<number>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  content: any;
  setContent: Dispatch<SetStateAction<any>>;
  thumbnailPreview: string;
  dvCd: string;
  setDvCd: Dispatch<SetStateAction<string>>;
  newsRegDt: string;
  setNewsRegDt: Dispatch<SetStateAction<string>>;


  removeThumbnail: () => void;

  modules: any;
  formats: any;

  submit: () => void;
}

const NewsCreatePresenter = (props: NewsCreateProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/1076/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>NEWS CREATE</s.TopTitle>
          <s.TopSubtitle>관리자페이지입니다.</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>
      <s.CreateNewsArea>
        <s.CreateNewsTable>
          <colgroup>
            <col width={'15%'}/>
            <col width={'35%'}/>
            <col width={'15%'}/>
            <col width={'35%'}/>
          </colgroup>
          <s.CreateNewsTbody>
            <s.CreateNewsTr>
              <s.CreateNewsTh>썸네일</s.CreateNewsTh>
              <s.CreateNewsTd colSpan={3}>
              <s.DeleteThumbnail onClick={props.removeThumbnail}><MdClose size={'100%'} color={'#FFFFFF'}/></s.DeleteThumbnail>
                <s.CreateNewsThumbnail src={props.thumbnailPreview}/>
                <s.CreateNewsInput id="thumb" type="file" style={{border: 0}} onChange={(e) => {e.target.files && props.setThumbnail(e.target.files[0])}} placeholder="썸네일 파일을 업로드하세요." accept={'.jpg,.jpeg,.png,.gif'}/>
              </s.CreateNewsTd>
            </s.CreateNewsTr>
            <s.CreateNewsTr>
              <s.CreateNewsTh>뉴스제목</s.CreateNewsTh>
              <s.CreateNewsTd colSpan={3}>
                <s.CreateNewsInput type="text" placeholder='프로젝트 명을 입력하세요' value={props.title} onChange={(e) => {props.setTitle(e.target.value)}}/>
              </s.CreateNewsTd>
            </s.CreateNewsTr>
            <s.CreateNewsTr>
              <s.CreateNewsTh>카테고리</s.CreateNewsTh>
              <s.CreateNewsTd >
                <s.CreateNewsSelect value={props.dvCd} onChange={(e) => props.setDvCd(e.target.value)}>
                  <option value="article">관련기사</option>
                  <option value="notice">공지</option>
                </s.CreateNewsSelect>
              </s.CreateNewsTd>
              <s.CreateNewsTh>뉴스등록일(YYYYMMDD)</s.CreateNewsTh>
              <s.CreateNewsTd>
                <s.CreateNewsInput type="text" placeholder='20220101' value={props.newsRegDt} onChange={(e) => {props.setNewsRegDt(e.target.value)}}/>
              </s.CreateNewsTd>
            </s.CreateNewsTr>
            <s.CreateNewsTr>
              <s.CreateNewsTh>뉴스 내용</s.CreateNewsTh>
              <s.CreateNewsTd colSpan={3}>
                <s.QuillWrapper>
                  <ReactQuill 
                      style={{height: "100%"}} 
                      theme="snow"
                      modules={props.modules} 
                      formats={props.formats} 
                      value={props.content || ''} 
                      onChange={(content, delta, source, editor) => props.setContent(editor.getHTML())} />
                </s.QuillWrapper>
              </s.CreateNewsTd>
            </s.CreateNewsTr>
          </s.CreateNewsTbody>

        </s.CreateNewsTable>
      </s.CreateNewsArea>
      <s.ButtonWrapper>
        <s.SubmitButton onClick={props.submit}>등록</s.SubmitButton>
        <s.ToListButton to="/admin/news">목록</s.ToListButton>
      </s.ButtonWrapper>
    </s.Container>
  );
};

export default NewsCreatePresenter;
