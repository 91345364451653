import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const slideUp = keyframes`
  0% {
    padding-top: 6rem;
  }

  30% {
    padding-top: 0rem;
  }
`;

export const slideUpShort = keyframes`
  0% {
    padding-top: 2rem;
  }

  20% {
    padding-top: 0rem;
  }
`;

export const borderBottomLeftToRight = keyframes`
  from {
    width: 0rem;
  }
  to {
    width: 100%;
  }
`;


export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  overflow-x: hidden;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopImageCarouselWrapper = styled.div`
  width: 100%;
  height: 100rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1500px) {
    height: 80rem;
  }

  @media screen and (max-width: 768px) {
    height: 50rem;
  }
`;

export const CarouselImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
`;

export const CarouselVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
`;

export const CarouselImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 1), 20%, rgba(0, 0, 0, 0),80%, rgba(0, 0, 0, 1));
  left: 0;
  top: 0;
`;

export const CarouselTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: absolute;
  right: 6rem;
  top: 70%;

  @media screen and (max-width: 768px) {
    top: 0;
    left: 0;
    position: relative;
    align-items: center;
  }
`;

export const CarouselTitleText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  color: #FFFFFF;
  height: 6rem;
  z-index: 10;
  animation: ${slideUp} 5s infinite;
  overflow: hidden;
  font-family: 'NanumSquare';

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    height: 3rem;
  }
`;

export const CarouselSubtitleBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #FFFFFF;
  font-size: 2rem;
  font-family: 'Noto Sans KR';
  margin-top: 1.5rem;
  height: 2.2rem;
  z-index: 10;
  animation: ${slideUpShort} 5s infinite;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    height: 1.4rem;
  }
`;

export const CarouselGotoButton = styled(Link)<{color: string}>`
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  color: #FFFFFF;
  text-align: right;
  margin-top: 1.5rem;
  border-bottom: 1px solid #FFFFFF;
  transition: 0.45s ease-out;

  &:hover {
    border-bottom: 1px solid ${props => props.color};
    color: ${props => props.color};
  }
`;

export const BusinessArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  background: linear-gradient(rgba(0, 0, 0, 1), 90%, rgba(200, 200, 200, 1));
`;

export const BusinessAreaTitle = styled.div`
  font-size: 3.5rem;
  color: #FFFFFF;
  font-weight: bold;
  margin: 25rem auto 0;
  font-family: 'NanumSquare';

  @media screen and (max-width: 768px) {
    margin: 6rem auto 0;
    font-size: 2rem;
  }
`;

export const BusinessAreaClickSection = styled.div<{expand: boolean}>`
  width: 70%;
  margin: 5rem 0 30rem;
  display: flex;
  flex-dierction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: ${props => props.expand ? '1': '0'};
  margin-left: ${props => props.expand ? '0' : '120rem'};
  transition: 0.9s ease-out;

  @media screen and (max-width: 1200px) {
    width: 90%;
    margin: 2rem auto;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 2rem auto 10rem;
  }
`;

export const BusinessAreaButton = styled(Link)<{color: string}>`
  width: 25%;
  height: 40rem;
  background-color: ${props => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.35s ease-out;
  color: #FFFFFF;
  position: relative;

  &:hover {
    width: 70%;
    color: #000000;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 30rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 20rem;
  }
`;

export const BusinessAreaButtonImg = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain;
`;

export const BusinessAreaButtonText = styled.div<{focused: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: ${props => props.focused ? '3.5rem' : '1.6rem'};
  width: 100%;
  height: ${props => props.focused ? '100%' : '10%'};
  transition: 0.35s ease-out;
  background-color: ${props => props.focused ? 'rgba(255,255, 255, 0.4)' : '#000000'};
  position: absolute;
  bottom: 0;
  

  @media screen and (max-width: 768px) {
    font-size: ${props => props.focused ? '3rem' : '1.2rem'};
    height: ${props => props.focused ? '100%' : '15%'};
  }
`;


export const ReferenceArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(125, 125, 125, 1), rgba(255, 255, 255, 1));
`;

export const ReferenceAreaTitle = styled.div`
  font-size: 2.5rem;
  color: #FFFFFF;
  font-weight: bold;
  margin: 20rem auto 0;
  font-family: 'NanumSquare';

  @media screen and (max-width: 768px) {
    margin: 6rem auto 0;
    font-size: 2rem;
  }
`;

export const ReferenceAreaItemSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
  width: 60%;
  gap: 1.5rem;
  overflow: hidden;

  @media screen and (max-width: 1600px) {
    width: 95%;
  }

  @media screen and (max-width: 1200px) {
    width: 95%;
    gap: 2rem;
    margin: 2rem auto;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    gap: 1rem;
    margin: 2rem auto;
  }
`;

export const ReferenceAreaItemSectionBlock = styled.div<{expand: boolean}>`
  width: 100%;
  height: ${props => props.expand ? '0' : '4rem'};
  transition: 0.7s ease-out;

  @media screen and (max-width: 768px) {
    height: ${props => props.expand ? '0' : '3rem'};
  }
`;

export const ReferenceAreaItem = styled.div<{expand: boolean}>`
  width: 23%;
  height: 25rem;
  background-color: #transparent;
  position: relative;
  overflow: hidden;
  opacity: ${props => props.expand ? '1' : '0'};
  transition: 0.35s ease-out;

  @media screen and (max-width: 1200px) {
    width: 32%;
  }

  @media screen and (max-width: 768px) {
    width: 48%;
    height: 15rem;
  }
`;

export const ReferenceAreaImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ReferenceAreaWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 171, 192, 0.8);
  opacity: 0;
  transition: 0.35s ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1
  }
`;

export const ReferenceItemTitle = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #FFFFFF;
  margin: 0 0 1rem;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const ReferenceItemContent = styled.div`
  font-size: 1.4rem;
  color: #FFFFFF;
  width: 80%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const ReferenceMoreArea = styled(Link)`
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 20rem;
  font-size: 1.5rem;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  font-family: 'NanumSquare';

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
    margin: 2rem auto 6rem;
  }
`;


export const AskLetterArea = styled.div`
  width: 100%;
  background-color: #3D3D3D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const AskLetterAreaTitle = styled.div`
  font-size: 2.5rem;
  color: #FFFFFF;
  font-weight: bold;
  margin: 4rem auto 0;

  @media screen and (max-width: 768px) {
    margin: 2rem auto 0;
    font-size: 2rem;
  }
`;

export const AskLetterWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

export const AskLetterImg = styled.img`
  height: 40rem;
  width: 20rem;
  object-fit: cover;
  border-radius: 2rem;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const AskLetterFormArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  margin: 0 2rem;
`;

export const AskLetterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 60rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
  }
`

export const AskLetterShort = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;


export const AskLetterLong = styled.div`
  display: flex;
  flex-direction: column;
  width: 92%;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;


export const AskLetterLabel = styled.div`
  font-size: 1.2rem;
  color: #FFFFFF;
  margin: 0 0 1rem;
`;

export const AskLetterInput = styled.input`
  width: 100%;
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  color: #4D4D4D;
  placeholder-color: #CECECE;
`;


export const AskLetterTextArea = styled.textarea`
  width: 100%;
  height: 20rem;
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  color: #4D4D4D;
  placeholder-color: #CECECE;
`;

export const AskLetterSendButton = styled.div`
  padding: 1.5rem 7rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
`;



export const CarouselIndicator = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  transition: 0.35s ease-out;
  width: 25rem;
  height: auto;
  position: relative;
  border-spacing: 0rem;
  border-collapse: collapse;
`;

export const CarouselIndicatorTitle = styled.div`
  font-size: 1.6rem;
  color: #FFFFFF;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 2.4rem;
  font-weight: 500;
  font-family: 'NanumSquare';

  @media screen and (max-width: 1200px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CarouselIndicatorSubtitle = styled.div`
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0.2rem;
  height: 2.4rem;
  margin-left: 1rem;
  font-family: 'GMarketSans';

  @media screen and (max-width: 1200px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CarouselActiveBottom = styled.div<{color: string}>`
  position: absolute;
  bottom: -3px;
  left: 0;
  animation: ${borderBottomLeftToRight} 7s;
  border-bottom: 3px solid ${props => props.color};
  z-index: 999;
`;


export const PartnersArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  background: linear-gradient(rgba(200, 200, 200, 1), 10%, rgba(255, 255, 255, 1));
`;

export const PartnersAreaTitle = styled.div`
  font-size: 3.5rem;
  color: #4D4D4D;
  font-weight: bold;
  margin: 18rem auto 0;
  font-family: 'NanumSquare';

  @media screen and (max-width: 768px) {
    margin: 6rem auto 0;
    font-size: 2rem;
  }
`;

export const PartnersAreaClickSection = styled.div<{expand: boolean}>`
  width: 50%;
  height: 110rem;
  margin: 2rem auto 20rem;
  display: flex;
  flex-dierction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
  opacity: ${props => props.expand ? '1' : '0'};
  margin-top: ${props => props.expand ? '2rem' : '6rem'};
  transition: 0.7s ease-out;

  @media screen and (max-width: 1200px) {
    width: 90%;
    margin: 2rem auto;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 2rem auto 10rem;
    height: 35rem;
    gap: 0;
  }
`;

export const PartnersItem = styled.div`
  width: 24%;
  height: 20%;
  border: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 25%;
    border: #EFEFEF;
  }
`;

export const PartnersImg = styled.img`
  width: 70%;
  height: 70%;
  object-fit: contain;
`;