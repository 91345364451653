import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;

export const CreateProjectArea = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
`;

export const CreateProjectTable = styled.table`
  width: 100%;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  border-collapse: separate;
  border-spacing: 1rem;
`;

export const CreateProjectTbody = styled.tbody`

`;

export const CreateProjectTr = styled.tr`
  
`;

export const CreateProjectTh = styled.th`
  border-right: 1px solid #DEDEDE;
  padding: 0.5rem 1rem;
  text-align: right;
  font-size: 1.4rem;
`;

export const CreateProjectTd = styled.td`
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 1.4rem;
`;


export const CreateProjectThumbnail = styled.img`
  width: 100%;
  height: 40rem;
  object-fit: contain;
`;

export const CreateProjectInput = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  color: #000000;
  placeholder-color: #DEDEDE;
  text-align: left;
  border: 1px solid #ABABAB;
  width: 100%;

  &:focus {
    border: 1px solid #000000;
  }
`;

export const CreateProjectTextArea = styled.textarea`
  width: 100%;
  height: 40rem;
  resize: none;
  border: 1px solid #ABABAB;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;

  &:focus {
    border: 1px solid #000000;
  }
`;

export const CreateProjectSelect = styled.select`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: 2rem auto 9rem;
`;

export const SubmitButton = styled.div`
  padding: 2rem 3rem;
  width: 16rem;
  font-size: 1.6rem;
  color: #FFFFFF;
  background-color: #000000;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ToListButton = styled(Link)`
  padding: 2rem 3rem;
  width: 16rem;
  font-size: 1.6rem;
  color: #FFFFFF;
  background-color: #ABABAB;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;



export const RefFileDropZone = styled.div`
  width: 100%;
  height: 10rem;
  border: 3px dashed #1891EF;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RefFileExplainPTag = styled.p`
  font-size: 1.4rem;
  color: #4D4D4D;
  font-weight: bold;
`;

export const RefFileItems = styled.div`
  font-size: 1.4rem;
  margin: 1rem;
  color: #1981EF;
  font-weight: bold;
  cursor: pointer;
`;