import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';
import korean from '../../util/korean.json';
import english from '../../util/english.json';

import FooterPresenter from './FooterPresenter';

const FooterContainer = () => {
  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    company_name: '',
    footer_textRegular1: '',
  });


  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  return (
    <>
      <FooterPresenter
        setLanguage={setLanguage}
        texts={texts}
      />
    </>
  );
};

FooterContainer.defaultProps = {};

export default FooterContainer;
