import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import $ from 'jquery';

import * as recoilItem from '../../../../util/recoilitem';
import * as util from '../../../../util/util';
import { useNavigate, useParams } from 'react-router-dom';

import NewsDetailPresenter from './NewsDetailPresenter';
import { newsApi } from '../../../../api/api-news';
import moment from 'moment';

const NewsDetailContainer = () => {
  
  const navigate = useNavigate();
  const params = useParams();
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [itemId, setItemId] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<any>('');
  const [dvCd, setDvCd] = useState<string>('business');
  const [newsRegDt, setNewsRegDt] = useState<string>(moment().format('YYYYMMDD'));
  const [thumbnailPreview, setThumbnailPreview] = useState<string>('/static/img/no_preview.png');

  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['clean']
    ],
  }

  const formats = [
    //'font',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'align', 'color', 'background',        
  ]

  useEffect(() => {
    if (userInfo.userTypeCd !== 'ADMIN') {
      util.makeMsg('관리자만 접근 가능합니다', 'error');
      navigate('/');
    }

    fetchData(params.id);
  }, [])

  useEffect(() => {
    if (thumbnail) {
      let reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      reader.onloadend = () => {
        setThumbnailPreview(String(reader.result));
      }
    }
    
  }, [thumbnail]);

  const fetchData = async (newsId: any) => {
    let params = {
      newsId: newsId
    };

    let res = await newsApi.getNewsDetail(params);
    if (res.data?.data.rsltCd === '00') {
      let item = res.data.data.item;
      if (item.thumbnail) {
        setThumbnailPreview(item.thumbnail);
      }
      setContent(item.content);
      setTitle(item.title);
      setItemId(item.news_id);
      setDvCd(item.dv_cd);
      setNewsRegDt(item.news_reg_dt);
    } else {
      util.makeMsg('삭제된 뉴스입니다.', 'error');
      navigate('/admin/news');
    }
  }


  const submit = async () => {
    setIsLoading(true);

    if (!title) {
      util.makeMsg('제목을 입력해주세요', 'error');
      return;
    }

    if (!content) {
      util.makeMsg('내용을 입력해주세요', 'error');
      return;
    }

    let dateTest = moment(newsRegDt).format('YYYYMMDD');
    if (dateTest === 'Invalid date') {
      util.makeMsg('날짜는 YYYYMMDD 형식으로 입력하세요', 'error');
      return;
    }

    let params: any = {
      title: title,
      content: content,
      newsId: itemId,
      dvCd: dvCd,
      newsRegDt: newsRegDt
    }

    if (thumbnail) {
      params.thumbnail = await readFileAsDataURL(thumbnail);
    } else if (thumbnailPreview === '/static/img/no_preview.png') {
      params.deleteThumb = 'Y'
    }

    let res = await newsApi.updateNews(token, params);
    if (res.data?.data.rsltCd === '00') {
      util.makeMsg('등록에 성공하였습니다', 'success');
      setIsLoading(false);
      navigate('/admin/news');
    } else {
      util.makeMsg('저장에 실패하였습니다', 'error');
      setIsLoading(false);
    }
  }

  const removeThumbnail = () => {
    setThumbnail(null);
    setThumbnailPreview('/static/img/no_preview.png');
    $('#thumb').val('');
  }


  async function readFileAsDataURL(file: File) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    return result_base64;
  }

  return (
    <>
      <NewsDetailPresenter
        modules={modules}
        formats={formats}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        title={title}
        setTitle={setTitle}
        content={content}
        setContent={setContent}
        thumbnailPreview={thumbnailPreview}
        removeThumbnail={removeThumbnail}
        submit={submit}
        dvCd={dvCd}
        setDvCd={setDvCd}
        newsRegDt={newsRegDt}
        setNewsRegDt={setNewsRegDt}
      />
    </>
  );
};

NewsDetailContainer.defaultProps = {};

export default NewsDetailContainer;
