import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import ProjectCreatePresenter from './ProjectCreatePresenter';

import * as recoilItem from '../../../../util/recoilitem';
import * as util from '../../../../util/util';
import { useNavigate } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import { projectApi } from '../../../../api/api-project';

interface ProjectCreateProps {
  setShowExplainArea: Dispatch<SetStateAction<boolean>>;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setModalItem: Dispatch<SetStateAction<any>>;
  setModalPicture: Dispatch<SetStateAction<any[]>>;
}

const ProjectCreateContainer = (props: ProjectCreateProps) => {
  
  const navigate = useNavigate();
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const token = useRecoilValue(recoilItem.token);

  const [thumbnail, setThumbnail] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [refFiles, setRefFiles] = useState<any[]>([]);
  const [thumbnailPreview, setThumbnailPreview] = useState<string>('/static/img/no_preview.png');
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    refFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, [refFiles]);

  useEffect(() => {
    if (userInfo.userTypeCd !== 'ADMIN') {
      util.makeMsg('관리자만 접근 가능합니다', 'error');
      navigate('/');
    }
  }, [])

  useEffect(() => {
    if (thumbnail) {
      let reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      reader.onloadend = () => {
        setThumbnailPreview(String(reader.result));
      }
    }
  }, [thumbnail]);


  const submit = async () => {
    setIsLoading(true);
    if (!title) {
      util.makeMsg('제목을 입력해주세요', 'error');
      setIsLoading(false);
      return;
    }

    if (!content) {
      util.makeMsg('내용을 입력해주세요', 'error');
      setIsLoading(false);
      return;
    }

    if (!thumbnail) {
      util.makeMsg('썸네일은 필수입니다.', 'error');
      setIsLoading(false);
      return;
    }

    if (!refFiles || refFiles.length === 0) {
      util.makeMsg('이미지는 1개 이상 등록하세요', 'error');
      setIsLoading(false);
      return;
    }

    let params: any = {
      title: title,
      content: content,
      categoryId: categoryId
    }

    params.thumbnail = await readFileAsDataURL(thumbnail);

    let tempRefFiles: any = [];
    
    for (let i = 0; i < refFiles.length; i++) {
      let base64 = await readFileAsDataURL(refFiles[i]);
      tempRefFiles.push(base64)
    }

    params.refFiles = tempRefFiles;

    let res = await projectApi.addProject(token, params);
    if (res.data?.data.rsltCd === '00') {
      util.makeMsg('등록에 성공하였습니다', 'success');
      setIsLoading(false);
      navigate('/admin/project');
    } else {
      util.makeMsg('저장에 실패하였습니다', 'error');
      setIsLoading(false);
    }
  }

  const handleModal = async (index: number) => {
    let base64: any = await readFileAsDataURL(refFiles[index]);
    props.setModalPicture([base64]);
    props.setModalVisible(true);
    props.setShowExplainArea(false);
    props.setModalItem(null);
  }


  async function readFileAsDataURL(file: File) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    return result_base64;
  }
  
  return (
    <>
      <ProjectCreatePresenter
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        title={title}
        setTitle={setTitle}
        content={content}
        setContent={setContent}
        refFiles={refFiles}
        setRefFiles={setRefFiles}
        thumbnailPreview={thumbnailPreview}
        submit={submit}

        handleModal={handleModal}
      />
    </>
  );
};

ProjectCreateContainer.defaultProps = {};

export default ProjectCreateContainer;
