import { notLoginedApi, loginedApi } from './api-base';

export const qnaApi = {
  addQna: (Data: any) => notLoginedApi.post('/qna/addQna', Data),

  getQnaList: (token: any, Data: any) => loginedApi.post('/qna/getQnaList', Data, {
    headers: {
      'X-AUTH-TOKEN': token.accessToken,
      "X-AUTH-REFRESH-TOKEN": token.refreshToken,
    }
  }),  
}