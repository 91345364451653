import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import styled from 'styled-components';
import logo from './logo.svg';

import Router from './views/Router';

const SplashScreen = styled.div<{showSplash: boolean}>`
  width: 100vw;
  height: 100vh;
  z-index: ${props => props.showSplash ? '999999' : '-1'};
  opacity: ${props => props.showSplash ? '1' : '0'};
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  transition: 0.7s ease-out;
`;

const SHiveLogo = styled.img`
  width: 40rem;
  height: 40rem;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    width: 20rem;
    height: 20rem;
  }
`;


function App() {

  const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    setShowSplash(true);
    setTimeout(() => {
      setShowSplash(false);
    }, 3000);
  }, []);

  return (
    <RecoilRoot>
      {/* <SplashScreen showSplash={showSplash}>
        <SHiveLogo src="static/logo/logo.png"/>
      </SplashScreen>
      {
        showSplash ? 
        null
        : <Router />
      } */}
      <Router/>
    </RecoilRoot>
  );
}

export default App;
