import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
  margin: 0 0 12rem;

  @media screen and (max-width: 768px) {
    margin: 0 0 6rem;
  }
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
  font-family: 'NanumSquare';
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;

export const ItemArea = styled.div`
  width: 70%;
  height: auto;
  margin: 2rem auto 10rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: 1600px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
`;

export const ItemColumn = styled.div`
  width: 25%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Item = styled.div<{heightSeed: number}>`
  width: 23%;
  height: ${props => `${props.heightSeed*10}rem`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  cursor: pointer;
  position: relative;
  transition: 0.25s ease-out;
  box-shadow: 0 0 0.3rem #1A2D56;

  &:hover {
    border: 6px solid #1A2D56;
  }

  @media screen and (max-width: 1200px) {
    width: 30%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 25rem;
  }
`;

export const ItemContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 0.5rem;
  }
`;

export const ItemDate = styled.div`
  width: 90%;
  text-align: left;
  font-size: 1.4rem;
  font-family: 'GMarketSans';
  height: 5%;
  color: #000000;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }
`;

export const ItemDay = styled.div`
  width: 90%;
  text-align: left;
  height: 7%;
  font-weight: bold;
  font-size: 2rem;
  color: #1A2D56;
  font-family: 'GMarketSans';
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    position: absolute;
    top: 3.6rem;
    left: 1.5rem;
  }
`;

export const ItemInnerContent = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;

  @media screen and (max-width: 768px) {
    height: 100%;
    justify-content: center;
  }
`;

export const ItemThumbnail = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    width: 60%;
    height: 60%;
  }
`;

export const ItemTitle = styled.div`
  font-size: 2rem;
  width: 80%;
  font-weight: bold;
  color: #000000;
  margin: 1rem 0;
`;

export const ItemContent = styled.div`
  font-size: 1.6rem;
  width: 80%;
  color: #000000;
  margin: 1rem 0 2rem;
`;

export const ItemCoverWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 171, 192, 0.7);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s ease-out;

  &:hover {
    opacity: 1;
  }
`;

export const ItemCoverTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  margin: 2rem 0;
  font-weight: bold;
`;

export const ItemMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

`;
export const ItemMore = styled.div`
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 4rem;
  font-size: 1.6rem;
  color: #6D6D6D;
  border: 1px solid #BFBFBF;
  cursor: pointer;
  font-family: 'NanumSquare';

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
  }
`;

export const NewItemWrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: 1rem;
`;

export const NewItem = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: #1A2D56;
  font-size: 1.2rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const RibbonBottom = styled.div`
  border:       2rem solid #1A2D56;        /* All borders set */
  border-top:  0;                      /* Remove left border */
  border-bottom: 2rem solid transparent; /* Right transparent */
  height: 3rem;                  /* Increase element Width */
`;


export const FilterArea = styled.div`
  width: 70%;
  margin: 3rem auto 7rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 2rem auto 2rem;
    gap: 1rem;
  }
`;

export const FilterItem = styled.div<{active: boolean}>`
  width: 10rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-bottom: ${props => props.active ? '3px solid #1A2D56' : '1px solid #1A2D56'};
  padding-bottom: ${props => props.active ? '-3px' : '0px'};
  cursor: pointer;
  font-size: 1.4rem;
  font-family: 'NanumSquare';
  
  &:hover { 
    border-bottom: 3px solid #1A2D56;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0.5rem;
    width: 8rem;
    height: 3rem;
  }
`;