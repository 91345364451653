import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import NewsPresenter from './NewsPresenter';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { newsApi } from '../../api/api-news';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import korean from '../../util/korean.json';
import english from '../../util/english.json';

interface NewsProps {
  setNewsModalVisible: Dispatch<SetStateAction<boolean>>;
  setNewsModalItem: Dispatch<SetStateAction<any>>;
}

const NewsContainer = (props: NewsProps) => {
  const [page, setPage] = useState<number>(0);
  const [dvCd, setDvCd] = useState<string>('');

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [items, setItems] = useState<any[]>([]);

  const navigate = useNavigate();

  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    lastpage_msg: '',
    news_topSubtitle: '',
    Industry: '',
    Culture: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);


  useEffect(() => {
    fetchData([]);
  }, []);

  useEffect(() => {
    fetchData([...items]);
  }, [page])

  useEffect(() => {
    setItems([]);
    setPage(0);
    fetchData([]);
  }, [dvCd])

  const fetchData = async (items: any[]) => {
    setIsLoading(true);

    let params = {
      page: String(page),
      dvCd: dvCd !== '' ? dvCd : null
    };

    let res = await newsApi.getNewsList(params);
    if (res.data?.data.rsltCd === '00') {
      setIsLoading(false);
      let itemsTemp = [];
      for (let i = 0; i < res.data.data.list.length; i++) {
        let item = res.data.data.list[i];
        item.prevContent = replaceAllTags(item.content)
        itemsTemp.push(item);
      }
      setItems([...items, ...itemsTemp]);
    } else {
      if (items.length > 0) {
        util.makeMsg(`${texts.lastpage_msg}`, 'success');
      }
      setIsLoading(false);
    }
  }

  const replaceAllTags = (htmlString: string) => {
    let regImg = /<img(.*?)>/g;
    let regP = /<p>/g;
    let regPStyle = /<p(.*?)">/g;
    let regPClose = /<\/p>/g;
    let regBr = /<br>/g;
    let regA = /<a(.*?)>/g;
    let regAClose = /<\/a>/g;
    let regStrongStyle = /<strong(.*?)">/g;
    let regStrong = /<strong>/g;
    let regStrongClose = /<\/strong>/g;
    let regEm = /<em>/g;
    let regEmClose = /<\/em>/g;
    let regUnderline = /<u>/g;
    let regUnderlineClose = /<\/u>/g;
    let regS = /<s>/g;
    let regSClose = /<\/s>/g;
    let regBlockQuote = /<blockquote>/g;
    let regBlockQuoteClose = /<\/blockquote>/g;
    let regOl = /<ol>/g;
    let regOlClose = /<\/ol>/g;
    let regLi = /<li>/g;
    let regLiClose = /<\/li>/g;

    let str = htmlString.replaceAll(regImg, '')
    .replaceAll(regP, '')
    .replaceAll(regPStyle, '')
    .replaceAll(regPClose, '')
    .replaceAll(regBr, ' ')
    .replaceAll(regA, '')
    .replaceAll(regAClose, '')
    .replaceAll(regStrong, '')
    .replaceAll(regStrongStyle, '')
    .replaceAll(regStrongClose, '')
    .replaceAll(regEm, '')
    .replaceAll(regEmClose, '')
    .replaceAll(regUnderline, '')
    .replaceAll(regUnderlineClose, '')
    .replaceAll(regS, '')
    .replaceAll(regSClose, '')
    .replaceAll(regBlockQuote, '')
    .replaceAll(regBlockQuoteClose, '')
    .replaceAll(regOl, '')
    .replaceAll(regOlClose, '')
    .replaceAll(regLi, '')
    .replaceAll(regLiClose, '')
    .replaceAll('.', '. ')


    return str.length > 70 ? str.substring(0, 70).concat('...') : str;
    
  }

  const handleModal = (item: any) => {
    props.setNewsModalItem(item);
    props.setNewsModalVisible(true);
  }

  return (
    <>
      <NewsPresenter
        items={items}
        page={page}
        setPage={setPage}
        handleModal={handleModal}
        dvCd={dvCd}
        setDvCd={setDvCd}
        texts={texts}
      />
    </>
  );
};

NewsContainer.defaultProps = {};

export default NewsContainer;
