import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;


export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;


export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 3rem;
  font-weight: bold;
  color: #000000;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const FilterArea = styled.div`
  width: 70%;
  margin: 3rem auto 7rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 2rem auto 2rem;
    gap: 1rem;
  }
`;

export const FilterItem = styled.div<{active: boolean}>`
  width: 15rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#1A2D56' : '#FFFFFF'};
  border: ${props => props.active ? '1px solid #1A2D56' : '1px solid #1A2D56'};
  color: ${props => props.active ? '#FFFFFF' : '#6D6D6D'};
  cursor: pointer;
  font-size: 1.4rem;

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0.5rem;
    width: 8rem;
    height: 3rem;
  }
`;

export const ItemArea = styled.div`
  width: 70%;
  height: auto;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: 1600px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    gap: 1rem;
  }
`;


export const Item = styled(Link)<{color: string}>`
  width: 23%;
  height: 30rem;
  background-color: #FFFFFF;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    width: 32%;
  }

  @media screen and (max-width: 768px) {
    width: 48%;
    height: 15rem;
  }
`;

export const ItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 171, 192, 0.8);
  opacity: 0;
  transition: 0.35s ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1
  }
`;

export const ItemCoverTitle = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #FFFFFF;
  margin: 0 0 1rem;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const ItemCoverContent = styled.div`
  font-size: 1.4rem;
  color: #FFFFFF;
  width: 80%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const ItemMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

`;
export const ItemMore = styled.div`
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 4rem;
  font-size: 1.6rem;
  color: #6D6D6D;
  border: 1px solid #BFBFBF;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
  }
`;

export const CreateButton = styled(Link)`
  padding: 1rem 2rem;
  width: 10rem;
  margin: 2rem auto;
  font-size: 1.6rem;
  color: #FFFFFF;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const NoRowsToShow = styled.div`
  width: 100%;
  height: 30rem;
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #DEDEDE;
`;