import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 88rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;

export const AdminFunctionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 4rem auto;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
`;


export const AdminFunctionButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25rem;
  height: 25rem;
  font-size: 3rem;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #ABABAB;
  cursor: pointer;

  &:hover {
    background-color: #1A2D56;
  }
`;