import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import ProjectListPresenter from './ProjectListPresenter';
import * as recoilItem from '../../../../util/recoilitem';
import * as util from '../../../../util/util';
import { projectApi } from '../../../../api/api-project';

const ProjectListContainer = () => {
  
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [category, setCategory] = useState<number>(-1);
  const [page, setPage] = useState<number>(0);
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {
    fetchData([]);
  }, []);

  useEffect(() => {
    setProjects([]);
    setPage(0);
    fetchData([]);
  }, [category])

  useEffect(() => {
    fetchData([...projects]);
  }, [page])

  const fetchData = async (projects: any[]) => {
    setIsLoading(true);

    let params = {
      categoryId: category === -1 ? null : category,
      page: page === 0 ? '0' : page
    };

    let res = await projectApi.getProjectList(params);
    if (res.data?.data.rsltCd === '00') {
      setProjects([...projects, ...res.data?.data.list]);
      setIsLoading(false);
    } else {
      if (projects.length > 0) {
        util.makeMsg('마지막 페이지입니다.', 'success');
      }
      setIsLoading(false);
    }
  }

  return (
    <>
      <ProjectListPresenter
        category={category}
        setCategory={setCategory}
        page={page}
        setPage={setPage}
        projects={projects}
      />
    </>
  );
};

ProjectListContainer.defaultProps = {};

export default ProjectListContainer;
