import { notLoginedApi, loginedApi } from './api-base';

export const projectApi = {
  addProject: (token: any, Data: any) => loginedApi.post('/project/addProject', Data, {
    headers: {
      'X-AUTH-TOKEN': token.accessToken,
      "X-AUTH-REFRESH-TOKEN": token.refreshToken,
    }
  }),

  updateProject: (token: any, Data: any) => loginedApi.post('/project/updateProject', Data, {
    headers: {
      'X-AUTH-TOKEN': token.accessToken,
      "X-AUTH-REFRESH-TOKEN": token.refreshToken,
    }
  }),

  getProjectList: (Data: any) => notLoginedApi.post('/project/getProjectList', Data),

  getProjectDetail: (Data: any) => notLoginedApi.post('/project/getProjectDetail', Data),
}