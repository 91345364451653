import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Common
export const Container = styled.div<{expanded: boolean, scrollPosition: number, isMain: boolean}>`
  width: 100%;
  height: 6rem;
  min-width: 120rem;
  margin: 0 auto 0.5rem;
  text-align: center;
  // box-shadow: ${props => props.expanded || (props.scrollPosition < 30) ? null : '0 0.3rem 0.3rem 0.3rem #EEEEEE'};
  transition: 0.35s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: ${props => props.expanded ? '#FFFFFF' : props => props.scrollPosition < 30 ? 'transparent' : '#FFFFFF'};
  background-color: ${props => props.expanded ? '#FFFFFF' : 'transparent'};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  @media screen and (max-width: 1024px) {
    min-width: 100%;
    height: 6rem;
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    height: 6rem;
  }
`;

export const LogoArea = styled(NavLink)`
  width: 25rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: 16rem;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    left: 0.5rem;
    width: 10;
    height: 5rem;
  }
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

// Web
export const TopMenuArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  min-width: 90rem;
  margin: 0 0 0 10%;
  gap: 2rem;

  @media screen and (max-width: 1024px) {
    min-width: 65%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TopMenuItem = styled(NavLink)<{focused: boolean, scrollPosition: number, expanded: boolean, isMain: boolean}>`
  display: flex;
  align-items: center;
  text-align: left;
  
  border-bottom: ${props => props.focused ? '0.5rem solid #1A2D56' : null};

  color: ${props => props.focused ? '#1A2D56' : props.expanded ? '#4D4D4D' : '#FFFFFF'};
  width: 14rem;
  height: 100%;
  margin: 0 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.15s ease-out;
  font-family: 'NanumSquare';
`;

export const UserIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  background-color: #ABABAB;
  cursor: pointer;
  border-radius: 5rem;

  @media screen and (max-width: 1024px) {
    width: 3.5rem;
    height: 3.5rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const WebHoverArea = styled.div<{expanded: boolean}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.expanded ? '20rem' : '0rem'};
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0.3rem 0.3rem 0 #DEDEDE;
  background-color: #FFFFFF;
  z-index: 999;
  opacity: ${props => props.expanded ? '1' : '0'};

  transition: 0.2s linear;
  top: 6rem;

  @media screen and (max-width: 1024px) {
    top: 6rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const WebSubMenuItemCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 14rem;
  height: 100%;
  margin: 0 1rem;
  padding: 1rem 0;
`;

export const SubMenuLeftMargin = styled.div`
  width: 25rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: 16rem;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    left: 0.5rem;
    width: auto;
  }
`;

export const SubMenuRightMargin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;

  @media screen and (max-width: 1024px) {
    width: 3.5rem;
    height: 3.5rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SubMenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  color: #4D4D4D;
  margin: 1rem 0;
  font-family: 'NanumSquare';

  &:hover {
    color: #1A2D56;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const SubMenuDivItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  color: #4D4D4D;
  margin: 1rem 0;
  font-family: 'NanumSquare';

  &:hover {
    color: #1A2D56;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const LoginModal = styled.div<{show: boolean, isLogin: boolean}>`
  position: absolute;
  top: 115%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15rem;
  padding: 0 1rem;
  height: ${props => props.show ? props.isLogin ? '12rem' : '7.5rem' : '0rem'};
  overflow: hidden;
  background-color: ${props => props.show ? '#1A2D56' : 'transparent'};
  border: 1px solid ${props => props.show ? '#FFFFFF' : 'transparent'};
  transition: 0.25s ease-out;
  z-index: 9999;
`;

export const LoginModalText = styled.div`
  padding: 1rem;
  font-size: 1.4rem;
  font-family: 'NanumSquare';
  font-weight: bold;
  color: #FFFFFF;
`;

export const LoginModalItem = styled(NavLink)`
  padding: 1rem;
  font-size: 1.4rem;
  font-family: 'NanumSquare';
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    color: #007890;
  }
`;


// Mobile
export const BurgerIcon = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: calculate((100%-3rem)/2);
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const ModalSidebarWrapper = styled.div<{show: boolean}>`
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${props => props.show ? '1000' : '-1'};
  width: ${props => props.show ? '100%' : '0'};
  height: ${props => props.show ? '600vh' : '0vh'};
  opacity: ${props => props.show ? '1' : '0'};

  transition: 0.15s ease-out;
`;

export const ModalBackdropArea = styled.div`
  width: 55%;
  background-color: transparent;
`;

export const MobileSidebar = styled.div<{show: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  background-color: #FFFFFF;
  display: ${props => props.show ? 'flex' : 'none'};
  width: 45%;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileSidebarTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 7rem;
  width: 100%;
  background-color: #1A2D56;
  color: #FFFFFF;
  font-size: 1rem;
  font-family: 'NanumSquare';
  font-weight: 500;
  z-index: 99999;
`;

export const MobileSidebarTopInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1rem;
`;

export const MobileSidebarTopNaviWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.5rem;
`;

export const MobileSidebarTopNavi = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: 'NanumSquare';
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 0.5rem;
  margin-right: 0.5rem;
`;

export const MobileSidebarTopTextInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-family: 'NanumSquare';
  font-weight: bold;
  color: #FFFFFF;
`;

export const MobileSidebarCloseButton = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0.5rem;
  display: flex;  
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MobileSidebarTopMenu = styled.div<{focused: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  font-weight: bold;
  height: 3rem;
  width: 100%;
  z-index: 99999;
  cursor: pointer;
  border-bottom: ${props => props.focused ? '1px solid #1A2D56' : '1px solid #ABABAB'};
  color: ${props => props.focused ? '#1A2D56' : '#4D4D4D'};
  position: relative;
`;

export const MobileSidebarTopMenuIcon = styled.div`
  position: absolute;
  right: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileSidebarSubMenuWrapper = styled.div<{focused: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: ${props => props.focused ? 'auto' : '0'};
  margin: ${props => props.focused ? '1rem auto' : '0'};
  background-color: #F6F6F6;
  overflow: hidden;
  transition: 0.35s ease-out;
  padding: ${props => props.focused ? '1rem 0' : '0'};
`;

export const MobileSidebarSubMenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  font-size: 1rem;
  font-family: 'NanumSquare';
  font-weight: bold;
  margin: 0.3rem 0 0.3rem 1rem;
  
`;
