import { notLoginedApi, loginedApi } from './api-base';

export const newsApi = {
  addNews: (token: any, Data: any) => loginedApi.post('/news/addNews', Data, {
    headers: {
      'X-AUTH-TOKEN': token.accessToken,
      "X-AUTH-REFRESH-TOKEN": token.refreshToken,
    }
  }),

  updateNews: (token: any, Data: any) => loginedApi.post('/news/updateNews', Data, {
    headers: {
      'X-AUTH-TOKEN': token.accessToken,
      "X-AUTH-REFRESH-TOKEN": token.refreshToken,
    }
  }),

  getNewsList: (Data: any) => notLoginedApi.post('/news/getNewsList', Data),

  getNewsDetail: (Data: any) => notLoginedApi.post('/news/getNewsDetail', Data),
}