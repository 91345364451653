import React from 'react';
import * as s from './UserAskListStyled';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from 'react-js-pagination';

import * as util from '../../../../util/util';

interface UserAskListProps {
  rowData: any[],
  rowCount: number,

  page: number,
  handlePageChange: (page: number) => void,
}

const UserAskListPresenter = (props: UserAskListProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/1076/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>USER Q{'&'}A</s.TopTitle>
          <s.TopSubtitle>관리자페이지입니다.</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>

      <s.BoardItemTable>
        <s.BoardItemThead>
          <s.BoardItemTh style={{width: '5%'}}>순번</s.BoardItemTh>
          <s.BoardItemTh style={{width: '48%'}}>내용(미리보기)</s.BoardItemTh>
          <s.BoardItemTh style={{width: '15%'}}>작성자</s.BoardItemTh>
          <s.BoardItemTh style={{width: '16%'}}>이메일</s.BoardItemTh>
          <s.BoardItemTh style={{width: '16%'}}>작성일</s.BoardItemTh>
        </s.BoardItemThead>
        <s.BoardItemTbody>
          {
            props.rowData && props.rowData.length > 0 ? 
            props.rowData.map((item: any, index: number) => (
            <s.BoardItemTr onClick={() => {
              util.makeMsg('[이름] ' + item.user_nm + '\n[이메일] ' + item.user_email + '\n\n\n\n' + '[문의내용]' + '\n\n' + item.content, 'info')
            }}>
              <s.BoardItemTd>{(props.page - 1)*10 + index + 1}</s.BoardItemTd>
              <s.BoardItemTd style={{textAlign: 'left'}}>{item.content.length > 30 ? item.content.substring(0, 30).concat('...') : item.content}</s.BoardItemTd>
              <s.BoardItemTd>{item.user_nm}</s.BoardItemTd>
              <s.BoardItemTd>{item.user_email}</s.BoardItemTd>
              <s.BoardItemTd>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</s.BoardItemTd>
            </s.BoardItemTr>
            )) :
            <s.BoardItemTr>
              <s.BoardItemTd colSpan={5}>뉴스가 없습니다.</s.BoardItemTd>
            </s.BoardItemTr>
          }
        </s.BoardItemTbody>
      </s.BoardItemTable>
      <s.PagingArea>
        <Pagination activePage={props.page} itemsCountPerPage={16} totalItemsCount={props.rowCount} pageRangeDisplayed={5} prevPageText={"‹"} nextPageText={"›"} onChange={props.handlePageChange} />
      </s.PagingArea>
    </s.Container>
  );
};

export default UserAskListPresenter;
