import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: transparent;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  @media screen and (max-width: 768px) {
    height: 84rem;
    padding: 0 10%;
  }
`;

export const TopTitle = styled.div`
  margin-top: 1rem;
  font-size: 7rem;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  line-height: 8rem;

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    line-height: auto;
    text-align: center;
  }
`;

export const TopSubtitle = styled.div`
  font-size: 2.5rem;
  color: #38955f;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const TopAreaScrollDown = styled.div`
  width: 2rem;
  height: 25rem;
  position: absolute;
  right: 20rem;
  bottom: 0;
  background-color: #38955f;

  @media screen and (max-width: 768px) {
    width: 2rem;
    height: 15rem;
    right: 5rem;
  }
`;

export const MainBusinessIntroduceArea = styled.div`
  width: 100%;
  height: auto;
  padding: 15rem 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`

export const MainBusinessIntroduceTitle = styled.div`
  font-size: 4rem;
  font-weight: bold;
  margin: 3rem 0;
`;

export const BodyArea = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const AgendaSection = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #dedede;
  display: flex;
  margin: 12rem 0 0;

  @media screen and (max-width: 1900px) {
    height: 110rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 90rem;
  }
`;

export const AgendaPicture = styled.img`
  width: 40%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const AgendaContentWrapper = styled.div<{ expand: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12rem 3rem;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  margin-top: ${(props) => (props.expand ? "0rem" : "6rem")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 768px) {
    padding: 6rem 3rem;
  }
`;

export const AgendaTitle = styled.div`
  font-size: 6rem;
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  line-height: 5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const AgendaSubtitle = styled.div`
  font-size: 1.8rem;
  line-height: 3.2rem;
  padding-left: 2rem;
  color: #9d9d9d;
  margin: 4rem 0;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding-left: 0;
    margin: 2rem 0;
    line-height: 2rem;
  }
`;

export const AgendaContent = styled.div`
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-left: 2rem;
  color: #000000;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding-left: 0;
    margin: 2rem 0;
    line-height: 2rem;
  }
`;

export const MilestoneSection = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(#000000, 90%, #333333);
  display: flex;
  flex-direction: column;
  padding: 20rem 0 5rem;
  position: relative;

  @media screen and (max-width: 1900px) {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    height: 100rem;
    justify-content: center;
  }
`;

export const MilestoneTitle = styled.div`
  font-size: 4rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: left;
  width: 70%;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const MilestoneArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 12rem auto;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const MilestoneLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  gap: 6%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const MilestoneRight = styled.div`
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 0%;
  }
`;

export const MilestoneLeftItem = styled.div`
  width: 47%;
  height: 20rem;
  display: flex;
  flex-direction: column;
`;

export const MilestoneLeftItemTitle = styled.div`
  font-size: 2rem;
  color: #ffffff;
  display: flex;
  font-weight: 500;
  margin-bottom: 1rem;
  align-items: flex-end;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const MilestoneLeftItemText = styled.div`
  font-size: 4rem;
  color: #ffffff;
  display: flex;
  font-weight: bold;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const MilestoneBackgroundImg = styled.img`
  width: 130%;
  height: 130%;
  object-fit: contain;
  object-position: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const MilestoneWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20rem 0;
  background: linear-gradient(45deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6));
`;

export const HistorySection = styled.div<{ expand: boolean }>`
  width: 100%;
  background: linear-gradient(
    #000000,
    5%,
    #dddddd,
    10%,
    #ffffff,
    90%,
    #222222,
    95%,
    #000000
  );
  display: flex;
  flex-direction: column;
  padding: 10rem 0 10rem;
  position: relative;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.expand ? "240rem" : "150rem")};
  transition: 0.35s ease-out;
  overflow: hidden;

  @media screen and (max-width: 1900px) {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    height: 100rem;
    justify-content: center;
  }
`;

export const HistoryTitle = styled.div<{ expand: boolean }>`
  font-size: 4rem;
  font-weight: bold;
  color: #000000;
  text-align: left;
  width: 50%;
  margin-top: ${(props) => (props.expand ? "10rem;" : "30rem")};

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const HistoryWrapper = styled.div<{ expand: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    #000000,
    10%,
    rgba(255, 255, 255, 1),
    35%,
    rgba(255, 255, 255, 0.8),
    65%,
    rgba(255, 255, 255, 1),
    90%,
    #000000
  );
`;

export const NewsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #000000;
`;

export const HistoryBackgroundImg = styled.img`
  width: 80%;
  height: 100%;
  object-fit: contain;
  object-position: right;
`;

export const ItemArea = styled.div`
  width: 70%;
  height: auto;
  margin: 2rem auto 10rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: 1600px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
`;

export const ItemColumn = styled.div`
  width: 25%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Item = styled.div<{ heightSeed: number }>`
  width: 23%;
  height: ${(props) => `${props.heightSeed * 10}rem`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  border: 1px solid #dedede;
  cursor: pointer;
  position: relative;
  transition: 0.25s ease-out;
  box-shadow: 0 0 0.3rem #38955f;

  &:hover {
    border: 6px solid #38955f;
  }

  @media screen and (max-width: 1200px) {
    width: 30%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 25rem;
  }
`;

export const ItemContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 0.5rem;
  }
`;

export const ItemDate = styled.div`
  width: 90%;
  text-align: left;
  font-size: 1.4rem;
  font-family: "GMarketSans";
  height: 5%;
  color: #ffffff;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }
`;

export const ItemDay = styled.div`
  width: 90%;
  text-align: left;
  height: 7%;
  font-weight: bold;
  font-size: 2rem;
  color: #38955f;
  font-family: "GMarketSans";
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    position: absolute;
    top: 3.6rem;
    left: 1.5rem;
  }
`;

export const ItemInnerContent = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;

  @media screen and (max-width: 768px) {
    height: 100%;
    justify-content: center;
  }
`;

export const ItemThumbnail = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    width: 60%;
    height: 60%;
  }
`;

export const ItemTitle = styled.div`
  font-size: 2rem;
  width: 80%;
  font-weight: bold;
  color: #ffffff;
  margin: 1rem 0;
  text-align: left;
`;

export const ItemContent = styled.div`
  font-size: 1.6rem;
  width: 80%;
  color: #ffffff;
  margin: 1rem 0 2rem;
  text-align: left;
`;

export const ItemCoverWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 171, 192, 0.7);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s ease-out;

  &:hover {
    opacity: 1;
  }
`;

export const ItemCoverTitle = styled.div`
  font-size: 3rem;
  color: #ffffff;
  margin: 2rem 0;
  font-weight: bold;
`;

export const ItemMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
export const ItemMore = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  height: 1.6rem;
  justify-content: center;
  margin: 4rem 0;
  font-size: 1.6rem;
  color: #ffffff;
  cursor: pointer;
  font-family: "NanumSquare";

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
  }
`;

export const NewItemWrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: 1rem;
`;

export const NewItem = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: #38955f;
  font-size: 1.2rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const RibbonBottom = styled.div`
  border: 2rem solid #38955f; /* All borders set */
  border-top: 0; /* Remove left border */
  border-bottom: 2rem solid transparent; /* Right transparent */
  height: 3rem; /* Increase element Width */
`;

export const FilterArea = styled.div`
  width: 70%;
  margin: 25rem auto 7rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 2rem auto 2rem;
    gap: 1rem;
  }
`;

export const NewsTitle = styled.div`
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  left: 0;
  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const FilterItem = styled.div<{ active: boolean }>`
  width: 10rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  border-bottom: ${(props) =>
    props.active ? "3px solid #38955F" : "1px solid #38955F"};
  padding-bottom: ${(props) => (props.active ? "-4px" : "0px")};
  cursor: pointer;
  font-size: 1.4rem;
  font-family: "NanumSquare";
  transition: 0.15s ease-out;
  color: ${(props) => (props.active ? "#38955F" : "#FFFFFF")};
  font-weight: ${(props) => (props.active ? "bold" : null)};

  &:hover {
    border-bottom: 3px solid #38955f;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0.5rem;
    width: 6rem;
    height: 3rem;
  }
`;

export const OpenReferenceWrapper = styled.div<{ bg: boolean }>`
  font-size: 1.6rem;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1.5rem;
  cursor: pointer;
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) =>
    !props.bg
      ? "linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1))"
      : null};

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

// OLD

export const SectionTitle = styled.div<{ expand: boolean }>`
  font-size: 2rem;
  color: #bababa;
  font-weight: bold;
  text-align: left;
  position: absolute;
  left: 10rem;
  top: 8rem;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  margin-top: ${(props) => (props.expand ? "0rem" : "6rem")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 1900px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    left: 2rem;
    top: 7rem;
    font-size: 2.5rem;
  }
`;

export const IntroduceTitle = styled.div<{ expand: boolean }>`
  font-size: 3rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10rem;
  text-align: left;
  font-weight: bold;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  margin-top: ${(props) => (props.expand ? "0rem" : "6rem")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 1900px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IntroduceTitleHighlight = styled.div`
  font-size: 3rem;
  color: #2121ab;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-weight: bold;
  margin: 0 0.5rem;

  @media screen and (max-width: 1900px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IntroduceSubtitle = styled.div<{ expand: boolean }>`
  font-size: 2.5rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10rem;
  text-align: left;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  margin-top: ${(props) => (props.expand ? "1rem" : "6rem")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 1900px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IntroduceSubtitleHighlight = styled.div`
  font-size: 2.5rem;
  color: #2121ab;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-weight: bold;
  margin: 0 1rem;

  @media screen and (max-width: 1900px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileLogo = styled.img<{ expand: boolean }>`
  width: 80%;
  height: 20rem;
  object-fit: contain;
  margin: 15rem auto 2rem;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.75s ease-out;

  @media screen and (min-width: 770px) {
    display: none;
  }
`;

export const IntroduceCommentary = styled.div<{ expand: boolean }>`
  width: 110rem;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #bababa;
  text-align: left;
  margin: 6rem 10rem;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  margin-top: ${(props) => (props.expand ? "6rem" : "12rem")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 1900px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    margin-left: 1rem;
    line-height: 2.4rem;
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
`;

export const IntroduceItemArea = styled.div<{ expand: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 10rem;
  gap: 5rem;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  margin-top: ${(props) => (props.expand ? "0rem" : "6rem")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 1900px) {
    gap: 3rem;
  }

  @media screen and (max-width: 768px) {
    gap: 2rem;
    margin-left: 2rem;
    margin-top: ${(props) => (props.expand ? "4rem" : "6rem")};
  }
`;

export const IntroduceItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const IntroduceItemIcon = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1900px) {
    width: 4rem;
    height: 4rem;
  }

  @media screen and (max-width: 768px) {
    width: 20%;
    height: 3rem;
  }
`;

export const IntroduceItemCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 2rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    width: 80%;
  }
`;

export const IntroduceItemContentTitle = styled.div`
  font-size: 2rem;
  color: #2121ab;
  font-weight: bold;

  @media screen and (max-width: 1900px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const IntroduceItemContentContent = styled.div`
  font-size: 1.6rem;
  color: #9d9d9d;
  margin-top: 0.8rem;

  @media screen and (max-width: 1900px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    text-align: left;
    width: 80%;
  }
`;

export const YearlyItemWrapper = styled.div<{ expand: boolean }>`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 7rem 0 0;
  transition: 0.35s ease-out;
  position: relative;
`;

export const YearlyItem = styled.div<{ expand: boolean }>`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.75s ease-out;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const YearlyItemYear = styled.div`
  font-size: 3rem;
  color: #1a2b53;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  border-left: 1rem solid #1a2b53;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const YearlyItemContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  padding: 2.5rem 3rem;
  margin-left: 4.5rem;
  border-left: 3px solid #7d7d7d;
`;

export const YearlyItemContentDvCd = styled.div`
  font-size: 1.8rem;
  color: #38955f;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const YearlyItemContentMain = styled.div`
  font-size: 2.2rem;
  color: #000000;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const YearlyItemDot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: #38955f;
  border-radius: 5rem;
  position: absolute;
  top: 50%;
  left: -1rem;
`;
