import React, { Dispatch, SetStateAction } from "react";
import * as s from "./InformationStyled";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface InformationProps {
  expandCareer: number;
  setExpandCareer: Dispatch<SetStateAction<number>>;
  texts: {
    information_frontend_detail: string,
    information_to: string,
    information_frontend1_workexplain: string,
    information_frontend1_qualify1: string,
    information_related_major: string,
    information_frontend1_advantage1: string,
    information_frontend1_advantage2: string,
    information_frontend_workexplain1: string,
    information_frontend2_workexplain2: string,
    information_frontend2_qualify1: string,
    information_frontend_advantage1: string,
    information_frontend2_advantage2: string,
    information_frontend2_advantage3: string,
    information_frontend3_team: string,
    information_frontend3_workexplain2: string,
    information_frontend3_qualify1: string,
    information_frontend3_advantage2: string,
    information_frontend3_advantage3: string,
    information_backend1_workexplain1: string,
    information_backend1_workexplain2: string,
    information_backend1_qualify1: string,
    information_backend1_advantage1: string,
    information_backend1_advantage2: string,
    information_backend2_workexplain1: string,
    information_backend2_workexplain2: string,
    information_backend2_qualify1: string,
    information_backend2_qualify2: string,
    information_backend2_advantage1: string,
    information_backend2_advantage2: string,
    information_backend3_team: string,
    information_backend3_workexplain1: string,
    information_backend3_qualify1: string,
    information_backend3_advantage1: string,
    information_backend3_advantage2: string,
    information_Android_workexplain: string,
    information_Android_qualify: string,
    information_Android_advantage: string,
    information_iOS_workexplain: string,
    information_iOS_qualify: string,
    information_iOS_advantage: string,
    information_Management_support_job: string,
    information_Management_support_detail: string,
    information_Management_support_team: string,
    information_Management_support_workexplain1: string,
    information_Management_support_workexplain2: string,
    information_Management_support_workexplain3: string,
    information_topTitle1: string,
    information_topTitle2: string,
    information_topTitle3: string,
    information_RecruitTitle1: string,
    information_RecruitTitle2: string,
    information_Job_Detail: string,
    information_Job_Description: string,
    information_Applicants: string,
    information_preferential_treatment: string,
    information_go_saramin: string,
    information_text1: string,
    information_text2: string,
    information_text3: string,
    information_text4: string,
    information_text5: string,
    information_text6: string,
    information_text7: string,
    information_text8: string,
    information_text9: string,
    information_text10: string,
    information_text11: string, 
    information_text12: string,
    information_text13: string,
    information_text14: string,
    information_text15: string,
    information_text16: string,
    information_text17: string,
    information_text18: string, 
    information_text19: string, 
    information_text20: string, 
    information_text21: string, 
    information_text22: string, 
    information_text23: string, 
    information_text24: string, 
    information_text25: string, 
    information_text26: string,
    information_text27: string, 
    information_text28: string, 
    information_text29: string, 
    information_text30: string, 
    information_text31: string, 
    information_text32: string, 
    information_text33: string, 
    information_text34: string,
  }
}

const InformationPresenter = (props: InformationProps) => {
  const recruitInfos = [
    {
      job: "Frontend Developer",
      detail: `${props.texts.information_frontend_detail}`,
      team: "Web/App Dev. Part",
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [`${props.texts.information_frontend1_workexplain}`],
        qualify: [`${props.texts.information_frontend1_qualify1}`],
        advantage: [
          `${props.texts.information_frontend1_advantage1}`, 
          `${props.texts.information_frontend1_advantage2}`
        ],
      },
    },
    {
      job: "Frontend Developer",
      detail: "React, Typescript",
      team: "Web/App Dev. Part",
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [
          `${props.texts.information_frontend_workexplain1}`, 
          `${props.texts.information_frontend2_workexplain2}`
        ],
        qualify: [
          `${props.texts.information_frontend2_qualify1}`, 
          `${props.texts.information_related_major}`
        ],
        advantage: [
          `${props.texts.information_frontend_advantage1}`,
          `${props.texts.information_frontend2_advantage2}`,
          `${props.texts.information_frontend2_advantage3}`,
        ],
      },
    },
    {
      job: "Frontend Developer",
      detail: "Angular, Typescript",
      team: `${props.texts.information_frontend3_team}`,
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [
          `${props.texts.information_frontend_workexplain1}`, 
          `${props.texts.information_frontend3_workexplain2}`
        ],
        qualify: [
          `${props.texts.information_frontend3_qualify1}`, 
          `${props.texts.information_related_major}`
        ],
        advantage: [
          `${props.texts.information_frontend_advantage1}`,
          `${props.texts.information_frontend3_advantage2}`,
          `${props.texts.information_frontend3_advantage3}`,
        ],
      },
    },
    {
      job: "Backend Developer",
      detail: "Spring Boot, JPA",
      team: "Web/App Dev. Part",
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [
          `${props.texts.information_backend1_workexplain1}`,
          `${props.texts.information_backend1_workexplain2}`
        ],
        qualify: [
          `${props.texts.information_backend1_qualify1}`, 
          `${props.texts.information_related_major}`
        ],
        advantage: [
          `${props.texts.information_backend1_advantage1}`,
          `${props.texts.information_backend1_advantage2}`,
        ],
      },
    },
    {
      job: "Backend Developer",
      detail: "PostgreSQL, Database Design",
      team: "Web/App Dev. Part",
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [
          `${props.texts.information_backend2_workexplain1}`, 
          `${props.texts.information_backend2_workexplain2}`
        ],
        qualify: [
          `${props.texts.information_backend2_qualify1}`,
          `${props.texts.information_backend2_qualify2}`,
          `${props.texts.information_related_major}`,
        ],
        advantage: [
          `${props.texts.information_backend2_advantage1}`, 
          `${props.texts.information_backend2_advantage2}`
        ],
      },
    },
    {
      job: "Backend Developer",
      detail: "Spring Boot, MyBatis",
      team: `${props.texts.information_backend3_team}`,
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [`${props.texts.information_backend3_workexplain1}`],
        qualify: [
          `${props.texts.information_backend3_qualify1}`, 
          `${props.texts.information_related_major}`
        ],
        advantage: [
          `${props.texts.information_backend3_advantage1}`, 
          `${props.texts.information_backend3_advantage2}`
        ],
      },
    },
    {
      job: "Mobile Developer(Android)",
      detail: "Android Studio, React Native, Flutter",
      team: "Web/App Dev. Part",
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [`${props.texts.information_Android_workexplain}`],
        qualify: [
          `${props.texts.information_Android_qualify}`,
          `${props.texts.information_related_major}`,
        ],
        advantage: [`${props.texts.information_Android_advantage}`],
      },
    },
    {
      job: "Mobile Developer(iOS)",
      detail: "Swift, Objective-C, React Native, Flutter",
      team: "Web/App Dev. Part",
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [`${props.texts.information_iOS_workexplain}`],
        qualify: [
          `${props.texts.information_iOS_qualify}`,
          `${props.texts.information_related_major}`,
        ],
        advantage: [`${props.texts.information_iOS_advantage}`],
      },
    },
    {
      job: `${props.texts.information_Management_support_job}`,
      detail: `${props.texts.information_Management_support_detail}`,
      team: `${props.texts.information_Management_support_team}`,
      to: `${props.texts.information_to}`,
      explanation: {
        workexplain: [
          `${props.texts.information_Management_support_workexplain1}`,
          `${props.texts.information_Management_support_workexplain2}`,
          `${props.texts.information_Management_support_workexplain3}`,
        ],
        qualify: [""],
        advantage: [""],
      },
    },
  ];

  return (
    <>
      <s.TopImage src={"/static/img/info/info1.jpg"} />
      <s.Container>
        <s.TopArea>
          <s.TopWrapper>
            <s.TopTitle>
              {props.texts.information_topTitle1}
              <br />
              {props.texts.information_topTitle2}
              <br />
              {props.texts.information_topTitle3}
            </s.TopTitle>
          </s.TopWrapper>
        </s.TopArea>

        <s.BodyArea>
          <s.RecruitSection>
            <s.RecruitTitle id="recruit">
              {props.texts.information_RecruitTitle1}
              <br />
              {props.texts.information_RecruitTitle2}
            </s.RecruitTitle>
            <s.RecruitTable>
              <s.RecruitTbody>
                {recruitInfos.map((item: any, index: number) => (
                  <>
                    <s.RecruitTr
                      onClick={() => {
                        props.expandCareer === index
                          ? props.setExpandCareer(-1)
                          : props.setExpandCareer(index);
                      }}
                    >
                      <s.RecruitTd
                        style={{
                          fontWeight: "bold",
                          fontSize: "2rem",
                          textAlign: "left",
                        }}
                      >
                        {item.job}
                        {props.expandCareer === index ? (
                          <FaChevronUp
                            size={"2rem"}
                            style={{ marginLeft: "2rem" }}
                          />
                        ) : (
                          <FaChevronDown
                            size={"2rem"}
                            style={{ marginLeft: "2rem" }}
                          />
                        )}
                      </s.RecruitTd>
                      <s.RecruitTd
                        style={{
                          fontSize: "1.4rem",
                          color: "#989898",
                          textAlign: "right",
                        }}
                      >
                        {item.detail}
                      </s.RecruitTd>
                      <s.RecruitTd
                        style={{
                          fontSize: "1.4rem",
                          color: "#989898",
                          textAlign: "right",
                        }}
                      >
                        {item.team}
                      </s.RecruitTd>
                      <s.RecruitTd
                        style={{
                          fontSize: "1.4rem",
                          color: "#989898",
                          textAlign: "right",
                        }}
                      >
                        {item.to}
                      </s.RecruitTd>
                    </s.RecruitTr>
                    <s.RecruitTr>
                      <s.RecruitDetailTd
                        colSpan={4}
                        isExpand={props.expandCareer === index}
                      >
                        {props.expandCareer === index ? (
                          <>
                            <s.RecruitDetailTitle>
                              {props.texts.information_Job_Detail}
                            </s.RecruitDetailTitle>
                            <s.RecruitDetailSubtitle>
                            {props.texts.information_Job_Description}
                            </s.RecruitDetailSubtitle>
                            {item.explanation.workexplain.map(
                              (item: string) => (
                                <s.RecruitDetailContent>
                                  - {item}
                                </s.RecruitDetailContent>
                              )
                            )}
                            <s.RecruitDetailSubtitle>
                              {props.texts.information_Applicants}
                            </s.RecruitDetailSubtitle>
                            {item.explanation.qualify.map((item: string) => (
                              <s.RecruitDetailContent>
                                - {item}
                              </s.RecruitDetailContent>
                            ))}
                            <s.RecruitDetailSubtitle>
                              {props.texts.information_preferential_treatment}
                            </s.RecruitDetailSubtitle>
                            {item.explanation.advantage.map((item: string) => (
                              <s.RecruitDetailContent>
                                - {item}
                              </s.RecruitDetailContent>
                            ))}
                          </>
                        ) : null}
                      </s.RecruitDetailTd>
                    </s.RecruitTr>
                  </>
                ))}
              </s.RecruitTbody>
            </s.RecruitTable>
            <s.RecruitGotoLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.saramin.co.kr/zf_user/company-info/view?csn=TFlKUXJyclowS202OFJFSG1XZWJadz09&popup_yn=y"
            >
              {props.texts.information_go_saramin}
            </s.RecruitGotoLink>
          </s.RecruitSection>

          <s.LifestyleSection>
            <s.LifestyleLeft>
              <s.LifestyleTitle id='lifeStyle'>Life Style in DOUBLT</s.LifestyleTitle>
              <s.LifestyleSubtitle>
                {props.texts.information_text1}
                <br />
                {props.texts.information_text2}
              </s.LifestyleSubtitle>
              <s.LifestyleWrapper>
                <s.LifestyleItem>
                  <s.LifestyleImg src={"https://picsum.photos/900"} />
                  <s.LifestyleItemWrapper>
                    <s.LifestyleItemTitle>
                      {props.texts.information_text3}
                      <br />
                      {props.texts.information_text4}
                    </s.LifestyleItemTitle>
                    <s.LifestyleItemContent>
                      {props.texts.information_text5}
                      <br />
                      {props.texts.information_text6}
                      <br />
                      {props.texts.information_text7}<br />
                      {props.texts.information_text8}
                    </s.LifestyleItemContent>
                  </s.LifestyleItemWrapper>
                </s.LifestyleItem>

                <s.LifestyleItem>
                  <s.LifestyleImg src={"https://picsum.photos/901"} />
                  <s.LifestyleItemWrapper>
                    <s.LifestyleItemTitle>
                      {props.texts.information_text9}
                      <br />
                      {props.texts.information_text10}
                    </s.LifestyleItemTitle>
                    <s.LifestyleItemContent>
                      {props.texts.information_text11}
                      <br />
                      {props.texts.information_text12}
                      <br />
                      {props.texts.information_text13}
                    </s.LifestyleItemContent>
                  </s.LifestyleItemWrapper>
                </s.LifestyleItem>

                <s.LifestyleItem>
                  <s.LifestyleImg src={"https://picsum.photos/902"} />
                  <s.LifestyleItemWrapper>
                    <s.LifestyleItemTitle>
                    {props.texts.information_text14}<br />
                    {props.texts.information_text15}
                    </s.LifestyleItemTitle>
                    <s.LifestyleItemContent>
                      {props.texts.information_text16}
                      <br />
                      {props.texts.information_text17}
                      <br />
                      {props.texts.information_text18}
                    </s.LifestyleItemContent>
                  </s.LifestyleItemWrapper>
                </s.LifestyleItem>

                <s.LifestyleItem>
                  <s.LifestyleImg src={"https://picsum.photos/903"} />
                  <s.LifestyleItemWrapper>
                    <s.LifestyleItemTitle>
                      {props.texts.information_text19}
                      <br />
                      {props.texts.information_text20}
                    </s.LifestyleItemTitle>
                    <s.LifestyleItemContent>
                      {props.texts.information_text21}
                      <br />
                      {props.texts.information_text22}
                      <br />
                      {props.texts.information_text23}
                      <br />
                      {props.texts.information_text24}
                    </s.LifestyleItemContent>
                  </s.LifestyleItemWrapper>
                </s.LifestyleItem>
              </s.LifestyleWrapper>
            </s.LifestyleLeft>

            <s.LifestyleRight>
              <s.LifestyleSubtitle>{props.texts.information_text25}</s.LifestyleSubtitle>
              <s.LifestyleRightItem color={"#ABDCAC"}>
                {props.texts.information_text26}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCABAC"}>
                {props.texts.information_text27}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#ABACDC"}>
                {props.texts.information_text28}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCDC87"}>
                {props.texts.information_text29}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCDC87"}>
                {props.texts.information_text30}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCDC87"}>
                {props.texts.information_text31}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCDC87"}>
               {props.texts.information_text32}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCDC87"}>
                {props.texts.information_text33}
              </s.LifestyleRightItem>
              <s.LifestyleRightItem color={"#DCDC87"}>
                {props.texts.information_text34}
              </s.LifestyleRightItem>
            </s.LifestyleRight>
          </s.LifestyleSection>
        </s.BodyArea>
      </s.Container>
    </>
  );
};

export default InformationPresenter;
