import React, { Dispatch } from 'react';
import * as s from './FooterStyled';
import { Link } from 'react-router-dom';

interface FooterProps{
  setLanguage: Dispatch<React.SetStateAction<string>>;
  texts: {
    company_name: string;
    footer_textRegular1: string;
  };
}

const FooterPresenter = (props: FooterProps) => {
  return (
    <s.Container>
      <s.TextArea>
        <s.BottomTextBold>{props.texts.company_name}</s.BottomTextBold>
        <s.BottomTextRegular>{props.texts.footer_textRegular1}</s.BottomTextRegular>
        <s.BottomTextRegular>Copyright© 2022 DOUBLT CO.LTD., All Right Reserved</s.BottomTextRegular>
      </s.TextArea>
      <s.LocaleButton onClick={() => props.setLanguage('ko')}> 한국어 </s.LocaleButton>
      <s.LocaleButton onClick={() => props.setLanguage('en')}> English </s.LocaleButton>
    </s.Container>
  );
};

export default FooterPresenter;
