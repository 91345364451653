import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Service Route Area
import Main from './main';

import Project from './project';
import News from './news';

import NotFound from '../components/404';

import AboutUs from '../views/aboutUs';
import Business from '../views/business';

import Login from './login';
import SignUp from './signUp';

import Admin from './admin';

import ProjectList from './admin/project/projectList';
import ProjectDetail from './admin/project/projectDetail';
import ProjectCreate from './admin/project/projectCreate';

import NewsList from './admin/news/newsList';
import NewsDetail from './admin/news/newsDetail';
import NewsCreate from './admin/news/newsCreate';

import UserAskList from './admin/user/userAskList';

import Header from '../components/header';
import Footer from '../components/footer';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

import Information from './information';


import { useRecoilState } from 'recoil';
import * as recoilItem from '../util/recoilitem';
import { useEffect, useState } from 'react';
import ChannelService from '../util/ChannelService';
import ReferenceModal from '../components/ReferenceModal';
import NewsModal from '../components/NewsModal';

const Router = () => {

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalPicture, setModalPicture] = useState<any[]>([]);
  const [modalItem, setModalItem] = useState<any>(null);
  const [showExplainArea, setShowExplainArea] = useState<boolean>(false);

  const [newsModalVisible, setNewsModalVisible] = useState<boolean>(false);
  const [newsModalItem, setNewsModalItem] = useState<any>(null);

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  // useEffect(() => {
  //   ChannelService.boot({
  //     "pluginKey": "be0d42a0-6c68-47bd-8053-c09a9d1f45d9", //please fill with your plugin key
  //     "memberId": userInfo.userId,
  //     "profile": {
  //       "userName": userInfo.userNm,
  //       "userEmail": userInfo.userEmail, 
  //       "userTypeCd": userInfo.userTypeCd,
  //     }
  //   });
  // }, [])
  

  return (
    <>
      
      <BrowserRouter>
        <Header scrollPosition={scrollPosition}/>
        <Routes>
          <Route path="/" element={<Main setShowExplainArea={setShowExplainArea} setModalItem={setModalItem} setModalVisible={setModalVisible} setModalPicture={setModalPicture}/>} />

          <Route path="/project" element={<Navigate to="/project/-1"/>}/>
          <Route path="/project/:category" element={<Project setShowExplainArea={setShowExplainArea} setModalItem={setModalItem} setModalVisible={setModalVisible} setModalPicture={setModalPicture}/>}/>

          <Route path="/news" element={<News setNewsModalItem={setNewsModalItem} setNewsModalVisible={setNewsModalVisible}/>}/>
          <Route path="/business/:category" element={<Business/>}/>
          <Route path="/aboutUs" element={<AboutUs setNewsModalItem={setNewsModalItem} setNewsModalVisible={setNewsModalVisible}/>}/>

          <Route path="/login" element={<Login/>}/>
          <Route path="/signUp" element={<SignUp/>}/>

          <Route path="/admin" element={<Admin/>}/>
          <Route path="/admin/project" element={<ProjectList/>}/>
          <Route path="/admin/project/:id" element={<ProjectDetail setShowExplainArea={setShowExplainArea} setModalItem={setModalItem} setModalVisible={setModalVisible} setModalPicture={setModalPicture}/>}/>
          <Route path="/admin/project/create" element={<ProjectCreate setShowExplainArea={setShowExplainArea} setModalItem={setModalItem} setModalVisible={setModalVisible} setModalPicture={setModalPicture}/>}/>
          <Route path="/admin/news" element={<NewsList/>}/>
          <Route path="/admin/news/:id" element={<NewsDetail/>}/>
          <Route path="/admin/news/create" element={<NewsCreate/>}/>
          <Route path="/admin/user/userAskList" element={<UserAskList/>}/>
          <Route path="/info" element={<Information/>}/>

          {/* Not Found */}
          <Route path="/notfound" element={<NotFound/>} />
          <Route path="*" element={<Navigate to="/notfound" />}/>
        </Routes>
        <Footer/>
        <ReferenceModal 
          isOpen={modalVisible} 
          onClose={() => setModalVisible(false)}
          showExplainArea={true}
          pictures={modalPicture}
          title={modalItem?.title}
          content={modalItem?.content}
          />
        <NewsModal
          isOpen={newsModalVisible}
          onClose={() => setNewsModalVisible(false)}
          item={newsModalItem}/>
      </BrowserRouter>
      {/* {
        isLoading ? 
        <Loading>
          <ReactLoading type={'bars'} color={'#1A2D56'} height={'10%'} width={'10%'} />
        </Loading>
        : null
      } */}
    </>
  );
};


export const Loading = styled.div`
  display: flex;
  padding: 30rem 0;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;

  @media screen and (max-width: 1200px) {
    padding: 25rem 0;
  }

  @media screen and (max-width: 768px) {
    
  }
`;

export default Router;
