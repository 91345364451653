import React from 'react';
import * as s from './AdminStyled';
import { Link } from 'react-router-dom';

interface AdminProps {
}

const AdminPresenter = (props: AdminProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/1076/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>ADMIN</s.TopTitle>
          <s.TopSubtitle>관리자페이지입니다.</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>
      <s.AdminFunctionWrapper>
        <s.AdminFunctionButton to="/admin/project">레퍼런스 관리</s.AdminFunctionButton>
        <s.AdminFunctionButton to="/admin/news">뉴스 관리</s.AdminFunctionButton>
        <s.AdminFunctionButton to="/admin/user/userAskList">문의 관리</s.AdminFunctionButton>
      </s.AdminFunctionWrapper>
    </s.Container>
  );
};

export default AdminPresenter;
