import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import LoginPresenter from './LoginPresenter';

import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { userApi } from '../../api/api-user';
import { Navigate, useNavigate } from 'react-router-dom';

import korean from '../../util/korean.json';
import english from '../../util/english.json';

const LoginContainer = () => {

  const navigate = useNavigate();
  
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);

  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    login_inputId: '',
    login_inputPw: '',
    login_success: '',
    login_fail: '',
    login: '',
    login_text1: '',
    login_text2: '',
    id: '',
    password: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  const login = async () => {
    if (!loginId) {
      util.makeMsg(`${texts.login_inputId}`, 'error');
      return;
    }

    if (!password) {
      util.makeMsg(`${texts.login_inputPw}`, 'error');
      return;
    }

    let params = {
      loginId: loginId,
      password: password
    };

    let res = await userApi.login(params);
    if (res.data?.data.rsltCd === '00') {
      setToken({
        accessToken: res.data.data.data.token
      });
      setUserInfo({
        userId: res.data.data.data.userInfo.userId,
        userNm: res.data.data.data.userInfo.userNm,
        userEmail: res.data.data.data.userInfo.userEmail,
        userTypeCd: res.data.data.data.userInfo.userTypeCd
      })
      
      util.makeMsg(`${texts.login_success}`, 'success');
      navigate('/');
    } else {
      util.makeMsg(`${texts.login_fail}`, 'error');
    }
  }

  const checkEnter = (event: any) => {
    if (event.key === 'Enter') {
      login();
    }
  }

  return (
    <>
      <LoginPresenter
        loginId={loginId}
        setLoginId={setLoginId}
        password={password}
        setPassword={setPassword}

        checkEnter={checkEnter}
        login={login}

        texts={texts}
      />
    </>
  );
};

LoginContainer.defaultProps = {};

export default LoginContainer;
