import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;


export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;

export const LoginArea = styled.div`
  width: 70%;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const LoginTitle = styled.div`
  font-size: 2.5rem;
  color: #4D4D4D;
  font-weight: bold;
  margin: 0 auto 3rem;
`;


export const LoginBox = styled.div`
  width: 70rem;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEDEDE;
`;

export const LoginRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 7rem;
`;

export const LoginLabel = styled.div`
  width: 10rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.6rem;
  color: #4D4D4D;
  font-weight: bold;
`;

export const LoginInput = styled.input`
  width: 30rem;
  padding: 0.5rem 1rem;
  border: 1px solid #DEDEDE;
  font-size: 1.6rem;

  &:focus {
    border: 1px solid #000000;
  }
`;

export const LoginButton = styled.div`
  padding: 2rem 4rem;
  margin: 4rem auto;
  font-size: 1.6rem;
  font-weight: bold;
  background-color: #000000;
  color: #FFFFFF;
  cursor: pointer;
`