import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 83rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: auto;
  margin: 2rem auto;
  border-top: 1px solid #CECECE;
  border-bottom: 1px solid #CECECE;
  padding: 2rem 0;
`;

export const FilterHeader = styled.div`
  width: 10%;
  height: 100%;
  font-size: 1.6rem;
  color: #4D4D4D;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
`;

export const FilterItemWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
`;

export const FilterItem = styled.div<{active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  color: ${props => props.active ? '#FFFFFF' : '#4D4D4D'};
  background-color: ${props => props.active ? '#1A2D56' : '#FFFFFF'};
  border: 1px solid #1A2D56;

  &:hover {
    color: #FFFFFF;
    background-color: #1A2D56;
  }
`;

export const BoardItemTable = styled.table`
  width: 70%;
  margin: 4rem auto;
`;

export const BoardItemThead = styled.thead`

`;

export const BoardItemTh = styled.th`
  background-color: #1A2D56;
  color: #FFFFFF;
  font-size: 1.4rem;
  border-right: 1px solid #FFFFFF;
  padding: 1rem;
`;

export const BoardItemTbody = styled.tbody`
`;

export const BoardItemTr = styled.tr`
  border-bottom: 1px solid #CECECE;
  cursor: pointer;

  &:hover {
    color: #1A2D56;
  }
`;

export const BoardItemTd = styled.td`
  padding: 1rem;
  font-size: 1.4rem;

`;

export const PagingArea = styled.div`
  display: flex;
  width: 70%;
  height: auto;
  margin: 0 auto 5rem;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const NewButton = styled(Link)`
  padding: 1rem 2rem;
  background-color: #1A2D56;
  color: #FFFFFF;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;