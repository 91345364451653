import React, { Dispatch, useEffect } from "react";
import * as s from "./HeaderStyled";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { FaUserCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Link, animateScroll as scroll } from "react-scroll";
interface HeaderProps {
  currentFocus: number;
  showMobileSidebar: boolean;
  showLoginModal: boolean;
  userInfo: any;
  scrollPosition: number;
  location: any;
  texts: {
    header_Company_introduction: string;
    header_Company_sales_information: string;
    header_history: string;
    header_news: string;
    Physical_Security_Development: string;
    Development_of_Industrial_Safety_AI_System: string;
    IT_Infra: string;
    jikgu: string;
    header_Vision: string;
    header_job_posting: string;
    header_doublt_culture: string;
    header_nim: string;
    mypage: string;
    Anonymous_user: string;
    login: string;
    signUp_member: string;
    Software_development: string;
    IT_Commerce: string;
    Appliance_commerce: string;
  };

  setCurrentFocus: Dispatch<React.SetStateAction<number>>;
  setShowMobileSidebar: Dispatch<React.SetStateAction<boolean>>;
  setShowLoginModal: Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
}

const HeaderPresenter = (props: HeaderProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    // URL에 anchor를 포함하여 이동하려는 섹션을 지정합니다.
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const handleLinkClick = (targetId: any) => {
    // Link 클릭 시 URL을 변경하고 페이지 이동
    navigate(`/aBoutUs#${targetId}`);
  };
  return (
    <>
      <s.Container
        isMain={props.location.pathname === "/"}
        expanded={props.currentFocus !== -1}
        scrollPosition={props.scrollPosition}
      >
        <s.LogoArea to="/">
          <s.LogoImg
            // src={props.scrollPosition < 30 && props.currentFocus === -1 ? "/static/logo/logo_wide_white.png" : "/static/logo/logo_wide.png"}/>
            src={
              props.currentFocus === -1
                ? "/static/logo/logo_white.png"
                : "/static/logo/logo.png"
            }
          />
        </s.LogoArea>

        {/* Web */}
        <s.TopMenuArea>
          <s.TopMenuItem
            to="/aboutUs"
            focused={props.currentFocus === 0}
            scrollPosition={props.scrollPosition}
            expanded={props.currentFocus !== -1}
            isMain={props.location.pathname === "/"}
            onMouseEnter={() => props.setCurrentFocus(0)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          >
            ABOUT
          </s.TopMenuItem>
          <s.TopMenuItem
            to="/business/0"
            focused={props.currentFocus === 1}
            scrollPosition={props.scrollPosition}
            expanded={props.currentFocus !== -1}
            isMain={props.location.pathname === "/business"}
            onMouseEnter={() => props.setCurrentFocus(1)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          >
            BUSINESS
          </s.TopMenuItem>
          <s.TopMenuItem
            to="/info"
            focused={props.currentFocus === 2}
            scrollPosition={props.scrollPosition}
            expanded={props.currentFocus !== -1}
            isMain={props.location.pathname === "/"}
            onMouseEnter={() => props.setCurrentFocus(2)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          >
            INFORMATION
          </s.TopMenuItem>
          {/* <s.TopMenuItem 
          to="/aboutUs"
          focused={props.currentFocus === 3} 
          onMouseEnter={() => props.setCurrentFocus(3)}
          scrollPosition={props.scrollPosition}
          expanded={props.currentFocus !== -1}
          isMain={props.location.pathname === '/'}
          onMouseLeave={() => props.setCurrentFocus(-1)}>ABOUT US</s.TopMenuItem> */}
          <s.UserIcon
            onClick={() => props.setShowLoginModal(!props.showLoginModal)}
          >
            <FaUserCircle size={"100%"} style={{ color: "#000000" }} />
            <s.LoginModal
              show={props.showLoginModal}
              isLogin={props.userInfo.userId !== ""}
            >
              {props.userInfo.userId === "" ? (
                <>
                  <s.LoginModalItem to="/login">LOGIN</s.LoginModalItem>
                  <s.LoginModalItem to="/signup">SIGN UP</s.LoginModalItem>
                </>
              ) : props.userInfo.userTypeCd === "ADMIN" ? (
                <>
                  <s.LoginModalText>{props.userInfo.userNm}{props.texts.header_nim}</s.LoginModalText>
                  <s.LoginModalItem to="/admin">ADMIN</s.LoginModalItem>
                  <s.LoginModalItem to="/" onClick={() => props.logout()}>
                    LOG OUT
                  </s.LoginModalItem>
                </>
              ) : (
                <>
                  <s.LoginModalText>{props.userInfo.userNm}{props.texts.header_nim}</s.LoginModalText>
                  <s.LoginModalItem to="/">MY PAGE</s.LoginModalItem>
                  <s.LoginModalItem to="/" onClick={() => props.logout()}>
                    LOG OUT
                  </s.LoginModalItem>
                </>
              )}
            </s.LoginModal>
          </s.UserIcon>
        </s.TopMenuArea>
        <s.WebHoverArea expanded={props.currentFocus !== -1}>
          <s.SubMenuLeftMargin />
          <s.TopMenuArea>
            <s.WebSubMenuItemCol
              onMouseEnter={() => props.setCurrentFocus(0)}
              onMouseLeave={() => props.setCurrentFocus(-1)}
            >
              {/* Add Some Submenu */}
              <s.SubMenuItem to="/aboutUs" style={{ margin: 0 }}>
                <s.SubMenuDivItem>{props.texts.header_Company_introduction}</s.SubMenuDivItem>
              </s.SubMenuItem>
              <s.SubMenuDivItem>
                <Link
                  activeClass="active"
                  to="targetSection2"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={200}
                  onClick={() => handleLinkClick("targetSection2")}
                >
                  {props.texts.header_Company_sales_information}
                </Link>
              </s.SubMenuDivItem>
              <s.SubMenuDivItem>
                <Link
                  activeClass="active"
                  to="targetSection3"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={200}
                  onClick={() => handleLinkClick("targetSection3")}
                >
                  {props.texts.header_history}
                </Link>
              </s.SubMenuDivItem>
              <s.SubMenuDivItem>
                <Link
                  activeClass="active"
                  to="targetSection4"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={200}
                  onClick={() => handleLinkClick("targetSection4")}
                >
                  {props.texts.header_news}
                </Link>
              </s.SubMenuDivItem>
              {/* <s.SubMenuItem to="/news">관련뉴스</s.SubMenuItem> */}
            </s.WebSubMenuItemCol>
            <s.WebSubMenuItemCol
              onMouseEnter={() => props.setCurrentFocus(1)}
              onMouseLeave={() => props.setCurrentFocus(-1)}
            >
              {/* Add Some Submenu */}
              <s.SubMenuItem to="/business/0">{props.texts.Physical_Security_Development}</s.SubMenuItem>
              <s.SubMenuItem to="/business/1">{props.texts.Development_of_Industrial_Safety_AI_System}</s.SubMenuItem>
              <s.SubMenuItem to="/business/2">{props.texts.IT_Infra}</s.SubMenuItem>
              <s.SubMenuItem to="/business/3">{props.texts.jikgu}</s.SubMenuItem>
            </s.WebSubMenuItemCol>
            <s.WebSubMenuItemCol
              onMouseEnter={() => props.setCurrentFocus(2)}
              onMouseLeave={() => props.setCurrentFocus(-1)}
            >
              {/* Add Some Submenu */}
              <s.SubMenuItem to="/info">{props.texts.header_Vision}</s.SubMenuItem>
              <s.SubMenuItem to="/info">
                <Link
                  activeClass="active"
                  to="recruit"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={200}
                  onClick={() => navigate(`/info#recruit`)}
                >
                  {props.texts.header_job_posting}
                </Link>
              </s.SubMenuItem>
              <s.SubMenuItem to="/info">
                <Link
                  activeClass="active"
                  to="lifeStyle"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={200}
                  onClick={() => navigate(`/info#lifeStyle`)}
                >
                  {props.texts.header_doublt_culture}
                </Link>
              </s.SubMenuItem>
            </s.WebSubMenuItemCol>
            {/* <s.WebSubMenuItemCol
            onMouseEnter={() => props.setCurrentFocus(3)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          > */}
            {/* Add Some Submenu */}
            {/* </s.WebSubMenuItemCol> */}
            <s.SubMenuRightMargin />
          </s.TopMenuArea>
        </s.WebHoverArea>

        {/* Mobile */}
        <s.BurgerIcon
          onClick={() => {
            props.setShowMobileSidebar(!props.showMobileSidebar);
          }}
        >
          <GiHamburgerMenu size={"100%"} />
        </s.BurgerIcon>
        <s.ModalSidebarWrapper show={props.showMobileSidebar}>
          <s.ModalBackdropArea
            onClick={() => props.setShowMobileSidebar(false)}
          />
          <s.MobileSidebar show={props.showMobileSidebar}>
            <s.MobileSidebarTop>
              {props.userInfo.userId !== "" ? (
                <s.MobileSidebarTopInfoWrapper>
                  <s.MobileSidebarTopTextInfo>
                    {props.userInfo.userNm}{props.texts.header_nim}
                  </s.MobileSidebarTopTextInfo>
                  <s.MobileSidebarTopNaviWrapper>
                    {props.userInfo.userTypeCd === "ADMIN" ? (
                      <s.MobileSidebarTopNavi
                        to="/admin"
                        onClick={() => props.setShowMobileSidebar(false)}
                      >
                        ADMIN
                      </s.MobileSidebarTopNavi>
                    ) : (
                      <s.MobileSidebarTopNavi
                        to="/"
                        onClick={() => props.setShowMobileSidebar(false)}
                      >
                        {props.texts.mypage}
                      </s.MobileSidebarTopNavi>
                    )}

                    <s.MobileSidebarTopNavi
                      to="/"
                      onClick={() => props.logout()}
                    >
                      {props.texts.mypage}
                    </s.MobileSidebarTopNavi>
                  </s.MobileSidebarTopNaviWrapper>
                </s.MobileSidebarTopInfoWrapper>
              ) : (
                <s.MobileSidebarTopInfoWrapper>
                  <s.MobileSidebarTopTextInfo>
                    {props.texts.Anonymous_user}
                  </s.MobileSidebarTopTextInfo>
                  <s.MobileSidebarTopNaviWrapper>
                    <s.MobileSidebarTopNavi
                      to="/login"
                      onClick={() => props.setShowMobileSidebar(false)}
                    >
                      {props.texts.login}
                    </s.MobileSidebarTopNavi>
                    <s.MobileSidebarTopNavi
                      to="/signup"
                      onClick={() => props.setShowMobileSidebar(false)}
                    >
                      {props.texts.signUp_member}
                    </s.MobileSidebarTopNavi>
                  </s.MobileSidebarTopNaviWrapper>
                </s.MobileSidebarTopInfoWrapper>
              )}
              <s.MobileSidebarCloseButton
                onClick={() => props.setShowMobileSidebar(false)}
              >
                <MdClose size={"3rem"} />
              </s.MobileSidebarCloseButton>
            </s.MobileSidebarTop>
            <s.MobileSidebarTopMenu
              focused={props.currentFocus === 0}
              onClick={() => {
                props.currentFocus === 0
                  ? props.setCurrentFocus(-1)
                  : props.setCurrentFocus(0);
              }}
            >
              ABOUT DOUBLT
              <s.MobileSidebarTopMenuIcon>
                {props.currentFocus === 0 ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </s.MobileSidebarTopMenuIcon>
            </s.MobileSidebarTopMenu>
            <s.MobileSidebarSubMenuWrapper focused={props.currentFocus === 0}>
              <s.MobileSidebarSubMenuItem
                to="/aboutUs"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.header_Company_introduction}
              </s.MobileSidebarSubMenuItem>
              {/* <s.MobileSidebarSubMenuItem to="/news" onClick={() => {props.setShowMobileSidebar(false)}}>관련뉴스</s.MobileSidebarSubMenuItem> */}
            </s.MobileSidebarSubMenuWrapper>

            <s.MobileSidebarTopMenu
              focused={props.currentFocus === 1}
              onClick={() => {
                props.currentFocus === 1
                  ? props.setCurrentFocus(-1)
                  : props.setCurrentFocus(1);
              }}
            >
              BUSINESS
              <s.MobileSidebarTopMenuIcon>
                {props.currentFocus === 1 ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </s.MobileSidebarTopMenuIcon>
            </s.MobileSidebarTopMenu>
            <s.MobileSidebarSubMenuWrapper focused={props.currentFocus === 1}>
              <s.MobileSidebarSubMenuItem
                to="/business/0"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.Software_development}
              </s.MobileSidebarSubMenuItem>
              <s.MobileSidebarSubMenuItem
                to="/business/1"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.IT_Commerce}
              </s.MobileSidebarSubMenuItem>
              <s.MobileSidebarSubMenuItem
                to="/business/2"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.Appliance_commerce}
              </s.MobileSidebarSubMenuItem>
              <s.MobileSidebarSubMenuItem
                to="/business/3"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.jikgu}
              </s.MobileSidebarSubMenuItem>
            </s.MobileSidebarSubMenuWrapper>
            <s.MobileSidebarTopMenu
              focused={props.currentFocus === 2}
              onClick={() => {
                props.currentFocus === 2
                  ? props.setCurrentFocus(-1)
                  : props.setCurrentFocus(2);
              }}
            >
              INFORMATION
              <s.MobileSidebarTopMenuIcon>
                {props.currentFocus === 2 ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </s.MobileSidebarTopMenuIcon>
            </s.MobileSidebarTopMenu>
            <s.MobileSidebarSubMenuWrapper focused={props.currentFocus === 2}>
              <s.MobileSidebarSubMenuItem
                to="/info"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.header_job_posting}
              </s.MobileSidebarSubMenuItem>
              <s.MobileSidebarSubMenuItem
                to="/info"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.header_doublt_culture}
              </s.MobileSidebarSubMenuItem>
              <s.MobileSidebarSubMenuItem
                to="/info"
                onClick={() => {
                  props.setShowMobileSidebar(false);
                }}
              >
                {props.texts.header_Vision}
              </s.MobileSidebarSubMenuItem>
            </s.MobileSidebarSubMenuWrapper>
            {/* <s.MobileSidebarTopMenu
            focused={props.currentFocus === 3} 
            onClick={() => {props.currentFocus === 3 ? props.setCurrentFocus(-1) : props.setCurrentFocus(3)}}>
              ABOUT US
              <s.MobileSidebarTopMenuIcon>
                {
                  props.currentFocus === 3 ?
                  <AiOutlineMinus/>
                  : <AiOutlinePlus/>
                }
              </s.MobileSidebarTopMenuIcon>
          </s.MobileSidebarTopMenu>
          <s.MobileSidebarSubMenuWrapper focused={props.currentFocus === 3}>
            <s.MobileSidebarSubMenuItem to="/aboutUs" onClick={() => {props.setShowMobileSidebar(false)}}>ABOUT US</s.MobileSidebarSubMenuItem>
         </s.MobileSidebarSubMenuWrapper> */}
          </s.MobileSidebar>
        </s.ModalSidebarWrapper>
      </s.Container>
    </>
  );
};

export default HeaderPresenter;
