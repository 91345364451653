import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import HeaderPresenter from './HeaderPresenter';

import * as recoilItem from '../../util/recoilitem';
import { makeMsg } from '../../util/util';
import korean from '../../util/korean.json';
import english from '../../util/english.json';

interface HeaderProps {
  scrollPosition: number
}

const HeaderContainer = (props: HeaderProps) => {

  const [currentFocus, setCurrentFocus] = useState<number>(-1);
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    header_Company_introduction: '',
    header_Company_sales_information: '',
    header_history: '',
    header_news: '',
    Physical_Security_Development: '',
    Development_of_Industrial_Safety_AI_System: '',
    IT_Infra: '',
    jikgu: '',
    header_Vision: '',
    header_job_posting: '',
    header_doublt_culture: '',
    header_nim: '',
    mypage: '',
    Anonymous_user: '',
    login: '',
    signUp_member: '',
    Software_development: '',
    IT_Commerce: '',
    Appliance_commerce: '',
    header_logout_msg: '',
  });

  const location = useLocation();

  const logout = () => {
    setIsLoading(true);
    setUserInfo({
      userId: '',
      userNm: '',
      userEmail: '',
      userTypeCd: '',
    });
    setToken(null);
    setShowLoginModal(false);
    setShowMobileSidebar(false);
    makeMsg(`${texts.header_logout_msg}`, 'success');
    setIsLoading(false);
  }

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  return (
    <>
      <HeaderPresenter
        {...props}
        currentFocus={currentFocus}
        showMobileSidebar={showMobileSidebar}
        showLoginModal={showLoginModal}
        userInfo={userInfo}
        location={location}
        texts={texts}

        setCurrentFocus={setCurrentFocus}
        setShowMobileSidebar={setShowMobileSidebar}
        setShowLoginModal={setShowLoginModal}
        logout={logout}
      />
    </>
  );
};

HeaderContainer.defaultProps = {};

export default HeaderContainer;
