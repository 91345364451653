import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowRight, FaCaretLeft, FaCaretRight, FaChevronLeft, FaChevronRight, FaCross } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import ReactQuill from 'react-quill';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import korean from '../../util/korean.json';
import english from '../../util/english.json';
import { useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';

interface NewsModalProps {
  isOpen: boolean,
  onClose: any,
  item?: any
}

const NewsModal = (props: NewsModalProps) => {

  const quillRef = useRef<HTMLDivElement>(null);
  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    company_name: '',
  });

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.scroll(0, 0);
    }

    if (props.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.isOpen])

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]); 

  return (
    <NewsModalStyle isOpen={props.isOpen} onClick={props.onClose}>
      <ModalInner onClick={(e) => {e.stopPropagation()}}>
        <CloseButton onClick={props.onClose}><MdClose size={'100%'} color={'#000000'}/></CloseButton>
        <ModalTopArea>
          <ModalTitle>{props.item?.title}</ModalTitle>
          <ModalWriteDtm>{moment(props.item?.reg_dtm).format('YYYY-MM-DD HH:mm')}</ModalWriteDtm>
        </ModalTopArea>
        <ModalWriterArea>
          <ModalWriterPicture src={'https://picsum.photos/400'}/>
          <ModalWriterName>{texts.company_name}</ModalWriterName>
        </ModalWriterArea>
        <QuillWrapper>
          {
            props.isOpen ?
            <ReactQuill 
              style={{height: "100%", width: '100%', borderWidth: 0}} 
              theme="snow"
              readOnly={true}
              modules={{toolbar: false}}
              value={props.item?.content || ''} />
            : null
          }
          
        </QuillWrapper>
      </ModalInner>
    </NewsModalStyle>
  )
}

const CloseButton = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
    z-index: 999999;
  }
`;

const NewsModalStyle = styled.div<{isOpen: boolean}>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  z-index: 999999999;
`;

const ModalInner = styled.div`
  width: 50vw;
  height: 80vh;
  overflow: hidden;
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0.3rem 0.3rem #DEDEDE;

  border: 1px solid #FFFFFF;

  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 80vh;
  }
`;

const ModalTopArea = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 2rem;
`;

const ModalTitle = styled.div`
  width: 75%;
  font-size: 3rem;
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ModalWriteDtm = styled.div`
  width: 25%;
  font-size: 1.4rem;
  color: #1A2D56;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: right;
  }
`;

const ModalWriterArea = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 1rem;
  border-bottom: 1px solid #DEDEDE;
`;

const ModalWriterPicture = styled.img`
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 5rem;

  @media screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
`;

const ModalWriterName = styled.div`
  margin: 0 0 0 1rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: #000000;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const QuillWrapper = styled.div`
  height: 70%;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0;
`;

export default NewsModal