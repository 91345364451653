import styled, { keyframes } from "styled-components";
import theme from "../../components/styles/theme";

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding-top: 6rem;
    background-color: #004560;
  }
`;

export const TopArea = styled.div<{ expand: boolean }>`
  width: ${(props) => (props.expand ? "70%" : "10%")};
  height: 80rem;
  position: relative;
  margin: 0 0 12rem;
  transition: 0.35s ease-out;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: ${(props) => (props.expand ? "50rem" : "5rem")};
  }
`;

export const TopImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
`;

export const TopMinWrapper = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem 0;
  top: 0;
  left: 0;
  background-color: ${(props) => props.background};
  z-index: 999;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const TopTitle = styled.div<{ expand: boolean }>`
  font-size: 5rem;
  color: #ffffff;
  font-weight: bold;
  margin-top: 4rem;
  font-family: "NanumSquare";
  position: absolute;
  left: 10rem;
  bottom: 42%;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.35s ease-out;

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    position: relative;
    margin: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

export const TopSubtitle = styled.div<{ expand: boolean }>`
  font-size: 3rem;
  color: #ffffff;
  margin-top: 1.2rem;
  position: absolute;
  left: 10rem;
  bottom: 35%;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.35s ease-out;

  @media screen and (max-width: 768px) {
    position: relative;
    font-size: 2rem;
    margin: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

export const MainDescriptionWrapper = styled.div<{ expand: boolean }>`
  width: 70%;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  position: absolute;
  left: 10rem;
  bottom: 2rem;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.35s ease-out;
  text-align: left;
  @media screen and (max-width: 768px) {
    position: relative;
    margin: 1rem 0;
    width: 90%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
  }
`;

export const MainDescription = styled.div`
  font-size: 2rem;
  color: #dedede;
  font-weight: 500;
  letter-spacing: 0.1rem;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const RedirectButton = styled.a`
  font-size: 2rem;
  border: 1px solid #ffffff;
  text-decoration: none;
  position: absolute;
  bottom: 4rem;
  height: 3rem;
  padding: 3rem;
  color: #ffffff;
  font-weight: bold;
  left: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  border-radius: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    bottom: 1rem;
  }
`;

export const BusinessAreaSection = styled.div<{
  isGrid: boolean;
  expand: boolean;
}>`
  width: 70%;
  min-height: 20rem;
  margin: 5rem auto 15rem;
  display: ${(props) => (props.isGrid ? "grid" : "flex")};
  ${(props) => (props.isGrid ? "grid-template-columns: 1fr 1fr 1fr" : null)};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6rem;

  opacity: ${(props) => (props.expand ? "1" : "0")};
  padding-top: ${(props) => (props.expand ? "0rem" : "20rem")};
  transition: 0.35s ease-out;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 3rem auto 5rem;
    display: flex;
  }
`;

export const SectionTitle = styled.div<{ expand: boolean; color?: string }>`
  font-size: 4rem;
  margin: 12rem auto 5rem;
  font-weight: bold;
  text-align: left;
  width: 70%;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.35s ease-out;
  color: ${(props) => (props.color ? props.color : "#000000")};

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
    margin: 6rem auto 3rem;
    width: 90%;
  }
`;

export const BusinessAreaItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const BusinessAreaTitle = styled.div<{ color: string }>`
  font-size: 2.4rem;
  color: #000000;
  padding: 0.5rem 4rem 0.5rem 0rem;
  margin-bottom: 1rem;
  border-top: 4px solid ${(props) => (props.color ? props.color : "#1A2D56")};
  text-align: left;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
    width: 15rem;
  }
`;

export const BusinessAreaContent = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ababab;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const ProcessArea = styled.div<{ background: string }>`
  width: 100%;
  background-color: ${(props) =>
    props.background ? props.background : "#1A2D56"};
  padding: 0 0 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ProcessWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8rem;
  position: relative;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
  }
`;

export const ProcessImg = styled.img`
  width: 70%;
  height: 100%;
  object-fit: contain;
`;

export const ProcessItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  position: relative;
  margin-bottom: 6rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const ProcessItemTitle = styled.div<{
  expand: boolean;
  color: string;
  colorPrimary: string;
}>`
  height: 4rem;
  width: 100%;
  min-width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding: 2rem;
  border-bottom: 3px solid ${(props) => props.color};
  margin-bottom: 1rem;
  font-weight: bold;
  color: #ffffff;
  font-family: "NanumSquare";
  background-color: ${(props) => props.colorPrimary};
  text-align: center;

  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.35s ease-out;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ProcessItemContent = styled.div<{
  expand: boolean;
  colorPrimary: string;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  margin: 0.5rem;
  color: #ffffff;
  height: ${(props) => (props.expand ? "3rem" : "0rem")};
  background-color: ${(props) => props.colorPrimary};
  transition: 0.35s ease-out;
  transition-delay: 0.35s;
  overflow: hidden;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    height: ${(props) => (props.expand ? "1.8rem" : "0rem")};
  }
`;

export const ReferenceArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 60rem;
  margin: 0 0 12rem;
  transition: 0.35s ease-out;
  position: relative;
  overflow: hidden;
  padding: 0 0 5rem;
`;

export const OpenReferenceWrapper = styled.div<{ bg: boolean }>`
  font-size: 1.6rem;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1.5rem;
  cursor: pointer;
  position: absolute;
  bottom: 15rem;
  left: 0;
  right: 0;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const YearlyItem = styled.div<{ align: string; expand: boolean }>`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align};
  justify-content: center;
  opacity: ${(props) => (props.expand ? "1" : "0")};
  transition: 0.75s ease-out;
  ${(props) =>
    props.align === "flex-start"
      ? props.expand
        ? "margin-right: auto"
        : "margin-right: 80rem"
      : props.expand
      ? "margin-left: auto"
      : "margin-left: 80rem"};

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const YearlyItemYear = styled.div<{ align: string }>`
  font-size: 2rem;
  color: #1a2d56;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  ${(props) =>
    props.align === "flex-start"
      ? "border-left: 3px solid #1A2D56"
      : "border-right: 3px solid #1A2D56"};

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const YearlyItemContentWrapper = styled.div<{
  align: string;
  color: string;
}>`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  padding: 1rem 1.5rem;
  width: 70%;
  margin: 0 auto;
  ${(props) =>
    props.align === "flex-start"
      ? `border-left: 3px solid ${props.color}`
      : `border-right: 3px solid ${props.color}`};
`;

export const YearlyItemContentDvCd = styled.div<{ color: string }>`
  font-size: 1.4rem;
  color: ${(props) => props.color};
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const YearlyItemContentMain = styled.div`
  font-size: 1.6rem;
  color: #000000;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const YearlyItemDot = styled.div<{ align: string; color: string }>`
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => props.color};
  border-radius: 5rem;
  position: absolute;
  top: 50%;
  left: ${(props) => (props.align === "flex-start" ? "-0.7rem" : "98%")};
`;

export const ReferenceDataCenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5%;
  flex-wrap: wrap;
  width: 70%;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
`;

export const ReferenceDataCenterItem = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5rem;
`;

export const ReferenceDataCenterItemTitle = styled.div<{ color: string }>`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const ReferenceDataCenterItemContent = styled.div<{ color?: string }>`
  font-size: 6rem;
  font-weight: bold;
  ${(props) => (props.color ? `color: ${props.color};` : null)}
`;

export const PartnersAreaClickSection = styled.div<{ expand: boolean }>`
  width: 70%;
  height: ${(props) => (props.expand ? "100rem" : "30rem")};
  margin: 2rem auto 20rem;
  display: flex;
  flex-dierction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  opacity: 1;
  margin-top: 2rem;
  transition: 0.7s ease-out;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    width: 90%;
    margin: 2rem auto;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 2rem auto 10rem;
    height: 35rem;
    gap: 0;
  }
`;

export const PartnersItem = styled.div`
  width: 23%;
  height: 30rem;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  @media screen and (max-width: 768px) {
    width: 25%;
    border: #efefef;
  }
`;

export const PartnersImg = styled.img`
  width: 70%;
  height: 70%;
  object-fit: contain;
`;

export const ReadMore = styled.div`
  font-size: 1.6rem;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  cursor: pointer;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const MoreViewTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const MoreViewItem = styled.div`
  height: 100%;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
`;

export const MoreViewImg = styled.img`
  width: 70%;
  height: 70%;
  object-fit: contain;
`;

export const MoreViewImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
