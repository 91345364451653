import React, { Dispatch, RefObject, SetStateAction } from 'react';
import * as s from './MainStyled';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "./carousel.css";
import ReferenceModal from '../../components/ReferenceModal';
import { url } from 'inspector';
import Jikguround from '../../assets/svg/jikguround.svg';
import SwDevelop from '../../assets/svg/swdevelop.svg';
import ItCommerce from '../../assets/svg/itcommerce.svg';
import Electronics from '../../assets/svg/electronics.svg';

interface MainProps {
  currentCarousel: number;
  setCurrentCarousel: Dispatch<SetStateAction<number>>;
  projects: any[];

  userInfo: any;
  userNm: string;
  setUserNm: Dispatch<SetStateAction<string>>;
  userEmail: string;
  setUserEmail: Dispatch<SetStateAction<string>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;

  currentBusinessFocus: number,
  setCurrentBusinessFocus: Dispatch<SetStateAction<number>>;

  registerQna: () => void;

  handleModal: (item: any) => void;

  scroll: any;
  referenceRef: RefObject<HTMLDivElement>;
  businessRef: RefObject<HTMLDivElement>;
  partnersRef: RefObject<HTMLDivElement>;
  openReference: boolean;
  openBusiness: boolean;
  openPartners: boolean;
  texts:{
    main_carouselItems_description1: string,
    main_carouselItems_description2: string,
    main_carouselItems_description3: string,
    main_carouselItems_description4: string,
    main_view_projects: string,
    main_view_Employee_Benefits: string,
    Physical_Security_Development: string,
    Development_of_Industrial_Safety_AI_System: string,
    IT_Infra: string,
    jikgu: string,

  }
}

const MainPresenter = (props: MainProps) => {

  const carouselItems = [
    {
      name: '',
      engName: 'Physical Security',
      engNameCapital: 'PHYSICAL SECURITY',
      description: `${props.texts.main_carouselItems_description1}`,
      imgSrc: '/static/img/main1.jpg',
      videoSrc: '/static/videos/main1.mp4'
    },
    {
      name: '',
      engName: 'Web Application',
      engNameCapital: 'WEB APPLICATION',
      description: `${props.texts.main_carouselItems_description2}`,
      imgSrc: '/static/img/main2.jpg',
      videoSrc: '/static/videos/main2.mp4'
    },
    {
      name: '',
      engName: 'Platform Operation',
      engNameCapital: 'PLATFORM OPERATION',
      description: `${props.texts.main_carouselItems_description3}`,
      imgSrc: '/static/img/main3.jpg',
      videoSrc: '/static/videos/main3.mp4'
    },
    {
      name: '',
      engName: 'Company Culture',
      engNameCapital: 'COMPANY CULTURE',
      description: `${props.texts.main_carouselItems_description4}`,
      imgSrc: '/static/img/main4.jpg',
      videoSrc: '/static/videos/main4.mp4'
    }
  ]
  
  const partnerLogos = [
    '/static/logo/partners/1.png',
    '/static/logo/partners/2.png',
    '/static/logo/partners/3.png',
    '/static/logo/partners/4.png',
    '/static/logo/partners/5.png',
    '/static/logo/partners/6.png',
    '/static/logo/partners/7.png',
    '/static/logo/partners/8.png',
    '/static/logo/partners/9.png',
    '/static/logo/partners/10.png',
    '/static/logo/partners/11.png',
    '/static/logo/partners/12.png',
    '/static/logo/partners/13.png',
    '/static/logo/partners/14.png',
    '/static/logo/partners/15.png',
    '/static/logo/partners/16.png',
    '/static/logo/partners/17.png',
    '/static/logo/partners/18.gif',
    '/static/logo/partners/19.png',
  
  ];

  return (
    <s.Container>
      <Carousel
        dynamicHeight={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        swipeable={true}
        stopOnHover={false}
        swipeScrollTolerance={15}
        emulateTouch={true}
        showStatus={false}
        showArrows={false}
        interval={7000}
        onChange={(e) => props.setCurrentCarousel(e)}
        labels={{ leftArrow: 'LEFT', rightArrow: 'RIGHT', item: 'item' }}
        renderIndicator={(clickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <s.CarouselIndicator onClick={clickHandler}>
                <s.CarouselActiveBottom color={props.currentCarousel === 0 ? '#6767EE' : props.currentCarousel === 1 ? '#38955F' : props.currentCarousel === 2 ? '#2895B6' : '#EE6767'} />
                <s.CarouselIndicatorTitle style={{ color: props.currentCarousel === 0 ? '#6767EE' : props.currentCarousel === 1 ? '#38955F' : props.currentCarousel === 2 ? '#2895B6' : '#EE6767' }}>{carouselItems[index].engName}</s.CarouselIndicatorTitle>
                {/* <s.CarouselIndicatorSubtitle>{carouselItems[index].engName}</s.CarouselIndicatorSubtitle> */}
              </s.CarouselIndicator>
            )
          } else {
            return (
              <s.CarouselIndicator onClick={clickHandler}>
                <s.CarouselIndicatorTitle>{carouselItems[index].engName}</s.CarouselIndicatorTitle>
                {/* <s.CarouselIndicatorSubtitle>{carouselItems[index].engName}</s.CarouselIndicatorSubtitle> */}
              </s.CarouselIndicator>
            )
          }
        }}>
        <s.TopImageCarouselWrapper>
          {/* <s.CarouselImg src="static/gif/main1.gif"/> */}
          <s.CarouselImg src={carouselItems[0].imgSrc} />
          <s.CarouselImgWrapper />
          {
            props.currentCarousel === 0 ?
              <s.CarouselTextWrapper>
                <s.CarouselTitleText style={{ color: 'rgba(255, 255, 255, 0.8)', backgroundImage: "url('static/gif/main1.gif') no-repeat 0 0", backgroundClip: 'text', WebkitBackgroundClip: 'text' }}>{carouselItems[props.currentCarousel].engNameCapital}</s.CarouselTitleText>
                {
                  carouselItems[props.currentCarousel].description.split('\n').map((item) => (
                    <s.CarouselSubtitleBox>{item}</s.CarouselSubtitleBox>
                  ))
                }
                <s.CarouselGotoButton to="/business/1" color={'#6767EE'}>{props.texts.main_view_projects} {'►'}</s.CarouselGotoButton>
              </s.CarouselTextWrapper>
              : null
          }
        </s.TopImageCarouselWrapper>
        <s.TopImageCarouselWrapper>
          {/* <s.CarouselImg src="static/gif/main2.gif"/> */}
          <s.CarouselImg src={carouselItems[1].imgSrc} />
          <s.CarouselImgWrapper />
          {
            props.currentCarousel === 1 ?
              <s.CarouselTextWrapper>
                <s.CarouselTitleText style={{ color: 'rgba(255, 255, 255, 0.8)', backgroundImage: "url('static/gif/main2.gif') no-repeat 0 0", backgroundClip: 'text', WebkitBackgroundClip: 'text' }}>{carouselItems[props.currentCarousel].engNameCapital}</s.CarouselTitleText>
                {
                  carouselItems[props.currentCarousel].description.split('\n').map((item) => (
                    <s.CarouselSubtitleBox>{item}</s.CarouselSubtitleBox>
                  ))
                }
                <s.CarouselGotoButton to="/business/1" color={'#38955F'}>{props.texts.main_view_projects} {'►'}</s.CarouselGotoButton>
              </s.CarouselTextWrapper>
              : null
          }
        </s.TopImageCarouselWrapper>
        <s.TopImageCarouselWrapper>
          {/* <s.CarouselImg src="static/gif/main3.gif"/> */}
          <s.CarouselImg src={carouselItems[2].imgSrc} />
          <s.CarouselImgWrapper />
          {
            props.currentCarousel === 2 ?
              <s.CarouselTextWrapper>
                <s.CarouselTitleText style={{ color: 'rgba(255, 255, 255, 0.8)', backgroundImage: "url('static/gif/main3.gif') no-repeat 0 0", backgroundClip: 'text', WebkitBackgroundClip: 'text' }}>{carouselItems[props.currentCarousel].engNameCapital}</s.CarouselTitleText>
                {
                  carouselItems[props.currentCarousel].description.split('\n').map((item) => (
                    <s.CarouselSubtitleBox>{item}</s.CarouselSubtitleBox>
                  ))
                }
              </s.CarouselTextWrapper>
              : null
          }
        </s.TopImageCarouselWrapper>
        <s.TopImageCarouselWrapper>
          {/* <s.CarouselImg src="https://picsum.photos/id/671/1920/1080"/> */}
          <s.CarouselImg src={carouselItems[3].imgSrc} />
          <s.CarouselImgWrapper />
          {
            props.currentCarousel === 3 ?
              <s.CarouselTextWrapper>
                <s.CarouselTitleText style={{ color: 'rgba(255, 255, 255, 0.8)', backgroundImage: "url('https://picsum.photos/id/671/1920/1080') no-repeat 0 0", backgroundClip: 'text', WebkitBackgroundClip: 'text' }}>{carouselItems[props.currentCarousel].engNameCapital}</s.CarouselTitleText>
                {
                  carouselItems[props.currentCarousel].description.split('\n').map((item) => (
                    <s.CarouselSubtitleBox>{item}</s.CarouselSubtitleBox>
                  ))
                }
                <s.CarouselGotoButton to="/info" color={'#EE6767'}>{props.texts.main_view_Employee_Benefits} {'►'}</s.CarouselGotoButton>
              </s.CarouselTextWrapper>
              : null
          }
        </s.TopImageCarouselWrapper>
      </Carousel>

      {/* <s.ReferenceArea ref={props.referenceRef}>
          <s.ReferenceAreaTitle>REFERENCE</s.ReferenceAreaTitle>
          <s.ReferenceAreaItemSection>
            <s.ReferenceAreaItemSectionBlock expand={props.openReference}/>
            {
              props.projects.map((item: any, index: number) => {
                return (
                  <s.ReferenceAreaItem expand={props.openReference} onClick={() => {props.handleModal(item)}} color={index%6 === 0 ? '#F0512F' : index%6 === 1 ? '#F0D32F' : index%6 === 2 ? '#9ADA1B' : index%6 === 3 ? '#1BDA9A' : index%6 === 4 ? '#1B8CDA' : index%6 === 5 ? '#3A1BDA' : '#BA1BDA'}>
                    <s.ReferenceAreaImg src={item.thumbnail}/>
                    <s.ReferenceAreaWrapper >
                      <s.ReferenceItemTitle>{item.title}</s.ReferenceItemTitle>
                      <s.ReferenceItemContent>{item.content}</s.ReferenceItemContent>
                    </s.ReferenceAreaWrapper>
                  </s.ReferenceAreaItem>
                )
              })
            }
          </s.ReferenceAreaItemSection>
          <s.ReferenceMoreArea to="/project">MORE</s.ReferenceMoreArea>
        </s.ReferenceArea> */}


      <s.BusinessArea ref={props.businessRef}>
        <s.BusinessAreaTitle>DOUBLT BUSINESS</s.BusinessAreaTitle>
        <s.BusinessAreaClickSection expand={props.openBusiness}>

          <s.BusinessAreaButton color={'#1A2D56'} to="/business/0" onMouseEnter={() => props.setCurrentBusinessFocus(1)} onMouseLeave={() => props.setCurrentBusinessFocus(-1)}>
            <s.BusinessAreaButtonImg src={SwDevelop} />
            {/* <SwDevelop/> */}
            <s.BusinessAreaButtonText focused={props.currentBusinessFocus === 1}>{props.texts.Physical_Security_Development}</s.BusinessAreaButtonText>
          </s.BusinessAreaButton>
          <s.BusinessAreaButton color={'#0089AA'} to="/business/1" onMouseEnter={() => props.setCurrentBusinessFocus(3)} onMouseLeave={() => props.setCurrentBusinessFocus(-1)}>
            <s.BusinessAreaButtonImg src={Electronics} />
            {/* <Electronics/> */}
            <s.BusinessAreaButtonText focused={props.currentBusinessFocus === 3}>{props.texts.Development_of_Industrial_Safety_AI_System}</s.BusinessAreaButtonText>
          </s.BusinessAreaButton>
          <s.BusinessAreaButton color={'#0C7B93'} to="/business/2" onMouseEnter={() => props.setCurrentBusinessFocus(2)} onMouseLeave={() => props.setCurrentBusinessFocus(-1)}>
            <s.BusinessAreaButtonImg src={ItCommerce} />
            {/* <ItCommerce/> */}
            <s.BusinessAreaButtonText focused={props.currentBusinessFocus === 2}>{props.texts.IT_Infra}</s.BusinessAreaButtonText>
          </s.BusinessAreaButton>
          <s.BusinessAreaButton color={'#39855F'} to="/business/3" onMouseEnter={() => props.setCurrentBusinessFocus(0)} onMouseLeave={() => props.setCurrentBusinessFocus(-1)}>
            <s.BusinessAreaButtonImg src={Jikguround} />
            {/* <Jikguround/> */}
            <s.BusinessAreaButtonText focused={props.currentBusinessFocus === 0}>{props.texts.jikgu}</s.BusinessAreaButtonText>
          </s.BusinessAreaButton>
        </s.BusinessAreaClickSection>
      </s.BusinessArea>


      <s.PartnersArea ref={props.partnersRef}>
        <s.PartnersAreaTitle>DOUBLT PARTNERS</s.PartnersAreaTitle>
        <s.PartnersAreaClickSection expand={props.openPartners}>
          {
            partnerLogos.map((item: any, index: number) => {
              return (
                <s.PartnersItem>
                  <s.PartnersImg src={item} style={{ width: index === 1 ? '55%' : '50%' }} />
                </s.PartnersItem>
              )
            })
          }
        </s.PartnersAreaClickSection>
      </s.PartnersArea>


      {/* <s.AskLetterArea>
          <s.AskLetterAreaTitle>문의등록</s.AskLetterAreaTitle>
          <s.AskLetterWrapper>
            <s.AskLetterImg src="https://picsum.photos/200/400"/>
            <s.AskLetterFormArea>
              <s.AskLetterRow>
                <s.AskLetterShort>
                  <s.AskLetterLabel>이름 *</s.AskLetterLabel>
                  {
                    props.userInfo.userNm ?
                    <s.AskLetterInput type="text" placeholder='성함을 입력해주세요.' value={props.userInfo.userNm} readOnly style={{backgroundColor: '#ABABAB'}}/>
                    :
                    <s.AskLetterInput type="text" placeholder='성함을 입력해주세요.' value={props.userNm} onChange={(e) => {props.setUserNm(e.target.value)}}/>
                  }
                  
                </s.AskLetterShort>
                <s.AskLetterShort>
                  <s.AskLetterLabel>이메일 *</s.AskLetterLabel>
                  {
                    props.userInfo.userEmail ?
                    <s.AskLetterInput type="text" placeholder='이메일을 입력해주세요.' value={props.userInfo.userEmail} readOnly style={{backgroundColor: '#ABABAB'}}/>
                    :
                    <s.AskLetterInput type="text" placeholder='이메일을 입력해주세요.' value={props.userEmail} onChange={(e) => {props.setUserEmail(e.target.value)}}/>
                  }
                </s.AskLetterShort>
              </s.AskLetterRow>
              <s.AskLetterRow>
                <s.AskLetterLong>
                  <s.AskLetterLabel>내용 *</s.AskLetterLabel>
                  <s.AskLetterTextArea placeholder='문의내용을 입력해주세요. 입력하신 메일로 회신을 드립니다.' value={props.content} onChange={(e) => {props.setContent(e.target.value)}}/>
                </s.AskLetterLong>
              </s.AskLetterRow>

              <s.AskLetterSendButton onClick={props.registerQna}>보내기</s.AskLetterSendButton>
            </s.AskLetterFormArea>
          </s.AskLetterWrapper>
        </s.AskLetterArea> */}
    </s.Container>
  );
};

export default MainPresenter;
