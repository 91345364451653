import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProjectDetailStyled';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import * as util from '../../../../util/util';
import { MdClose } from 'react-icons/md';

interface ProjectDetailProps {
  thumbnail: any;
  setThumbnail: Dispatch<SetStateAction<any>>;
  categoryId: number;
  setCategoryId: Dispatch<SetStateAction<number>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  refFiles: any[];
  setRefFiles: Dispatch<SetStateAction<any[]>>;
  thumbnailPreview: string;
  origFileList: any[];
  deleteOrigFile: (imageId: any) => void;

  submit: () => void;
  handleModal: (index: number, dvCd: string) => void;
}

const ProjectDetailPresenter = (props: ProjectDetailProps) => {

  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      props.setRefFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/1076/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>PROJECT UPDATE</s.TopTitle>
          <s.TopSubtitle>관리자페이지입니다.</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>

      <s.CreateProjectArea>
        <s.CreateProjectTable>
          <colgroup>
            <col width={'15%'}/>
            <col width={'35%'}/>
            <col width={'15%'}/>
            <col width={'35%'}/>
          </colgroup>
          <s.CreateProjectTbody>
            <s.CreateProjectTr>
              <s.CreateProjectTh rowSpan={3}>썸네일</s.CreateProjectTh>
              <s.CreateProjectTd rowSpan={3}>
                <s.CreateProjectThumbnail src={props.thumbnailPreview}/>
                <s.CreateProjectInput type="file" style={{border: 0}} onChange={(e) => {e.target.files && props.setThumbnail(e.target.files[0])}} placeholder="썸네일 파일을 업로드하세요." accept={'.jpg,.jpeg,.png,.gif'}/>
              </s.CreateProjectTd>
              <s.CreateProjectTh>프로젝트 구분</s.CreateProjectTh>
              <s.CreateProjectTd>
                <s.CreateProjectSelect value={props.categoryId} onChange={(e) => {props.setCategoryId(Number(e.target.value))}}>
                  <option value="1">OFFICE</option>
                  <option value="2">HOSPITAL</option>
                  <option value="3">FRANCHISE</option>
                  <option value="4">OTHERS</option>
                </s.CreateProjectSelect>
              </s.CreateProjectTd>
            </s.CreateProjectTr>
            <s.CreateProjectTr>
              <s.CreateProjectTh>프로젝트 명</s.CreateProjectTh>
              <s.CreateProjectTd>
                <s.CreateProjectInput type="text" placeholder='프로젝트 명을 입력하세요' value={props.title} onChange={(e) => {props.setTitle(e.target.value)}}/>
              </s.CreateProjectTd>
            </s.CreateProjectTr>
            <s.CreateProjectTr>
              <s.CreateProjectTh>프로젝트 상세설명</s.CreateProjectTh>
              <s.CreateProjectTd>
                <s.CreateProjectTextArea placeholder='상세설명을 입력하세요.' value={props.content} onChange={(e) => {props.setContent(e.target.value)}}/>
              </s.CreateProjectTd>
            </s.CreateProjectTr>
            <s.CreateProjectTr>
              <s.CreateProjectTh>등록된 이미지</s.CreateProjectTh>
              <s.CreateProjectTd colSpan={3}>
              {
                props.origFileList.map((file: any, index: number) => {
                  if (file.useYn === 'Y') {
                    return (
                      <s.RefFileItems>
                        <s.FileName onClick={() => {
                          props.handleModal(index, 'O');
                        }}>
                          {file.image_path}
                        </s.FileName>
                        <s.DeleteItem onClick={() => props.deleteOrigFile(file.image_id)}><MdClose size={'100%'} color={'#FF0000'}/></s.DeleteItem>
                      </s.RefFileItems>
                    )
                  }
                })
              }
              </s.CreateProjectTd>
            </s.CreateProjectTr>
            <s.CreateProjectTr>
              <s.CreateProjectTh>프로젝트 이미지</s.CreateProjectTh>
              <s.CreateProjectTd colSpan={3}>
              <s.RefFileDropZone {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <s.RefFileExplainPTag>업로드 할 이미지를 끌어넣으세요.</s.RefFileExplainPTag>
              </s.RefFileDropZone>
              {
                props.refFiles.map((file: any, index: number) => (
                  <s.RefFileItems onClick={() => {
                    props.handleModal(index, 'R');
                  }}>
                    {file.path} - {util.priceToString(file.size)} bytes
                    
                  </s.RefFileItems>
                ))
              }
              </s.CreateProjectTd>
            </s.CreateProjectTr>
          </s.CreateProjectTbody>

        </s.CreateProjectTable>
      </s.CreateProjectArea>
      <s.ButtonWrapper>
        <s.SubmitButton onClick={props.submit}>등록</s.SubmitButton>
        <s.ToListButton to="/admin/project">목록</s.ToListButton>
      </s.ButtonWrapper>
    </s.Container>
  );
};

export default ProjectDetailPresenter;
