import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProjectStyled';
import { Link } from 'react-router-dom';
import ReferenceModal from '../../components/ReferenceModal';

interface ProjectProps {
  category: number;
  setCategory: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  projects: any[];

  userInfo: any;
  userNm: string;
  setUserNm: Dispatch<SetStateAction<string>>;
  userEmail: string;
  setUserEmail: Dispatch<SetStateAction<string>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;

  registerQna: () => void;

  handleModal: (item: any) => void;

  texts: {
    project_topSubtitle: string,
    project_noRowToShow: string,
    project_askLetter: string,
    project_name: string,
    project_input_name: string,
    project_email: string,
    project_input_email: string,
    project_content: string,
    project_input_content: string,
    project_send: string,
  }
}

const ProjectPresenter = (props: ProjectProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/378/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>DOUBLT PROJECTS</s.TopTitle>
          <s.TopSubtitle>{props.texts.project_topSubtitle}</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>
      <s.FilterArea>
        <s.FilterItem active={props.category < 0} onClick={() => {props.setCategory(-1);}}>ALL</s.FilterItem>
        <s.FilterItem active={props.category === 1} onClick={() => {props.setCategory(1);}}>OFFICE</s.FilterItem>
        <s.FilterItem active={props.category === 2} onClick={() => {props.setCategory(2);}}>HOSPITAL</s.FilterItem>
        <s.FilterItem active={props.category === 3} onClick={() => {props.setCategory(3);}}>FRANCHISE</s.FilterItem>
        <s.FilterItem active={props.category === 4} onClick={() => {props.setCategory(4);}}>OTHERS</s.FilterItem>
      </s.FilterArea>
      <s.ItemArea>
      {
        props.projects.length > 0 ? 
        <>
        {
          props.projects.map((item: any, index: number) => {
            return (
              <s.Item onClick={() => {props.handleModal(item)}} color={index%6 === 0 ? '#F0512F' : index%6 === 1 ? '#F0D32F' : index%6 === 2 ? '#9ADA1B' : index%6 === 3 ? '#1BDA9A' : index%6 === 4 ? '#1B8CDA' : index%6 === 5 ? '#3A1BDA' : '#BA1BDA'}>
                <s.ItemImg src={item.thumbnail}/>
                <s.ItemCover>
                  <s.ItemCoverTitle>{item.title}</s.ItemCoverTitle>
                  <s.ItemCoverContent>{item.content}</s.ItemCoverContent>
                </s.ItemCover>
              </s.Item>
            )
          })
        }
        <s.ItemMoreWrapper>
          <s.ItemMore onClick={() => {props.setPage(props.page + 1);}}>MORE</s.ItemMore>
        </s.ItemMoreWrapper>
        </>
        : <s.NoRowsToShow>{props.texts.project_noRowToShow}</s.NoRowsToShow>
      }
        
      </s.ItemArea>

      <s.AskLetterArea>
          <s.AskLetterAreaTitle>{props.texts.project_askLetter}</s.AskLetterAreaTitle>
          <s.AskLetterWrapper>
            <s.AskLetterImg src="https://picsum.photos/200/400"/>
            <s.AskLetterFormArea>
              <s.AskLetterRow>
                <s.AskLetterShort>
                  <s.AskLetterLabel>{props.texts.project_name} *</s.AskLetterLabel>
                  {
                    props.userInfo.userNm ?
                    <s.AskLetterInput type="text" placeholder={props.texts.project_input_name} value={props.userInfo.userNm} readOnly style={{backgroundColor: '#ABABAB'}}/>
                    :
                    <s.AskLetterInput type="text" placeholder={props.texts.project_input_name} value={props.userNm} onChange={(e) => {props.setUserNm(e.target.value)}}/>
                  }
                  
                </s.AskLetterShort>
                <s.AskLetterShort>
                  <s.AskLetterLabel>{props.texts.project_email} *</s.AskLetterLabel>
                  {
                    props.userInfo.userEmail ?
                    <s.AskLetterInput type="text" placeholder={props.texts.project_input_email} value={props.userInfo.userEmail} readOnly style={{backgroundColor: '#ABABAB'}}/>
                    :
                    <s.AskLetterInput type="text" placeholder={props.texts.project_input_email}  value={props.userEmail} onChange={(e) => {props.setUserEmail(e.target.value)}}/>
                  }
                </s.AskLetterShort>
              </s.AskLetterRow>
              <s.AskLetterRow>
                <s.AskLetterLong>
                  <s.AskLetterLabel>{props.texts.project_content} *</s.AskLetterLabel>
                  <s.AskLetterTextArea placeholder={props.texts.project_input_content} value={props.content} onChange={(e) => {props.setContent(e.target.value)}}/>
                </s.AskLetterLong>
              </s.AskLetterRow>

              <s.AskLetterSendButton onClick={props.registerQna}>{props.texts.project_send}</s.AskLetterSendButton>
            </s.AskLetterFormArea>
          </s.AskLetterWrapper>
        </s.AskLetterArea>
    </s.Container>
  );
};

export default ProjectPresenter;
