import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  padding-top: 15rem;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
`;


export const TopArea = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: transparent;
`;

export const TopWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 0 12%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0), 10%, rgba(0, 0, 0, 0.5),  85%, rgba(255, 255, 255, 1));

  @media screen and (max-width: 768px) {
    height: 84rem;
    padding: 0 10%;
  }
`;

export const TopTitle = styled.div`
  margin-top: 1rem; 
  font-size: 5rem;
  color: #FFFFFF;
  font-weight: bold;
  text-align: left;
  line-height: 9rem;
  border-left: 1rem solid #FFFFFF;
  padding-left: 2rem;
  

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    line-height: auto;
    text-align: center;
  }
`;


export const BodyArea = styled.div`
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const RecruitSection = styled.div`
  width: 70%;
  min-height: 60rem;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 25rem 0;
  position: relative;
`;

export const RecruitTitle = styled.div`
  font-size: 4rem;
  color: #000000;
  font-weight: bold;
  text-align: left;
  padding: 1rem 3rem 1rem 1rem;
  width: 60rem;
  border-bottom: 0.5rem solid #1A2D53;
`;

export const RecruitTable = styled.table`
  margin: 4rem 0 0;
  width: 100%;
  border-spacing: 0px;
`;

export const RecruitTbody = styled.tbody`
  &:hover {
    padding: 4rem;
  }
`;

export const RecruitTr = styled.tr`
  
  transition: 0.35s ease-out;

  &:hover {
    background-color: #EFEFEF;
    padding: 4rem;
  }
`;

export const RecruitTh = styled.th`
  border-bottom: 1px solid #000000;
  padding: 1rem 1.5rem;
  font-size: 2rem;
  font-weight: bold;
`;

export const RecruitTd = styled.td`
  padding: 2rem 1.5rem;
`;

export const RecruitDetailTd = styled.td<{isExpand: boolean}>`
  height: ${props => props.isExpand ? '45rem' : '0'};
  background-color: #EFEFEF;
  transition: 0.35s ease-out;
  
`;

export const RecruitGotoLink = styled.a`
  position: absolute;
  right: 3rem;
  bottom: 20rem;
  text-decoration: none;
  font-size: 1.5rem;
  color: #000000;
  font-weight: bold;
  overflow: hidden;
  width: 17rem;
  transition: 0.35s ease-out;
  padding-bottom: 1rem;

  &:hover {
    padding-left: 3rem;
    border-bottom: 2px solid #000000;
    padding-bottom: 0.8rem;
  }
`;

export const LifestyleSection = styled.div`
  width: 70%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  padding: 20rem 0;
  position: relative;
  
`;

export const LifestyleLeft = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
`;

export const LifestyleTitle = styled.div`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: left;
`;

export const LifestyleSubtitle = styled.div`
  font-size: 2rem;
  color: #7D7D7D;
  text-align: left;
  line-height: 2.8rem;
  margin-bottom: 3rem;
`;

export const LifestyleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2%;
  flex-wrap: wrap;
  margin: 4rem 0;
`;

export const LifestyleItem = styled.div`
  width: 47%;
  height: 40rem;
  background-color: #ABDBBF;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  margin-bottom: 3rem;
  overflow: hidden;
  position: relative;
`;

export const LifestyleImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LifestyleItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  padding: 5rem;
  flex-direction: column;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.5);
`;

export const LifestyleItemTitle = styled.div`
  font-size: 2.8rem;
  font-weight: bold;
  text-align: left;
  color: #2D2D2D;
`;

export const LifestyleItemContent = styled.div`
  font-size: 1.6rem;
  color: 4D4D4D;
  text-align: left;
  margin-top: 3rem;
  line-height: 2.7rem;
`;

export const LifestyleRight = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding: 20rem 0;
`;

export const LifestyleRightItem = styled.div<{color: string}>`
  font-size: 1.4rem;
  font-weight: bold;
  // background-color: ${props => props.color};
  padding: 1rem 0;
  border-radius: 1rem;
  color: 5D5D5D;
  text-align: left;
`;

export const RecruitDetailTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  padding: 1rem;
  color: #4D4D4D;
`;

export const RecruitDetailSubtitle = styled.div`
  font-size: 1.6rem;
  display: flex;
  padding: 1rem;
  color: #5D5D5D;
`;

export const RecruitDetailContent = styled.div`
  font-size: 1.4rem;
  display: flex;
  padding: 0.5rem 1rem;
  color: #9D9D9D;
`;