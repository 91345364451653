import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 12rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    min-height: 10rem;
  }
`;

export const LogoArea = styled.div`
  width: 12rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 10.8rem;
    height: 5.5rem;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    left: 0.5rem;
    width: auto;
    height: 5rem;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;

export const TextArea = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1rem;
`;

export const BottomTextBold = styled.div`
  font-size: 1.6rem;
  color: #FFFFFF;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  margin: 5px 0;

  @media screen and (max-width: 1200px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const BottomTextRegular = styled.div`
  font-size: 1.2rem;
  color: #FFFFFF;
  font-family: 'Noto Sans KR';
  margin: 0.3rem 0;

  @media screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const LocaleButton = styled.button`
  width: 130px;
  font-size: 1.2rem;
  color: #686868;
  border: none;
  border-radius: 5px;
  font-family: 'Noto Sans KR';
  padding: 1rem 1.2rem;
  margin: 0 1rem;

  &:hover{
    background-color: #0089aa;
    color: #FFFFFF;
  }
`