import React, { Dispatch, SetStateAction } from 'react';
import * as s from './LoginStyled';
import { Link } from 'react-router-dom';

interface LoginProps {
  loginId: string;
  setLoginId: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;

  checkEnter: (event: any) => void;
  login: () => void;
  texts: {
    login: string,
    login_text1: string,
    login_text2: string,
    id: string,
    login_inputId: string,
    password: string,
    login_inputPw: string,
  }
}

const LoginPresenter = (props: LoginProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>{props.texts.login}</s.TopTitle>
          <s.TopSubtitle>{props.texts.login_text1}</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>

      <s.LoginArea>
        <s.LoginTitle>{props.texts.login_text2}</s.LoginTitle>
        <s.LoginBox>
          <s.LoginRow>
            <s.LoginLabel>{props.texts.id}</s.LoginLabel>
            <s.LoginInput type='text' placeholder={props.texts.login_inputId} value={props.loginId} onChange={(e) => props.setLoginId(e.target.value)} onKeyDown={(e) => props.checkEnter(e)}/>
          </s.LoginRow>
          <s.LoginRow>
            <s.LoginLabel>{props.texts.password}</s.LoginLabel>
            <s.LoginInput type='password'placeholder={props.texts.login_inputPw}  value={props.password} onChange={(e) => props.setPassword(e.target.value)} onKeyDown={(e) => props.checkEnter(e)}/>
          </s.LoginRow>
        </s.LoginBox>
        <s.LoginButton onClick={props.login}>{props.texts.login}</s.LoginButton>
      </s.LoginArea>
    </s.Container>
  );
};

export default LoginPresenter;
