import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import NewsListPresenter from './NewsListPresenter';
import * as recoilItem from '../../../../util/recoilitem';
import * as util from '../../../../util/util';
import { newsApi } from '../../../../api/api-news';
import { useNavigate } from 'react-router-dom';

const NewsListContainer = () => {
  const [page, setPage] = useState<number>(1);
  const [rowData, setRowData] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page])

  const fetchData = async () => {
    setIsLoading(true);

    let params = {
      page: String(page - 1)
    };

    let res = await newsApi.getNewsList(params);
    if (res.data?.data.rsltCd === '00') {
      setIsLoading(false);
      setRowData(res.data?.data.list || []);
      setRowCount(res.data?.data.totalCount || 0);
    } else {
      util.makeMsg('마지막 페이지입니다.', 'success');
      setIsLoading(false);
    }
  }

  const handlePageChange = async (page: number) => {
    setPage(page);
  }

  const toItemDetail = (index: any) => {
    navigate(`/admin/news/${index}`)
  }

  return (
    <>
      <NewsListPresenter
        toItemDetail={toItemDetail}
        page={page}
        handlePageChange={handlePageChange}
        rowCount={rowCount}
        rowData={rowData}
      />
    </>
  );
};

NewsListContainer.defaultProps = {};

export default NewsListContainer;
