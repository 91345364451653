import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSetRecoilState, useRecoilState } from "recoil";

import BusinessPresenter from "./BusinessPresenter";
import * as recoilItems from "../../util/recoilitem";
import korean from '../../util/korean.json';
import english from '../../util/english.json';

const BusinessContainer = () => {
  const params = useParams();
  const location = useLocation();
  const [category, setCategory] = useState<number>(0);
  const [hovered, setHovered] = useState<number>(-1);
  const [isExpandReference, setIsExpandReference] = useState<boolean>(false);
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const setIsLoading = useSetRecoilState(recoilItems.isLoading);
  const [language, setLanguage] = useRecoilState(recoilItems.locale);
  const [texts, setTexts] = useState({
    Physical_Security_Development: '',
    business_physical_mainDesc: '',
    business_physical_title1: '',
    business_physical_title2: '',
    business_physical_title3: '',
    business_physical_title4: '',
    business_physical_desc1: '',
    business_physical_desc2: '',
    business_physical_desc3: '',
    business_physical_desc4: '', 
    business_physical_processSectionName: '',
    business_physical_partnerSectionName: '',
    business_physical_process_Request_content1: '',
    business_physical_process_Request_content2: '',
    business_physical_process_Contraction_content1: '',
    business_physical_process_Design_content1: '',
    business_physical_process_Licensing_content1: '',
    business_physical_process_Licensing_content2: '',
    business_physical_process_Licensing_content3: '',
    business_physical_process_Licensing_content4: '',
    business_physical_process_Total_Solution_content1: '',
    business_physical_referenceAreaTitle: '',
    business_physical_refereceTitle1: '',
    business_physical_referenceContent1: '',
    business_physical_referenceAreaTitle2: '',
    business_physical_referenceContent2: '',
    business_physical_referenceAreaTitle3: '',
    business_physical_referenceContent3: '',
    business_physical_referenceAreaTitle4: '',
    business_physical_referenceContent4: '',
    business_etcText: '',
    Development_of_Industrial_Safety_AI_System: '',
    business_AI_mainDesc: '',
    business_AI_title1: '',
    business_AI_desc1: '',
    business_AI_title2: '',
    business_AI_desc2: '',
    business_AI_Interview: '',
    business_AI_Plan: '',
    business_AI_process_Plan: '',
    business_AI_process_Design: '',
    business_AI_process_Execution: '',
    business_AI_process_Validation: '',
    IT_Infra: '',
    business_IT_Infra_mainDesc: '',
    business_IT_Infra_title1: '',
    business_IT_Infra_desc1: '',
    business_IT_Infra_title2: '',
    business_IT_Infra_desc2: '',
    business_IT_Infra_processSectionName: '',
    business_IT_Infra_prcess_Interview_content1: '',
    business_IT_Infra_prcess_Interview_content2: '',
    business_IT_Infra_prcess_Plan_content1: '',
    business_IT_Infra_prcess_Plan_content2: '',
    business_IT_Infra_prcess_Plan_content3: '',
    business_IT_Infra_prcess_Contraction_content1: '',
    business_IT_Infra_prcess_Execution_content1: '',
    jikgu: '',
    business_jikgu_mainDesc: '',
    business_jikgu_shortcut: '',
    business_jikgu_title1: '',
    business_jikgu_desc1: '',
    business_jikgu_title2: '',
    business_jikgu_desc2: '',
    business_jikgu_title3: '',
    business_jikgu_desc3: '',
    business_jikgu_title4: '',
    business_jikgu_desc4: '',
    business_jikgu_processSectionName: '',
    business_jikgu_process_Interview_content1: '',
    business_jikgu_process_Interview_content2: '',
    business_jikgu_process_Plan_content1: '',
    business_jikgu_process_Plan_content2: '',
    business_jikgu_process_Plan_content3: '',
    business_jikgu_process_Design_content1: '',
    business_jikgu_process_Design_content2: '',
    business_jikgu_process_Design_content3: '',
    business_jikgu_process_Design_content4: '',
    business_jikgu_process_Execution_content1: '',
    business_jikgu_process_Execution_content2: '',
    business_jikgu_process_Execution_content3: '',
    business_jikgu_process_Execution_content4: '',
    business_jikgu_process_After_Service_content1: '',
    business_jikgu_referenceAreaTitle: '',
    business_jikgu_refereceTitle1: '',
    business_jikgu_referenceContent1: '',
    business_jikgu_refereceTitle2: '',
    business_jikgu_referenceContent2: '',
    business_jikgu_refereceTitle3: '',
    business_jikgu_referenceContent3: '',
    business_jikgu_refereceTitle4: '',
    business_jikgu_referenceContent4: '',
    business_field: '',
    View_Route_Management_Use_Cases: '',
    Close_Route_Management_Use_Cases: '',
    show_more: '',
    show_less: '',
    Physical_Security: '',
    business_text1: '',
    business_text2: '',
    business_text3: '',
    business_text4: '',
    business_text5: '',
    business_text6: '',
    business_text7: '',
    business_text8: '',
    business_text9: '',
    business_text10: '',
    business_text11: '',
    business_text12: '',
    business_text13: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  const items = [
    {
      color:
        "linear-gradient(0.25turn, rgba(26, 46, 86, 1), 20%, rgba(26, 46, 86, 0.85), 80%, rgba(26, 46, 86, 1))",
      colorHex: "#1A2D56",
      colorSecondaryHex: "#4D5E89",
      titleKor: `${texts.Physical_Security_Development}`,
      titleEng: "Physical Security",
      titleEngCap: "PHYSICAL SECURITY",
      background: "https://picsum.photos/id/1048/1000/1000",
      mainDescription:
        `${texts.business_physical_mainDesc}`,

      businessArea: [
        {
          titleKor: `${texts.business_physical_title1}`,
          description:
           `${texts.business_physical_desc1}`,
        },
        {
          titleKor: `${texts.business_physical_title2}`,
          description:
            `${texts.business_physical_desc2}`,
        },
        {
          titleKor: `${texts.business_physical_title3}`,
          description:
            `${texts.business_physical_desc3}`,
        },
        {
          titleKor: `${texts.business_physical_title4}`,
          description:
            `${texts.business_physical_desc4}`,
        },
      ],
      processSectionName: `${texts.business_physical_processSectionName}`,
      partnerSectionName: `${texts.business_physical_partnerSectionName}`,
      process: [
        {
          title: "Request",
          content: [
            `${texts.business_physical_process_Request_content1}`, 
            `${texts.business_physical_process_Request_content2}`
          ],
        },
        {
          title: "Contraction",
          content: [`${texts.business_physical_process_Contraction_content1}`],
        },
        {
          title: "Design",
          content: [`${texts.business_physical_process_Design_content1}`],
        },
        {
          title: "Licensing",
          content: [
            `${texts.business_physical_process_Licensing_content1}`, 
            `${texts.business_physical_process_Licensing_content2}`, 
            `${texts.business_physical_process_Licensing_content3}`, 
            `${texts.business_physical_process_Licensing_content4}`
          ],
        },
        {
          title: "Total Solution",
          content: [`${texts.business_physical_process_Total_Solution_content1}`],
        },
      ],
      referenceAreaTitle: `${texts.business_physical_referenceAreaTitle}`,
      references: [
        {
          refereceTitle: `${texts.business_physical_refereceTitle1}`,
          referenceContent: `${texts.business_physical_referenceContent1}`,
        },
        {
          refereceTitle: `${texts.business_physical_referenceAreaTitle2}`,
          referenceContent: `${texts.business_physical_referenceContent2}`,
        },
        {
          refereceTitle: `${texts.business_physical_referenceAreaTitle3}`,
          referenceContent: `${texts.business_physical_referenceContent3}`,
        },
        {
          refereceTitle: `${texts.business_physical_referenceAreaTitle4}`,
          referenceContent: `${texts.business_physical_referenceContent4}`,
        },
      ],
      etcText: `${texts.business_etcText}`,
      partnersImg: [
        "/static/logo/partners/sw1.png",
        "/static/logo/partners/sw2.png",
        "/static/logo/partners/sw3.png",
        "/static/logo/partners/sw4.png",
        "/static/logo/partners/sw5.png",
        "/static/logo/partners/sw6.png",
        "/static/logo/partners/sw7.png",
        "/static/logo/partners/sw8.png",
        "/static/logo/partners/sw9.png",
        "/static/logo/partners/sw10.png",
        "/static/logo/partners/sw11.png",
        "/static/logo/partners/sw12.png",
      ],
    },
    {
      color:
        "linear-gradient(0.25turn, rgba(0, 137, 170, 1), 20%, rgba(0, 137, 170, 0.85), 80%, rgba(0, 137, 170, 1))",
      colorHex: "#0089AA",
      colorSecondaryHex: "#33DBEE",
      titleKor: `${texts.Development_of_Industrial_Safety_AI_System}`,
      titleEng: "AI System for Industrial Safety",
      titleEngCap: "AI SYSTEM FOR INDUSTRIAL SAFETY",
      background: "https://picsum.photos/id/1048/1000/1000",
      mainDescription:
        `${texts.business_AI_mainDesc}`,

      businessArea: [
        {
          titleKor: `${texts.business_AI_title1}`,
          description:
            `${texts.business_AI_desc1}`,
        },
        {
          titleKor: `${texts.business_AI_title2}`,
          description:
            `${texts.business_AI_desc2}`,
        },
      ],
      processSectionName: `${texts.business_AI_Interview}`,
      process: [
        {
          title: "Interview",
          content: [`${texts.business_AI_Interview}`],
        },
        {
          title: "Plan",
          content: [`${texts.business_AI_Plan}`],
        },
        {
          title: "Design",
          content: [`${texts.business_AI_process_Design}`],
        },
        {
          title: "Execution",
          content: [`${texts.business_AI_process_Execution}`],
        },
        {
          title: "Validation",
          content: [`${texts.business_AI_process_Validation}`],
        },
      ],
      referenceAreaTitle: `${texts.business_jikgu_referenceAreaTitle}`,
      references: [
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle1}`,
          referenceContent: `${texts.business_jikgu_referenceContent1}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle2}`,
          referenceContent: `${texts.business_jikgu_referenceContent2}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle3}`,
          referenceContent: `${texts.business_jikgu_referenceContent3}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle4}`,
          referenceContent: `${texts.business_jikgu_referenceContent4}`,
        },
      ],
      etcText: `${texts.business_etcText}`,
      partnersImg: [
        "/static/logo/partners/1.png",
        "/static/logo/partners/2.png",
        "/static/logo/partners/3.png",
        "/static/logo/partners/5.png",
        "/static/logo/partners/6.png",
        "/static/logo/partners/7.png",
        "/static/logo/partners/8.png",
        "/static/logo/partners/20.PNG",
        "/static/logo/partners/21.png",
        "/static/logo/partners/22.png",
      ],
    },
    {
      color:
        "linear-gradient(0.25turn, rgba(12, 123, 147, 1), 20%, rgba(12, 123, 147, 0.85), 80%, rgba(12, 123, 147, 1))",
      colorHex: "#0C7B93",
      colorSecondaryHex: "#3FAEC6",
      titleKor: `${texts.IT_Infra}`,
      titleEng: "IT Infra",
      titleEngCap: "IT INFRA",
      background: "https://picsum.photos/id/1048/1000/1000",
      mainDescription:
        `${texts.business_IT_Infra_mainDesc}`,

      businessArea: [
        {
          titleKor: `${texts.business_IT_Infra_title1}`,
          description:
            `${texts.business_IT_Infra_desc1}`,
        },
        {
          titleKor: `${texts.business_IT_Infra_title2}`,
          description:
            `${texts.business_IT_Infra_desc2}`,
        },
      ],
      processSectionName: `${texts.business_IT_Infra_processSectionName}`,
      process: [
        {
          title: "Interview",
          content: [
            `${texts.business_IT_Infra_prcess_Interview_content1}`, 
            `${texts.business_IT_Infra_prcess_Interview_content2}`
          ],
        },
        {
          title: "Plan",
          content: [
            `${texts.business_IT_Infra_prcess_Plan_content1}`,
            `${texts.business_IT_Infra_prcess_Plan_content2}`,
            `${texts.business_IT_Infra_prcess_Plan_content3}`,
          ],
        },
        {
          title: "Contraction",
          content: [`${texts.business_IT_Infra_prcess_Contraction_content1}`],
        },
        {
          title: "Execution",
          content: [
            `${texts.business_IT_Infra_prcess_Execution_content1}`, 
            `${texts.business_physical_process_Licensing_content4}`
          ],
        },
      ],
      referenceAreaTitle: `${texts.business_jikgu_referenceAreaTitle}`,
      references: [
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle1}`,
          referenceContent: `${texts.business_jikgu_referenceContent1}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle2}`,
          referenceContent: `${texts.business_jikgu_referenceContent2}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle3}`,
          referenceContent: `${texts.business_jikgu_referenceContent3}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle4}`,
          referenceContent: `${texts.business_jikgu_referenceContent4}`,
        },
      ],
      etcText: `${texts.business_etcText}`,
      partnersImg: [
        "/static/logo/partners/11.png",
        "/static/logo/partners/15.png",
        "/static/logo/partners/19.png",
        "/static/logo/partners/9.png",
        "/static/logo/partners/10.png",
        "/static/logo/partners/12.png",
        "/static/logo/partners/13.png",
        "/static/logo/partners/14.png",
        "/static/logo/partners/16.png",
        "/static/logo/partners/17.png",
        "/static/logo/partners/18.gif",
        "/static/logo/partners/19.png",
      ],
    },
    {
      color:
        "linear-gradient(0.25turn, rgba(56, 149, 96, 1), 20%, rgba(56, 149, 96, 0.85), 80%, rgba(56, 149, 96, 1))",
      colorHex: "#38955F",
      colorSecondaryHex: "#7CD99F",
      titleKor: `${texts.jikgu}`,
      titleEng: "Direct Trade Platform",
      titleEngCap: "DIRECT TRADE PLATFORM",
      background: "https://picsum.photos/id/1048/1000/1000",
      mainDescription:
        `${texts.business_jikgu_mainDesc}`,
      redirectButtonText: `${texts.business_jikgu_shortcut}`,
      redirectUri: "https://jikguround.com/",
      businessArea: [
        {
          titleKor: `${texts.business_jikgu_title1}`,
          description:
            `${texts.business_jikgu_desc1}`,
        },
        {
          titleKor: `${texts.business_jikgu_title2}`,
          description:
            `${texts.business_jikgu_desc2}`,
        },
        {
          titleKor: `${texts.business_jikgu_title3}`,
          description:
            `${texts.business_jikgu_desc3}`,
        },
        {
          titleKor: `${texts.business_jikgu_title4}`,
          description:
            `${texts.business_jikgu_desc4}`,
        },
      ],
      processSectionName: `${texts.business_jikgu_processSectionName}`,
      process: [
        {
          title: "Interview",
          content: [
            `${texts.business_jikgu_process_Interview_content1}`, 
            `${texts.business_jikgu_process_Interview_content2}`,
          ],
        },
        {
          title: "Plan",
          content: [
            `${texts.business_jikgu_process_Plan_content1}`, 
            `${texts.business_jikgu_process_Plan_content2}`, 
            `${texts.business_jikgu_process_Plan_content3}`],
        },
        {
          title: "Design",
          content: [
            `${texts.business_jikgu_process_Design_content1}`, 
            `${texts.business_jikgu_process_Design_content2}`, 
            `${texts.business_jikgu_process_Design_content3}`, 
            `${texts.business_jikgu_process_Design_content4}`
          ],
        },
        {
          title: "Execution",
          content: [
            `${texts.business_jikgu_process_Execution_content1}`, 
            `${texts.business_jikgu_process_Execution_content2}`, 
            `${texts.business_jikgu_process_Execution_content3}`, 
            `${texts.business_jikgu_process_Execution_content4}`,
          ],
        },
        {
          title: "After Service",
          content: [`${texts.business_jikgu_process_After_Service_content1}`],
        },
      ],
      referenceAreaTitle: `${texts.business_jikgu_referenceAreaTitle}`,
      references: [
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle1}`,
          referenceContent: `${texts.business_jikgu_referenceContent1}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle2}`,
          referenceContent: `${texts.business_jikgu_referenceContent2}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle3}`,
          referenceContent: `${texts.business_jikgu_referenceContent3}`,
        },
        {
          refereceTitle: `${texts.business_jikgu_refereceTitle4}`,
          referenceContent: `${texts.business_jikgu_referenceContent4}`,
        },
      ],
      etcText: `${texts.business_etcText}`,
    },
  ];

  const businessRef = useRef<HTMLDivElement>(null);
  const processRef = useRef<HTMLDivElement>(null);
  const referenceRef = useRef<HTMLDivElement>(null);

  const [scroll, setScroll] = useState(0);
  const [openBusiness, setOpenBusiness] = useState(false);
  const [openProcess, setOpenProcess] = useState(false);
  const [openReference, setOpenReference] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScroll(position);
  };

  useEffect(() => {
    if (params.category) {
      setCategory(Number(params.category));
    }

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (params.category) {
      setCategory(Number(params.category));
    }
  }, [location.pathname]);

  useEffect(() => {
    setOpenBusiness(false);
    setOpenProcess(false);
    setOpenReference(false);
    window.scrollTo(0, 0);

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [category]);

  useEffect(() => {
    if (businessRef.current) {
      if (scroll >= businessRef.current.offsetTop - window.innerHeight / 2) {
        setOpenBusiness(true);
      }
    }

    if (processRef.current) {
      if (scroll >= processRef.current.offsetTop - window.innerHeight / 2) {
        setOpenProcess(true);
      }
    }

    if (referenceRef.current) {
      if (scroll >= referenceRef.current.offsetTop - window.innerHeight / 2) {
        setOpenReference(true);
      }
    }
  }, [scroll]);

  useEffect(() => {
    setIsExpandReference(false);
  }, [category]);

  const groupBy = (data: any, key: string) => {
    return data.reduce(function (carry: any, el: any) {
      var group = el[key];

      if (carry[group] === undefined) {
        carry[group] = [];
      }

      carry[group].push(el);
      return carry;
    }, {});
  };

  return (
    <>
      <BusinessPresenter
        category={category}
        setCategory={setCategory}
        hovered={hovered}
        setHovered={setHovered}
        items={items}
        scroll={scroll}
        businessRef={businessRef}
        processRef={processRef}
        referenceRef={referenceRef}
        openBusiness={openBusiness}
        openProcess={openProcess}
        openReference={openReference}
        groupBy={groupBy}
        isExpandReference={isExpandReference}
        setIsExpandReference={setIsExpandReference}
        isReadMore={isReadMore}
        setIsReadMore={setIsReadMore}
        texts={texts}
      />
    </>
  );
};

BusinessContainer.defaultProps = {};

export default BusinessContainer;
