import { notLoginedApi, loginedApi } from './api-base';

export const userApi = {
  login: (Data: any) => notLoginedApi.post('/user/login', Data),

  register: (Data: any) => notLoginedApi.post('/user/register', Data),

  checkIdDup: (Data: any) => notLoginedApi.post('/user/checkIdDup', Data),

  getUserInfo: (token: any, Data: any) => loginedApi.post('/user/getUserInfo', Data, {
    headers: {
      'X-AUTH-TOKEN': token.accessToken,
      "X-AUTH-REFRESH-TOKEN": token.refreshToken,
    }
  }),
}