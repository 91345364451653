import React, { Dispatch, RefObject, SetStateAction } from "react";
import * as s from "./AboutUsStyled";
import { Link } from "react-router-dom";
import {
  Fa500Px,
  FaAdjust,
  FaAlgolia,
  FaAngleDoubleUp,
  FaChevronDown,
  FaChevronUp,
  FaCreativeCommons,
  FaFlag,
  FaLightbulb,
  FaPen,
} from "react-icons/fa";
import moment from "moment";
import CountUp from "react-countup";

interface AboutUsProps {
  scroll: any;
  milestoneRef: RefObject<HTMLDivElement>;
  commentaryRef: RefObject<HTMLDivElement>;

  openCommentary: boolean;
  openMilestone: boolean;

  items: any[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  handleModal: (item: any) => void;
  dvCd: string;
  setDvCd: Dispatch<SetStateAction<string>>;

  isExpandReference: boolean;
  setIsExpandReference: Dispatch<SetStateAction<boolean>>;

  texts: {
    aboutUs_text1: string,
    aboutUs_text2: string,
    aboutUs_text3: string,
    aboutUs_text4: string,
    aboutUs_text5: string,
    aboutUs_text6: string,
    aboutUs_text7: string,
    aboutUs_text8: string,
    aboutUs_text9: string,
    aboutUs_text10: string,
    aboutUs_text11: string,
    aboutUs_text12: string,
    aboutUs_text13: string,
    aboutUs_text14: string,
    aboutUs_text15: string,
    aboutUs_text16: string,
    Sales_for_2022: string,
    won: string,
    Sales_for_2023: string,
    Net_income_for_2022: string,
    Year_on_year_achievement_rate: string,
    History_of_the_doublt: string,
    Read_more: string,
    Quick_view: string,
    Doublt_News: string,
    Related_Articles: string,
    Announcement: string,
    See_more: string,
    
  };
}

const AboutUsPresenter = (props: AboutUsProps) => {
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#2121AB" offset="0%" />
          <stop stopColor="#6767DE" offset="100%" />
        </linearGradient>
      </svg>

      <s.TopImage src={"/static/img/aboutUs/about1.jpg"} />
      <s.Container>
        <s.TopArea>
          <s.TopWrapper>
            <s.TopTitle>
              {props.texts.aboutUs_text1}
              <br />
              {props.texts.aboutUs_text2}
            </s.TopTitle>
          </s.TopWrapper>
        </s.TopArea>

        <s.MainBusinessIntroduceArea>
          <s.MainBusinessIntroduceTitle>{props.texts.aboutUs_text3}</s.MainBusinessIntroduceTitle>
          <s.MilestoneBackgroundImg style={{ objectFit: 'contain', height: '80rem' }} src="/static/img/heimdall_img.png" />
        </s.MainBusinessIntroduceArea>

        <s.BodyArea>
          <s.MilestoneSection ref={props.milestoneRef}>
            <div id="targetSection2">
              <s.MilestoneBackgroundImg src="/static/img/aboutUs/about2.jpg" />
              <s.MilestoneWrapper>
                <s.MilestoneTitle>
                {props.texts.aboutUs_text4}
                  <br />
                  {props.texts.aboutUs_text5}
                </s.MilestoneTitle>
                <s.MilestoneArea>
                  <s.MilestoneLeft>
                    <s.MilestoneLeftItem>
                      <s.MilestoneLeftItemTitle>
                        {props.texts.Sales_for_2022}
                      </s.MilestoneLeftItemTitle>
                      <s.MilestoneLeftItemText>
                        <CountUp
                          separator=","
                          delay={1}
                          end={9116913084}
                          duration={3}
                        />{" "}
                        {props.texts.won}
                      </s.MilestoneLeftItemText>
                    </s.MilestoneLeftItem>
                    <s.MilestoneLeftItem>
                      <s.MilestoneLeftItemTitle>
                        {props.texts.Sales_for_2023}
                      </s.MilestoneLeftItemTitle>
                      <s.MilestoneLeftItemText>
                        <CountUp
                          separator=","
                          delay={1}
                          end={17450382213}
                          duration={3}
                        />{" "}
                        {props.texts.won}
                      </s.MilestoneLeftItemText>
                    </s.MilestoneLeftItem>
                    <s.MilestoneLeftItem>
                      <s.MilestoneLeftItemTitle>
                        {props.texts.Net_income_for_2022}
                      </s.MilestoneLeftItemTitle>
                      <s.MilestoneLeftItemText>
                        <CountUp
                          separator=","
                          delay={1}
                          end={372579761}
                          duration={3}
                        />{" "}
                        {props.texts.won}
                      </s.MilestoneLeftItemText>
                    </s.MilestoneLeftItem>
                    <s.MilestoneLeftItem>
                      <s.MilestoneLeftItemTitle>
                        {props.texts.Year_on_year_achievement_rate}
                      </s.MilestoneLeftItemTitle>
                      <s.MilestoneLeftItemText>
                        <CountUp
                          decimals={2}
                          delay={1}
                          end={191.42}
                          duration={3}
                        />
                        %
                      </s.MilestoneLeftItemText>
                    </s.MilestoneLeftItem>
                  </s.MilestoneLeft>
                  <s.MilestoneRight></s.MilestoneRight>
                </s.MilestoneArea>
              </s.MilestoneWrapper>
            </div>
          </s.MilestoneSection>
          {/* <s.AgendaSection ref={props.commentaryRef}>
            <s.AgendaPicture src={'https://picsum.photos/id/124/800/1080'}/>
            <s.AgendaContentWrapper expand={props.openCommentary}>
              <s.AgendaTitle>WE ARE S-HIVE</s.AgendaTitle>
              <s.AgendaSubtitle>디자인으로 사람과 기업이<br/>
                함께 성장하는 공간을 만들어 가는 기업<br/>
                (주)에스하이브디자인그룹에 오신 것을 환영합니다.</s.AgendaSubtitle>
              
              <s.AgendaContent>
              (주)에스하이브디자인그룹은 “디자인으로 사람과 기업이 함께 성장하는 공간을 만들어 간다.”라는 경영이념아래<br/>
              기업의 문화와 가치를 표현하고 창출하는 역할을 수행하고 있으며, 우리 삶 속 모든 공간 속에서 접하는 디자인의<br/>
              새로운 패러다임을 써 내려갈 준비를 마쳤습니다.<br/><br/>

              현재 대한민국은 차별화된 디자인 혁신을 원하고 있으며,<br/>
              현재보다는 미래를 꿈꾸는 스마트하고 혁신적인 공간을 기대하고 있습니다.<br/>
              기업은 이에 발맞추어 공간을 통해 기업의 문화와 가치를 전달하고,<br/>
              그 공간에서 사람들이 교류하기를 원합니다.<br/><br/>

              저희 에스하이브디자인그룹은 기업이 추구하는 가치와 문화에 부합하는<br/>
              최적의 건축 및 인테리어 디자인솔루션을 제공합니다.<br/>
              저희만의 <strong>3D 입체공간 및 동선 분석시스템</strong>을 통해 건축설계, 리모델링, 인테리어, 가구 등<br/>
              디자인의 총체적인 솔루션을 제공하고 더불어 자산관리 및 시설관리까지<br/>
              All in One System을 운영하여 고객의 만족을 최우선으로 합니다.<br/><br/>

              디자인 강대국을 목표로 세계경쟁에서 으뜸이 되도록 끊임없는 성장과 발전을 이루어 나가며,<br/>
              디자인의 혁신적인 센세이션으로 많은 사람들을 열광하게 만드는 가장 수준 높은 기업인<br/>
              저희 에스하이브디자인그룹이 기업의 동반자가 되겠습니다.
              </s.AgendaContent>
            </s.AgendaContentWrapper>
          </s.AgendaSection> */}

          <s.HistorySection expand={props.isExpandReference}>
            <s.HistoryBackgroundImg src={"/static/img/aboutUs/about3.jpg"} />
            <s.HistoryWrapper expand={props.isExpandReference}>
              <s.HistoryTitle expand={props.isExpandReference}>
                <div id="targetSection3"></div>
                {props.texts.History_of_the_doublt}
              </s.HistoryTitle>
              <s.YearlyItemWrapper expand={props.isExpandReference}>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemYear>2023</s.YearlyItemYear>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2023.12</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text6}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2023.07</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text7}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2023.01</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text8}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemYear>2022</s.YearlyItemYear>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2022.12</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text9}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2022.05</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text10}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2022.05</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text11}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2022.01</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text12}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true} style={{ marginTop: "7rem" }}>
                  <s.YearlyItemYear>2021</s.YearlyItemYear>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2021.12</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                    {props.texts.aboutUs_text13}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2021.08</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                    {props.texts.aboutUs_text14}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2021.05</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                    {props.texts.aboutUs_text15}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
                <s.YearlyItem expand={true}>
                  <s.YearlyItemContentWrapper>
                    <s.YearlyItemContentDvCd>2021.01</s.YearlyItemContentDvCd>
                    <s.YearlyItemContentMain>
                      {props.texts.aboutUs_text16}
                    </s.YearlyItemContentMain>
                    <s.YearlyItemDot />
                  </s.YearlyItemContentWrapper>
                </s.YearlyItem>
              </s.YearlyItemWrapper>
              <s.OpenReferenceWrapper
                bg={props.isExpandReference}
                onClick={() => {
                  props.setIsExpandReference(!props.isExpandReference);
                }}
              >
                {!props.isExpandReference ? (
                  <>
                    {props.texts.Read_more}
                    <FaChevronDown size={"2rem"} color={"#FFFFFF"} />
                  </>
                ) : (
                  <>
                    {props.texts.Quick_view}
                    <FaChevronUp size={"2rem"} color={"#FFFFFF"} />
                  </>
                )}
              </s.OpenReferenceWrapper>
            </s.HistoryWrapper>
          </s.HistorySection>

          <s.NewsSection>
            <s.FilterArea>
              <div id="targetSection4"></div>
              <s.NewsTitle>{props.texts.Doublt_News}</s.NewsTitle>
              <s.FilterItem
                active={props.dvCd === ""}
                onClick={() => {
                  props.setDvCd("");
                }}
              >
                ALL
              </s.FilterItem>
              <s.FilterItem
                active={props.dvCd === "article"}
                onClick={() => {
                  props.setDvCd("article");
                }}
              >
                {props.texts.Related_Articles}
              </s.FilterItem>
              <s.FilterItem
                active={props.dvCd === "notice"}
                onClick={() => {
                  props.setDvCd("notice");
                }}
              >
                {props.texts.Announcement}
              </s.FilterItem>
            </s.FilterArea>
            <s.ItemArea>
              {props.items.map((item: any, index: number) => {
                return (
                  <s.Item
                    heightSeed={3}
                    onClick={() => props.handleModal(item)}
                  >
                    {moment(item.news_reg_dt).isAfter(
                      moment(new Date()).add(-1, "days")
                    ) ? (
                      <s.NewItemWrapper>
                        <s.NewItem>NEW</s.NewItem>
                        <s.RibbonBottom />
                      </s.NewItemWrapper>
                    ) : null}
                    <s.ItemContentWrapper>
                      <s.ItemDate>
                        {moment(item.news_reg_dt).format("YY.MM")}
                      </s.ItemDate>
                      <s.ItemDay>
                        {moment(item.news_reg_dt).format("DD")}
                      </s.ItemDay>
                      {item.thumbnail ? (
                        <s.ItemInnerContent>
                          <s.ItemThumbnail src={item.thumbnail} />
                          <s.ItemTitle>{item.title}</s.ItemTitle>
                        </s.ItemInnerContent>
                      ) : (
                        <s.ItemInnerContent
                          style={{ justifyContent: "center" }}
                        >
                          <s.ItemTitle>{item.title}</s.ItemTitle>
                          {/* <s.ItemContent>{item.prevContent}</s.ItemContent> */}
                        </s.ItemInnerContent>
                      )}
                    </s.ItemContentWrapper>
                  </s.Item>
                );
              })}
            </s.ItemArea>
            <s.ItemMoreWrapper>
              <s.ItemMore
                onClick={() => {
                  props.setPage(props.page + 1);
                }}
              >
                {props.texts.See_more}
              </s.ItemMore>
              <FaChevronDown size={"1.6rem"} color={"#FFFFFF"} />
            </s.ItemMoreWrapper>
          </s.NewsSection>
        </s.BodyArea>
      </s.Container>
    </>
  );
};

export default AboutUsPresenter;
