import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProjectListStyled';
import { Link } from 'react-router-dom';

interface ProjectListProps {
  category: number;
  setCategory: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  projects: any[];
}

const ProjectListPresenter = (props: ProjectListProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/1076/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>PROJECT</s.TopTitle>
          <s.TopSubtitle>관리자페이지입니다.</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>
      <s.CreateButton to="/admin/project/create">신규등록</s.CreateButton>
      <s.FilterArea>
        <s.FilterItem active={props.category < 0} onClick={() => {props.setCategory(-1);}}>ALL</s.FilterItem>
        <s.FilterItem active={props.category === 1} onClick={() => {props.setCategory(1);}}>OFFICE</s.FilterItem>
        <s.FilterItem active={props.category === 2} onClick={() => {props.setCategory(2);}}>HOSPITAL</s.FilterItem>
        <s.FilterItem active={props.category === 3} onClick={() => {props.setCategory(3);}}>FRANCHISE</s.FilterItem>
        <s.FilterItem active={props.category === 4} onClick={() => {props.setCategory(4);}}>OTHERS</s.FilterItem>
      </s.FilterArea>
      <s.ItemArea>
      {
        props.projects.length > 0 ? 
        <>
        {
          props.projects.map((item: any, index: number) => {
            return (
              <s.Item to={`/admin/project/${item.reference_id}`} color={index%6 === 0 ? '#F0512F' : index%6 === 1 ? '#F0D32F' : index%6 === 2 ? '#9ADA1B' : index%6 === 3 ? '#1BDA9A' : index%6 === 4 ? '#1B8CDA' : index%6 === 5 ? '#3A1BDA' : '#BA1BDA'}>
                <s.ItemImg src={item.thumbnail}/>
                <s.ItemCover>
                  <s.ItemCoverTitle>{item.title}</s.ItemCoverTitle>
                  <s.ItemCoverContent>{item.content}</s.ItemCoverContent>
                </s.ItemCover>
              </s.Item>
            )
          })
        }
        <s.ItemMoreWrapper>
          <s.ItemMore onClick={() => {props.setPage(props.page + 1);}}>MORE</s.ItemMore>
        </s.ItemMoreWrapper>
        </>
        : <s.NoRowsToShow>아직 프로젝트가 없어요!</s.NoRowsToShow>
      }
      </s.ItemArea>
    </s.Container>
  );
};

export default ProjectListPresenter;
