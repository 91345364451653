import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectApi } from '../../api/api-project';
import { qnaApi } from '../../api/api-qna';

import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';

import ProjectPresenter from './ProjectPresenter';

import korean from '../../util/korean.json';
import english from '../../util/english.json';

interface ProjectProps {
  setShowExplainArea: Dispatch<SetStateAction<boolean>>;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setModalItem: Dispatch<SetStateAction<any>>;
  setModalPicture: Dispatch<SetStateAction<any[]>>;
}

const ProjectContainer = (props: ProjectProps) => {
  
  const params = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [category, setCategory] = useState<number>(-1);
  const [page, setPage] = useState<number>(0);
  const [projects, setProjects] = useState<any>([]);

  const userInfo = useRecoilValue(recoilItem.userInfo);
  const [userNm, setUserNm] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [content, setContent] = useState<string>('');

  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    lastpage_msg: '',
    main_Missing_Project: '',
    main_Registration_success: '',
    main_Registration_fail: '',
    project_topSubtitle: '',
    project_noRowToShow: '',
    project_askLetter: '',
    project_name: '',
    project_input_name: '',
    project_email: '',
    project_input_email: '',
    project_content: '',
    project_input_content: '',
    project_send: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  useEffect(() => {
    if (params.category) {
      setCategory(Number(params.category));
    }
    fetchData([]);
  }, []);

  useEffect(() => {
    if (params.category) {
      setCategory(Number(params.category));
    }
    fetchData([]);
  }, [location.pathname]);


  useEffect(() => {
    setProjects([]);
    setPage(0);
    fetchData([]);
  }, [category])

  useEffect(() => {
    fetchData([...projects]);
  }, [page])

  const fetchData = async (projects: any[]) => {
    setIsLoading(true);

    let params = {
      categoryId: category === -1 ? null : category,
      page: page === 0 ? '0' : page
    };

    let res = await projectApi.getProjectList(params);
    if (res.data?.data.rsltCd === '00') {
      setProjects([...projects, ...res.data?.data.list]);
      setIsLoading(false);
    } else {
      if (projects.length > 0) {
        util.makeMsg(`${texts.lastpage_msg}`, 'success');
      }
      setIsLoading(false);
    }
  }

  const handleModal = async (item: any) => {
    let params = {
      referenceId: item.reference_id
    };

    let result = await projectApi.getProjectDetail(params);
    if (result.data?.data.rsltCd === '00') {
      props.setModalPicture(result.data?.data.item.images.map((item:any) => (item.image_path)));
      props.setModalItem(result.data?.data.item);
      props.setModalVisible(true);
      props.setShowExplainArea(true);
    } else {
      util.makeMsg(`${texts.main_Missing_Project}`, 'error');
    }

  }

  const registerQna = async () => {
    let params: any = {};

    if (userInfo.userNm && userInfo.userEmail) {
      params.userNm = userInfo.userNm;
      params.userEmail = userInfo.userEmail;
    } else {
      params.userNm = userNm;
      params.userEmail = userEmail;
    }

    params.content = content;

    let res = await qnaApi.addQna(params);
    if (res.data?.data.rsltCd === '00') {
      util.makeMsg(`${texts.main_Registration_success}`, 'success');
      setUserNm('');
      setUserEmail('');
      setContent('');
    } else {
      util.makeMsg(`${texts.main_Registration_fail}`, 'error');
    }

  }

  return (
    <>
      <ProjectPresenter
        category={category}
        setCategory={setCategory}
        page={page}
        setPage={setPage}
        projects={projects}
        handleModal={handleModal}

        userInfo={userInfo}
        userNm={userNm}
        setUserNm={setUserNm}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        content={content}
        setContent={setContent}
        registerQna={registerQna}

        texts={texts}
      />
    </>
  );
};

ProjectContainer.defaultProps = {};

export default ProjectContainer;
