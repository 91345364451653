import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import AboutUsPresenter from './AboutUsPresenter';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { newsApi } from '../../api/api-news';
import korean from '../../util/korean.json';
import english from '../../util/english.json';

interface AboutUsProps {
  setNewsModalVisible: Dispatch<SetStateAction<boolean>>;
  setNewsModalItem: Dispatch<SetStateAction<any>>;
}

const AboutUsContainer = (props: AboutUsProps) => {
  
  const [scroll, setScroll] = useState(0);
  const [openMilestone, setOpenMilestone] = useState(false);
  const [openCommentary, setOpenCommentary] = useState(false);

  const milestoneRef = useRef<HTMLDivElement>(null);
  const commentaryRef = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState<number>(0);
  const [dvCd, setDvCd] = useState<string>('');

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [items, setItems] = useState<any[]>([]);
  const [isExpandReference, setIsExpandReference] = useState<boolean>(false);

  const navigate = useNavigate();

  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    lastpage_msg: '',
    aboutUs_text1: '',
    aboutUs_text2: '',
    aboutUs_text3: '',
    aboutUs_text4: '',
    aboutUs_text5: '',
    aboutUs_text6: '',
    aboutUs_text7: '',
    aboutUs_text8: '',
    aboutUs_text9: '',
    aboutUs_text10: '',
    aboutUs_text11: '',
    aboutUs_text12: '',
    aboutUs_text13: '',
    aboutUs_text14: '',
    aboutUs_text15: '',
    aboutUs_text16: '',
    Sales_for_2022: '',
    won: '',
    Sales_for_2023: '',
    Net_income_for_2022: '',
    Year_on_year_achievement_rate: '',
    History_of_the_doublt: '',
    Read_more: '',
    Quick_view: '',
    Doublt_News: '',
    Related_Articles: '',
    Announcement: '',
    See_more: '',
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScroll(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    if (milestoneRef.current) {
      if (scroll >= (milestoneRef.current.offsetTop - (milestoneRef.current.offsetHeight/5)) && !openMilestone) {
        setOpenMilestone(true);
      }
    }

    if (commentaryRef.current) {
      if (scroll >= (commentaryRef.current.offsetTop - (commentaryRef.current.offsetHeight/7)) && !openCommentary) {
        setOpenCommentary(true);
      }
    }
    
  }, [scroll])

  useEffect(() => {
    fetchData([]);
  }, []);

  useEffect(() => {
    fetchData([...items]);
  }, [page])

  useEffect(() => {
    setItems([]);
    setPage(0);
    fetchData([]);
  }, [dvCd])

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  const fetchData = async (items: any[]) => {
    setIsLoading(true);

    let params = {
      page: String(page),
      dvCd: dvCd !== '' ? dvCd : null
    };

    let res = await newsApi.getNewsList(params);
    if (res.data?.data.rsltCd === '00') {
      setIsLoading(false);
      let itemsTemp = [];
      for (let i = 0; i < res.data.data.list.length; i++) {
        let item = res.data.data.list[i];
        item.prevContent = replaceAllTags(item.content)
        itemsTemp.push(item);
      }
      setItems([...items, ...itemsTemp]);
    } else {
      if (items.length > 0) {
        util.makeMsg(`${texts.lastpage_msg}`, 'success');
      }
      setIsLoading(false);
    }
  }

  const replaceAllTags = (htmlString: string) => {
    let regImg = /<img(.*?)>/g;
    let regP = /<p>/g;
    let regPStyle = /<p(.*?)">/g;
    let regPClose = /<\/p>/g;
    let regBr = /<br>/g;
    let regA = /<a(.*?)>/g;
    let regAClose = /<\/a>/g;
    let regStrongStyle = /<strong(.*?)">/g;
    let regStrong = /<strong>/g;
    let regStrongClose = /<\/strong>/g;
    let regEm = /<em>/g;
    let regEmClose = /<\/em>/g;
    let regUnderline = /<u>/g;
    let regUnderlineClose = /<\/u>/g;
    let regS = /<s>/g;
    let regSClose = /<\/s>/g;
    let regBlockQuote = /<blockquote>/g;
    let regBlockQuoteClose = /<\/blockquote>/g;
    let regOl = /<ol>/g;
    let regOlClose = /<\/ol>/g;
    let regLi = /<li>/g;
    let regLiClose = /<\/li>/g;
    let regNbsp = /&nbsp;/g;

    let str = htmlString.replaceAll(regImg, '')
    .replaceAll(regP, '')
    .replaceAll(regPStyle, '')
    .replaceAll(regPClose, '')
    .replaceAll(regBr, ' ')
    .replaceAll(regA, '')
    .replaceAll(regAClose, '')
    .replaceAll(regStrong, '')
    .replaceAll(regStrongStyle, '')
    .replaceAll(regStrongClose, '')
    .replaceAll(regEm, '')
    .replaceAll(regEmClose, '')
    .replaceAll(regUnderline, '')
    .replaceAll(regUnderlineClose, '')
    .replaceAll(regS, '')
    .replaceAll(regSClose, '')
    .replaceAll(regBlockQuote, '')
    .replaceAll(regBlockQuoteClose, '')
    .replaceAll(regOl, '')
    .replaceAll(regOlClose, '')
    .replaceAll(regLi, '')
    .replaceAll(regLiClose, '')
    .replaceAll(regNbsp, ' ')
    .replaceAll('.', '. ')


    return str.length > 70 ? str.substring(0, 70).concat('...') : str;
    
  }

  const handleModal = (item: any) => {
    props.setNewsModalItem(item);
    props.setNewsModalVisible(true);
  }

  return (
    <>
      <AboutUsPresenter
      scroll={scroll}
      milestoneRef={milestoneRef}
      commentaryRef={commentaryRef}
      openMilestone={openMilestone}
      openCommentary={openCommentary}

      items={items}
      page={page}
      setPage={setPage}
      handleModal={handleModal}
      dvCd={dvCd}
      setDvCd={setDvCd}

      isExpandReference={isExpandReference}
      setIsExpandReference={setIsExpandReference}

      texts={texts}
      />
    </>
  );
};

AboutUsContainer.defaultProps = {};

export default AboutUsContainer;
