import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SignUpPresenter from './SignUpPresenter';
import * as util from '../../util/util';
import { userApi } from '../../api/api-user';

import { useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';
import korean from '../../util/korean.json';
import english from '../../util/english.json';

const SignUpContainer = () => {
  
  const navigate = useNavigate();

  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConf, setPasswordConf] = useState<string>('');
  const [userNm, setUserNm] = useState<string>('');
  const [emailId, setEmailId] = useState<string>('');
  const [emailDomain, setEmailDomain] = useState<string>('');
  const [currentDomain, setCurrentDomain] = useState<string>('');
  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    signUp_checkId: '',
    signUp_check_password_regularExpression: '',
    signUp_checkPassword: '',
    signUp_checkEmail: '',
    signUp_success: '',
    signUp_Duplicate_Id: '',
    signUp_fail: '',
    signUp_use_id: '',
    signUp_fail_Server_Request: '',
    signUp_member: '',
    signUp_text1: '',
    signUp_text2: '',
    signUp_check_Duplicate: '',
    signUp_check_password_regularExpression_placeholder: '',
    check_password: '',
    signUp_password_input_again: '',
    signUp_incorrect_password: '',
    signUp_name: '',
    Direct_input: '',
    signUp_input_domain: '',
    signUp: '',
    login_inputId: '',
    password: '',
    project_input_name: '',
    project_input_email: '',
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);

  useEffect(() => {
    setEmailDomain('');
  }, [currentDomain])

  const submit = async () => {

    if (!loginId) {
      util.makeMsg(`${texts.signUp_checkId}`, 'error');
      return;
    }

    if (!passwordRegTest(password)) {
      util.makeMsg(`${texts.signUp_check_password_regularExpression}`, 'error');
      return;
    }

    if (password !== passwordConf) {
      util.makeMsg(`${texts.signUp_checkPassword}`, 'error');
      return;
    }

    if (currentDomain === 'manual' && !emailDomain) {
      util.makeMsg(`${texts.signUp_checkEmail}`, 'error');
      return;
    }

    let params = {
      loginId: loginId,
      password: password,
      userNm: userNm,
      userEmail: currentDomain === 'manual' ? emailId + '@' + emailDomain : emailId + '@' + currentDomain,
    }

    let result = await userApi.register(params);
    if (result.data?.data.rsltCd === '00') {
      util.makeMsg(`${texts.signUp_success}`, 'success');
      navigate('/');
    } else if (result.data?.data.rsltCd === '06') {
      util.makeMsg(`${texts.signUp_Duplicate_Id}`, 'error')
    } else {
      util.makeMsg(`${texts.signUp_fail}`, 'error')
    }
  };

  const dupCheck = async () => {

    if (!loginId) {
      util.makeMsg(`${texts.signUp_checkId}`, 'error');
      return;
    }

    let params = {
      loginId: loginId,
    }

    let result = await userApi.checkIdDup(params);
    if (result.data?.data.rsltCd === '00') {
      util.makeMsg(`${texts.signUp_use_id}`, 'success');
    } else if (result.data?.data.rsltCd === '06') {
      util.makeMsg(`${texts.signUp_Duplicate_Id}`, 'error')
    } else {
      util.makeMsg(`${texts.signUp_fail_Server_Request}`, 'error')
    }
  }

  const passwordRegTest = (passwordCode: string) => {
    let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!reg.test(passwordCode)) {
      return false;
    }
    return true;
  }

  const checkEnter = (event: any) => {
    if (event.key === 'Enter') {
      submit();
    }
  }

  return (
    <>
      <SignUpPresenter
        loginId={loginId}
        setLoginId={setLoginId}
        password={password}
        setPassword={setPassword}
        passwordConf={passwordConf}
        setPasswordConf={setPasswordConf}
        userNm={userNm}
        setUserNm={setUserNm}
        emailId={emailId}
        setEmailId={setEmailId}
        emailDomain={emailDomain}
        setEmailDomain={setEmailDomain}
        currentDomain={currentDomain}
        setCurrentDomain={setCurrentDomain}

        dupCheck={dupCheck}
        checkEnter={checkEnter}
        passwordRegTest={passwordRegTest}
        submit={submit}

        texts={texts}

      />
    </>
  );
};

SignUpContainer.defaultProps = {};

export default SignUpContainer;
