import React, { useState, useEffect } from 'react';

import InformationPresenter from './InformationPresenter';
import { useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';
import korean from '../../util/korean.json';
import english from '../../util/english.json';

const InformationContainer = () => {
  
  const [expandCareer, setExpandCareer] = useState<number>(-1);
  const [language, setLanguage] = useRecoilState(recoilItem.locale);
  const [texts, setTexts] = useState({
    information_frontend_detail: '',
    information_to: '',
    information_frontend1_workexplain: '',
    information_frontend1_qualify1: '',
    information_related_major: '',
    information_frontend1_advantage1: '',
    information_frontend1_advantage2: '',
    information_frontend_workexplain1: '',
    information_frontend2_workexplain2: '',
    information_frontend2_qualify1: '',
    information_frontend_advantage1: '',
    information_frontend2_advantage2: '',
    information_frontend2_advantage3: '',
    information_frontend3_team: '',
    information_frontend3_workexplain2: '',
    information_frontend3_qualify1: '',
    information_frontend3_advantage2: '',
    information_frontend3_advantage3: '',
    information_backend1_workexplain1: '',
    information_backend1_workexplain2: '',
    information_backend1_qualify1: '',
    information_backend1_advantage1: '',
    information_backend1_advantage2: '',
    information_backend2_workexplain1: '',
    information_backend2_workexplain2: '',
    information_backend2_qualify1: '',
    information_backend2_qualify2: '',
    information_backend2_advantage1: '',
    information_backend2_advantage2: '',
    information_backend3_team: '',
    information_backend3_workexplain1: '',
    information_backend3_qualify1: '',
    information_backend3_advantage1: '',
    information_backend3_advantage2: '',
    information_Android_workexplain: '',
    information_Android_qualify: '',
    information_Android_advantage: '',
    information_iOS_workexplain: '',
    information_iOS_qualify: '',
    information_iOS_advantage: '',
    information_Management_support_job: '',
    information_Management_support_detail: '',
    information_Management_support_team: '',
    information_Management_support_workexplain1: '',
    information_Management_support_workexplain2: '',
    information_Management_support_workexplain3: '',
    information_topTitle1: '',
    information_topTitle2: '',
    information_topTitle3: '',
    information_RecruitTitle1: '',
    information_RecruitTitle2: '',
    information_Job_Detail: '',
    information_Job_Description: '',
    information_Applicants: '',
    information_preferential_treatment: '',
    information_go_saramin: '',
    information_text1: '',
    information_text2: '',
    information_text3: '',
    information_text4: '',
    information_text5: '',
    information_text6: '',
    information_text7: '',
    information_text8: '',
    information_text9: '',
    information_text10: '',
    information_text11: '', 
    information_text12: '',
    information_text13: '',
    information_text14: '',
    information_text15: '',
    information_text16: '',
    information_text17: '',
    information_text18: '', 
    information_text19: '', 
    information_text20: '', 
    information_text21: '', 
    information_text22: '', 
    information_text23: '', 
    information_text24: '', 
    information_text25: '', 
    information_text26: '',
    information_text27: '', 
    information_text28: '', 
    information_text29: '', 
    information_text30: '', 
    information_text31: '', 
    information_text32: '', 
    information_text33: '', 
    information_text34: ''
  });

  useEffect(() =>{
    if(language === 'ko'){
      setTexts(korean);
    }else{
      setTexts(english);
    }
  }, [language]);  

  return (
    <>
      <InformationPresenter
      expandCareer={expandCareer}
      setExpandCareer={setExpandCareer}

      texts={texts}
      />
    </>
  );
};

InformationContainer.defaultProps = {};

export default InformationContainer;
