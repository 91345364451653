import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import './fonts/font.css';

// 위에서 받은 `normalize`로 기본 css가 초기화 합니다.
const GlobalStyle = createGlobalStyle`
  ${normalize}

  /* CSS reset  */
  /*******************************************************************************************************************************************/
  @charset "UTF-8";
  
  /*******************************************************************************************************************************************/
  
  /*******************************************************************************************************************************************/
  
  @font-face {
    font-family: 'BRBA_B';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.2/BRBA_B.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.2/BRBA_B.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gosanja';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/Gosanja.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/Gosanja.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'GyeonggiTitleM';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/GyeonggiTitleM.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/GyeonggiTitleM.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans KR';
    src: local('NotoSansKR-Regular'), url('./fonts/NotoSansKR-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'GMarketSans';
    src: url('./fonts/GmarketSansTTFLight.ttf') format('ttf'), url('./fonts/GmarketSansTTFMedium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'NanumSquare';
    src: url('./fonts/NanumSquareR.ttf') format('ttf'), url('./fonts/NanumSquareR.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/UbuntuR.ttf') format('ttf'), url('./fonts/UbuntuR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  :root {
    --app-height: 100%;
  }

  html,body, h1, h2, h3, h4, h5, h6, form, fieldset, img, pre {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin:0;padding:0;border:0;
    /* font-family:"Noto Sans KR", sans-serif;  */
    font-family: 'Noto Sans KR', Helvetica, Arial, sans-serif;
    font-size:10px; line-height:1.2;}
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block;}
  
  ul,dl,dt,dd {margin:0;padding:0;list-style:none;}
  legend {position:absolute;margin:0;padding:0;;line-height:0;text-indent:-9999em;overflow:hidden;}
  label, input, button, select, img {vertical-align:middle;}
  input, button {margin:0;padding:0;font-family: 'Noto Sans KR','nanum Gothic','Malgun Gothic', dotum, sans-serif;}
  input[type="submit"]{cursor:pointer}
  button {cursor:pointer}
  
  textarea, select {font-family: 'Noto Sans KR','nanum Gothic','Malgun Gothic', dotum, sans-serif;}
  select {margin:0}
  p {margin:0;padding:0;word-break:break-all}
  hr {display:none}
  pre {overflow-x:scroll;}
  a {color:#222;text-decoration:none}
  
  
  *, :after, :before {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
  }
  *:focus, *:active {
    outline: 0 !important; }

  .mar_t10{margin-top:10px;}
  .mar_t20{margin-top:20px;}
  .swal2-styled.swal2-confirm{
    border: 0 !important;
    border-radius: .25em;
    background: initial;
    background-color: #FEF0DF;
    color:#4d4d4d;
    font-size: 1em;
    outline: 0 !important;
    overflow: hidden;
    box-shadow: none;
  }

  .swal2-styled.swal2-confirm:focus{ 	
  box-shadow: none;
  }

  .swal2-actions:not(.swal2-loading) .swal2-styled:hover{}

  .__react_component_tooltip {
      display: inline-block;
  }

  .react-stars {
      outline: none;
  }

  .__react_component_tooltip.show {
      opacity: 1 !important;
  }

  .Select-menu-outer {
      z-index: 9999999 !important;
  }
  .Select.is-open {
      z-index: 9999999 !important;
  }

  .react-pdf__Page__canvas{
      display: inline-block !important;
      margin-top: 15px auto !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #F46200;
  }

  .content-slider-prev-arrow {
	background:${(props) => `url("/static/img/Option3.png")`};
	background-size: 100% 100%;
  }
  .content-slider-next-arrow {
	background:${(props) => `url("/static/img/Option4.png")`};
	background-size: 100% 100%;
  }
`;

export default GlobalStyle;
