import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import ProjectDetailPresenter from './ProjectDetailPresenter';

import { projectApi } from '../../../../api/api-project';
import * as recoilItem from '../../../../util/recoilitem';
import * as util from '../../../../util/util';

interface ProjectDetailProps {
  setShowExplainArea: Dispatch<SetStateAction<boolean>>;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setModalItem: Dispatch<SetStateAction<any>>;
  setModalPicture: Dispatch<SetStateAction<any[]>>;
}

const ProjectDetailContainer = (props: ProjectDetailProps) => {
  
  const params = useParams();

  const navigate = useNavigate();
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [itemId, setItemId] = useState<any>(null);

  const [origFileList, setOrigFileList] = useState<any[]>([]);

  const [thumbnail, setThumbnail] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [refFiles, setRefFiles] = useState<any[]>([]);
  const [thumbnailPreview, setThumbnailPreview] = useState<string>('/static/img/no_preview.png');

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    refFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, [refFiles]);

  useEffect(() => {
    if (userInfo.userTypeCd !== 'ADMIN') {
      util.makeMsg('관리자만 접근 가능합니다', 'error');
      navigate('/');
    }

    fetchData(params.id);
  }, [])

  useEffect(() => {
    if (thumbnail) {
      let reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      reader.onloadend = () => {
        setThumbnailPreview(String(reader.result));
      }
    }
  }, [thumbnail]);

  const fetchData = async (referenceId: any) => {
    let params = {
      referenceId: referenceId,
    }

    setIsLoading(true);

    let res = await projectApi.getProjectDetail(params);
    if (res.data?.data.rsltCd === '00') {
      let item = res.data.data.item;
      setThumbnailPreview(item.thumbnail);
      setItemId(item.reference_id);
      setTitle(item.title);
      setContent(item.content);
      setCategoryId(item.category_id);
      setOrigFileList(item.images.map((item: any) => ({...item, useYn: 'Y'})));
      setIsLoading(false);
    } else {
      util.makeMsg('삭제된 프로젝트입니다', 'error');
      setIsLoading(false);
      navigate('/admin/project');
    }
  };


  const submit = async () => {

    if (!title) {
      util.makeMsg('제목을 입력해주세요', 'error');
      return;
    }

    if (!content) {
      util.makeMsg('내용을 입력해주세요', 'error');
      return;
    }

    if ((!refFiles || refFiles.length === 0) && origFileList.findIndex((item: any) => item.useYn === 'Y') === -1) {
      util.makeMsg('이미지는 1개 이상 등록하세요', 'error');
      return;
    }

    let params: any = {
      title: title,
      content: content,
      categoryId: categoryId,
      referenceId: itemId
    }

    if (thumbnail) {
      params.thumbnail = await readFileAsDataURL(thumbnail);
    }

    let tempRefFiles: any = [];
    
    for (let i = 0; i < refFiles.length; i++) {
      let base64 = await readFileAsDataURL(refFiles[i]);
      tempRefFiles.push(base64)
    }

    params.refFiles = tempRefFiles;

    params.delImgList = origFileList.filter((item: any) => (item.useYn === 'N')).map((item: any) => (item.image_id));


    let res = await projectApi.updateProject(token, params);
    if (res.data?.data.rsltCd === '00') {
      util.makeMsg('수정에 성공하였습니다', 'success');
      navigate('/admin/project');
    } else {
      util.makeMsg('저장에 실패하였습니다', 'error');
    }
  }

  const deleteOrigFile = async (image_id: any) => {
    let res = await util.askMsg('이 사진을 삭제하시겠습니까?');
    if (res) {
      let files = origFileList;

      files[files.findIndex((item: any) => (item.image_id === image_id))].useYn = 'N';
  
      setOrigFileList([...files]);
    }
  }

  const handleModal = async (index: number, dvCd: string) => {
    if (dvCd === 'R') {
      let base64: any = await readFileAsDataURL(refFiles[index]);
      props.setModalPicture([base64]);
      props.setModalVisible(true);
      props.setShowExplainArea(false);
      props.setModalItem(null);
    } else if (dvCd === 'O') {
      props.setModalPicture([origFileList[index].image_path]);
      props.setModalVisible(true);
      props.setShowExplainArea(false);
      props.setModalItem(null);
    }
    
  }


  async function readFileAsDataURL(file: File) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    return result_base64;
  }

  return (
    <>
      <ProjectDetailPresenter
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        title={title}
        setTitle={setTitle}
        content={content}
        setContent={setContent}
        refFiles={refFiles}
        setRefFiles={setRefFiles}
        thumbnailPreview={thumbnailPreview}
        submit={submit}
        origFileList={origFileList}
        deleteOrigFile={deleteOrigFile}

        handleModal={handleModal}
      />
    </>
  );
};

ProjectDetailContainer.defaultProps = {};

export default ProjectDetailContainer;
