import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 120rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  height: 40rem;
  position: relative;
`;

export const TopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TopWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopTitle = styled.div`
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TopSubtitle = styled.div`
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 1.2rem;
`;


export const SignUpArea = styled.div`
  width: 70%;
  margin: 6rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SignUpTitle = styled.div`
  font-size: 2.5rem;
  color: #4D4D4D;
  font-weight: bold;
`;


export const SignUpTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 1rem;
  border-top: 2px solid #4D4D4D;
  border-bottom: 2px solid #4D4D4D;
  margin: 3rem 0;
`;

export const SignUpTbody = styled.tbody`  
`;

export const SignUpTr = styled.tr`
`;


export const SignUpTh = styled.th`
  width: 25%;
  border-right: 1px solid #DEDEDE;
  text-align: right;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
`;

export const SignUpTdRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const SignUpTd = styled.td`
  width: 75%;
  text-align: left;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
`;

export const SignUpInput = styled.input`
  padding: 0.5rem 1rem;
  width: 60%;
  font-size: 1.4rem;
  color: #4D4D4D;
  placeholder-color: #DEDEDE;
  border: 1px solid #4D4D4D;

  &:focus {
    border: 1px solid #2A79B7;
  }
`;

export const SignUpInputShort = styled.input`
  padding: 0.5rem 1rem;
  width: 25%;
  font-size: 1.4rem;
  color: #4D4D4D;
  placeholder-color: #DEDEDE;
  border: 1px solid #4D4D4D;

  &:focus {
    border: 1px solid #2A79B7;
  }
`;

export const SignUpInputSelect = styled.select`
  padding: 0.5rem 1rem;
  width: 15%;
  font-size: 1.4rem;
  color: #4D4D4D;
  placeholder-color: #DEDEDE;
  border: 1px solid #4D4D4D;
  margin-right: 2rem;

  &:focus {
    border: 1px solid #2A79B7;
  }
`;

export const SubmitButton = styled.div`
  padding: 1.5rem 3rem;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: bold;
  margin: 2rem 0 6rem;
  cursor: pointer;
`;


export const AlertPassword = styled.div`
  font-size: 1.2rem;
  color: #FF0000;
`;

export const DupCheckButton = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
`;