import React from 'react';
import * as s from './NewsListStyled';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import './Paging.css';

interface NewsListProps {
  toItemDetail: (index: any) => void;

  rowData: any[],
  rowCount: number,

  page: number,
  handlePageChange: (page: number) => void,
}

const NewsListPresenter = (props: NewsListProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/id/1076/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>NEWS LIST</s.TopTitle>
          <s.TopSubtitle>관리자페이지입니다.</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>

      <s.BoardItemTable>
        <s.BoardItemThead>
          <s.BoardItemTh style={{width: '5%'}}>순번</s.BoardItemTh>
          <s.BoardItemTh style={{width: '48%'}}>제목</s.BoardItemTh>
          <s.BoardItemTh style={{width: '15%'}}>작성자</s.BoardItemTh>
          <s.BoardItemTh style={{width: '16%'}}>작성일</s.BoardItemTh>
          <s.BoardItemTh style={{width: '16%'}}>수정일</s.BoardItemTh>
        </s.BoardItemThead>
        <s.BoardItemTbody>
          {
            props.rowData && props.rowData.length > 0 ? 
            props.rowData.map((item: any, index: number) => (
            <s.BoardItemTr onClick={() => props.toItemDetail(item.news_id)}>
              <s.BoardItemTd>{(props.page - 1)*10 + index + 1}</s.BoardItemTd>
              <s.BoardItemTd style={{textAlign: 'left'}}>{item.title}</s.BoardItemTd>
              <s.BoardItemTd>관리자</s.BoardItemTd>
              <s.BoardItemTd style={{textAlign: 'right'}}>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</s.BoardItemTd>
              <s.BoardItemTd style={{textAlign: 'right'}}>{moment(item.upd_dtm).format('YYYY-MM-DD HH:mm')}</s.BoardItemTd>
            </s.BoardItemTr>
            )) :
            <s.BoardItemTr>
              <s.BoardItemTd colSpan={5}>뉴스가 없습니다.</s.BoardItemTd>
            </s.BoardItemTr>
          }
        </s.BoardItemTbody>
      </s.BoardItemTable>
      <s.PagingArea>
        <Pagination activePage={props.page} itemsCountPerPage={16} totalItemsCount={props.rowCount} pageRangeDisplayed={5} prevPageText={"‹"} nextPageText={"›"} onChange={props.handlePageChange} />
        <s.NewButton to="/admin/news/create">신규등록</s.NewButton>
      </s.PagingArea>
    </s.Container>
  );
};

export default NewsListPresenter;
