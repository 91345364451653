import React, { Dispatch, SetStateAction } from 'react';
import * as s from './SignUpStyled';
import { Link } from 'react-router-dom';

interface SignUpProps {
  loginId: string;
  setLoginId: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  passwordConf: string;
  setPasswordConf: Dispatch<SetStateAction<string>>;
  userNm: string;
  setUserNm: Dispatch<SetStateAction<string>>;
  emailId: string;
  setEmailId: Dispatch<SetStateAction<string>>;
  emailDomain: string;
  setEmailDomain: Dispatch<SetStateAction<string>>;
  currentDomain: string;
  setCurrentDomain: Dispatch<SetStateAction<string>>;
  checkEnter: (event: any) => void;

  passwordRegTest: (passcode: string) => boolean;
  dupCheck: () => void;
  submit: () => void;

  texts:{
    signUp_member: string,
    signUp_text1: string,
    signUp_text2: string,
    signUp_check_Duplicate: string,
    signUp_check_password_regularExpression_placeholder: string,
    check_password: string,
    signUp_password_input_again: string,
    signUp_incorrect_password: string,
    signUp_name: string,
    Direct_input: string,
    signUp_input_domain: string,
    signUp: string,
    login_inputId: string,
    password: string,
    signUp_check_password_regularExpression: string,
    project_input_name: string,
    project_input_email: string,
  };
}

const SignUpPresenter = (props: SignUpProps) => {
  return (
    <s.Container>
      <s.TopArea>
        <s.TopImage src={'https://picsum.photos/1920/400'}/>
        <s.TopWrapper>
          <s.TopTitle>{props.texts.signUp_member}</s.TopTitle>
          <s.TopSubtitle>{props.texts.signUp_text1}</s.TopSubtitle>
        </s.TopWrapper>
      </s.TopArea>

      <s.SignUpArea>
        <s.SignUpTitle>{props.texts.signUp_text2}</s.SignUpTitle>
        <s.SignUpTable>
          <s.SignUpTbody>
            <s.SignUpTr>
              <s.SignUpTh>ID</s.SignUpTh>
              <s.SignUpTd>
                <s.SignUpTdRowWrapper>
                  <s.SignUpInputShort type="text" placeholder={props.texts.login_inputId} value={props.loginId} onChange={(e) => {props.setLoginId(e.target.value)}} onKeyDown={(e) => {props.checkEnter(e)}}/>
                  <s.DupCheckButton onClick={props.dupCheck}>{props.texts.signUp_check_Duplicate}</s.DupCheckButton>
                </s.SignUpTdRowWrapper>
              </s.SignUpTd>
            </s.SignUpTr>
            <s.SignUpTr>
              <s.SignUpTh>{props.texts.password}</s.SignUpTh>
              <s.SignUpTd>
                <s.SignUpInput type="password" placeholder={props.texts.signUp_check_password_regularExpression_placeholder} value={props.password} onChange={(e) => {props.setPassword(e.target.value)}} onKeyDown={(e) => {props.checkEnter(e)}}/>
                {
                  props.password !== '' && !props.passwordRegTest(props.password) ?
                  <s.AlertPassword>{props.texts.signUp_check_password_regularExpression}</s.AlertPassword>
                  : null
                }
              </s.SignUpTd>
            </s.SignUpTr>
            <s.SignUpTr>
              <s.SignUpTh>{props.texts.signUp_password_input_again}</s.SignUpTh>
              <s.SignUpTd>
                <s.SignUpInput type="password" placeholder={props.texts.signUp_password_input_again} value={props.passwordConf} onChange={(e) => {props.setPasswordConf(e.target.value)}} onKeyDown={(e) => {props.checkEnter(e)}}/>
                {
                  props.passwordConf !== '' && props.password !== props.passwordConf ?
                  <s.AlertPassword>{props.texts.signUp_incorrect_password}</s.AlertPassword>
                  : null
                }
              </s.SignUpTd>
            </s.SignUpTr>
            <s.SignUpTr>
              <s.SignUpTh>{props.texts.signUp_name}</s.SignUpTh>
              <s.SignUpTd>
                <s.SignUpInputShort type="text" placeholder={props.texts.project_input_name} value={props.userNm} onChange={(e) => {props.setUserNm(e.target.value)}} onKeyDown={(e) => {props.checkEnter(e)}}/>
              </s.SignUpTd>
            </s.SignUpTr>
            <s.SignUpTr>
              <s.SignUpTh>E-Mail</s.SignUpTh>
              <s.SignUpTd>
                <s.SignUpInputShort type="text" placeholder={props.texts.project_input_email} value={props.emailId} onChange={(e) => {props.setEmailId(e.target.value)}} onKeyDown={(e) => {props.checkEnter(e)}}/>
                {' @ '}
                <s.SignUpInputSelect value={props.currentDomain} onChange={(e) => {props.setCurrentDomain(e.target.value)}}>
                  <option value="naver.com">naver.com</option>
                  <option value="google.com">google.com</option>
                  <option value="daum.net">daum.net</option>
                  <option value="hanmail.net">hanmail.net</option>
                  <option value="kakao.com">kakao.com</option>
                  <option value="manual">{props.texts.Direct_input}</option>
                </s.SignUpInputSelect>
                {
                  props.currentDomain === 'manual' ?
                  <s.SignUpInputShort type="text" placeholder={props.texts.signUp_input_domain} value={props.emailDomain} onChange={(e) => {props.setEmailDomain(e.target.value)}} onKeyDown={(e) => {props.checkEnter(e)}}/>
                  : null
                }
              </s.SignUpTd>
            </s.SignUpTr>
          </s.SignUpTbody>
        </s.SignUpTable>
        <s.SubmitButton onClick={props.submit}>{props.texts.signUp}</s.SubmitButton>
      </s.SignUpArea>
    </s.Container>
  );
};

export default SignUpPresenter;
