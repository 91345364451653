import React, { useState, useEffect } from 'react';

import AdminPresenter from './AdminPresenter';

import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

const AdminContainer = () => {
  
  const navigate = useNavigate();

  const userInfo = useRecoilValue(recoilItem.userInfo);
  const token = useRecoilValue(recoilItem.token);

  useEffect(() => {
    if (userInfo.userTypeCd !== 'ADMIN') {
      util.makeMsg('관리자만 접근가능합니다.', 'error');
      navigate('/');
    }
  }, []);

  return (
    <>
      <AdminPresenter
      />
    </>
  );
};

AdminContainer.defaultProps = {};

export default AdminContainer;
