import React, { Dispatch, RefObject, SetStateAction } from "react";
import * as s from "./BusinessStyled";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface BusinessProps {
  category: number;
  setCategory: Dispatch<SetStateAction<number>>;
  hovered: number;
  setHovered: Dispatch<SetStateAction<number>>;
  items: any;

  scroll: any;
  businessRef: RefObject<HTMLDivElement>;
  processRef: RefObject<HTMLDivElement>;
  referenceRef: RefObject<HTMLDivElement>;

  openBusiness: boolean;
  openProcess: boolean;
  openReference: boolean;
  groupBy: (data: any, key: string) => any;
  isExpandReference: boolean;
  setIsExpandReference: Dispatch<SetStateAction<boolean>>;

  isReadMore: boolean;
  setIsReadMore: Dispatch<SetStateAction<boolean>>;
  texts: {
    business_physical_title1: string,
    business_field: string,
    View_Route_Management_Use_Cases: string,
    Close_Route_Management_Use_Cases: string,
    show_more: string,
    show_less: string,
    Physical_Security: string,
    business_text1: string,
    business_text2: string,
    business_text3: string,
    business_text4: string,
    business_text5: string,
    business_text6: string,
    business_text7: string,
    business_text8: string,
    business_text9: string,
    business_text10: string,
    business_text11: string,
    business_text12: string,
    business_text13: string,
  }
}

const BusinessPresenter = (props: BusinessProps) => {
  return (
    <s.Container>
      <s.TopAreaWrapper>
        {props.items.map((item: any, index: number) => (
          <s.TopArea
            onClick={() => {
              props.setCategory(index);
            }}
            onMouseEnter={() => {
              props.setHovered(index);
            }}
            onMouseLeave={() => props.setHovered(-1)}
            expand={
              props.hovered === -1 && props.category === index
                ? true
                : props.hovered !== -1 && props.hovered === index
                  ? true
                  : false
            }
          >
            <s.TopImg src={item.background} />
            <s.TopWrapper color={item.color}>
              <s.TopTitle
                expand={
                  props.hovered === -1 && props.category === index
                    ? true
                    : props.hovered !== -1 && props.hovered === index
                      ? true
                      : false
                }
              >
                {item.titleKor}
              </s.TopTitle>
              <s.TopSubtitle
                expand={
                  props.hovered === -1 && props.category === index
                    ? true
                    : props.hovered !== -1 && props.hovered === index
                      ? true
                      : false
                }
              >
                {item.titleEngCap}
              </s.TopSubtitle>
              <s.MainDescriptionWrapper
                expand={
                  props.hovered === -1 && props.category === index
                    ? true
                    : props.hovered !== -1 && props.hovered === index
                      ? true
                      : false
                }
              >
                {item.mainDescription
                  .split("\n")
                  .map((line: any, index: number) => (
                    <s.MainDescription>{line.trim()}</s.MainDescription>
                  ))}
              </s.MainDescriptionWrapper>
              {item.redirectUri ? (
                <s.RedirectButton
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.redirectUri}
                >
                  {item.redirectButtonText}
                </s.RedirectButton>
              ) : null}
            </s.TopWrapper>
            {props.hovered === -1 && props.category === index ? (
              false
            ) : props.hovered !== -1 && props.hovered === index ? (
              false
            ) : true ? (
              <s.TopMinWrapper background={item.colorHex}>
                {item.titleEngCap}
              </s.TopMinWrapper>
            ) : null}
          </s.TopArea>
        ))}
      </s.TopAreaWrapper>

      <s.SectionTitle expand={props.openBusiness}>{props.texts.business_field}</s.SectionTitle>
      <s.BusinessAreaSection
        ref={props.businessRef}
        isGrid={props.category === 4}
        expand={props.openBusiness}
      >
        {props.items[props.category].businessArea.map((item: any) => (
          <s.BusinessAreaItemWrapper>
            <s.BusinessAreaTitle color={props.items[props.category].colorHex}>
              {item.titleKor}
            </s.BusinessAreaTitle>
            {item.description.split("\n").map((line: string) => (
              <s.BusinessAreaContent>{line}</s.BusinessAreaContent>
            ))}
            {item.titleKor === `${props.texts.business_physical_title1}` && (
              <s.ReadMore
                onClick={() => {
                  props.setIsReadMore(!props.isReadMore);
                }}
              >
                {!props.isReadMore ? (
                  <>
                    <s.MoreViewTextWrapper>
                      {props.texts.View_Route_Management_Use_Cases}
                      <FaChevronDown size={"2rem"} color={"#000"} />
                    </s.MoreViewTextWrapper>
                  </>
                ) : (
                  <>
                    <s.MoreViewTextWrapper>
                      {props.texts.Close_Route_Management_Use_Cases}
                      <FaChevronUp size={"2rem"} color={"#000"} />
                    </s.MoreViewTextWrapper>
                    <s.MoreViewImgWrapper>
                      <s.MoreViewItem>
                        <s.MoreViewImg src="/static/logo/partners/swReadMore1.png" />
                      </s.MoreViewItem>
                      <s.MoreViewItem>
                        <s.MoreViewImg src="/static/logo/partners/swReadMore2.png" />
                      </s.MoreViewItem>
                    </s.MoreViewImgWrapper>
                  </>
                )}
              </s.ReadMore>
            )}
          </s.BusinessAreaItemWrapper>
        ))}
      </s.BusinessAreaSection>
      {props.category === 3 ? (
        <s.ProcessArea
          background={props.items[props.category].colorHex}
          ref={props.processRef}
        >
          <s.SectionTitle expand={props.openProcess} color={"#FFFFFF"}>
            {props.items[props.category].processSectionName}
          </s.SectionTitle>
          <s.ProcessWrapper>
            <s.ProcessImg src="/static/img/jikgu_process.png" />
          </s.ProcessWrapper>
        </s.ProcessArea>
      ) : props.category === 0 ? (
        <s.ProcessArea
          background={props.items[props.category].colorHex}
          ref={props.processRef}
        >
          <s.SectionTitle expand={props.openProcess} color={"#FFFFFF"}>
            {props.items[props.category].processSectionName}
          </s.SectionTitle>
          <s.ReferenceDataCenterWrapper>
            {props.items[props.category].references.map((item: any) => (
              <s.ReferenceDataCenterItem>
                <s.ReferenceDataCenterItemTitle color={"#FFFFFF"}>
                  {item.refereceTitle}
                </s.ReferenceDataCenterItemTitle>
                <s.ReferenceDataCenterItemContent color={"#FFFFFF"}>
                  {item.referenceContent}
                </s.ReferenceDataCenterItemContent>
              </s.ReferenceDataCenterItem>
            ))}
          </s.ReferenceDataCenterWrapper>
        </s.ProcessArea>
      ) : props.category === 1 || props.category === 2 ? (
        <s.ProcessArea
          background={props.items[props.category].colorHex}
          ref={props.processRef}
        >
          <s.SectionTitle expand={props.openProcess} color={"#FFFFFF"}>
            {props.items[props.category].processSectionName}
          </s.SectionTitle>
          <s.PartnersAreaClickSection expand={props.isExpandReference}>
            {props.items[props.category].partnersImg.map(
              (item: any, index: number) => {
                return (
                  <s.PartnersItem>
                    <s.PartnersImg src={item} />
                  </s.PartnersItem>
                );
              }
            )}
          </s.PartnersAreaClickSection>
          <s.OpenReferenceWrapper
            bg={props.isExpandReference}
            onClick={() => {
              props.setIsExpandReference(!props.isExpandReference);
            }}
          >
            {!props.isExpandReference ? (
              <>
                {props.texts.show_more}
                <FaChevronDown size={"2rem"} color={"#FFFFFF"} />
              </>
            ) : (
              <>
                {props.texts.show_less}
                <FaChevronUp size={"2rem"} color={"#FFFFFF"} />
              </>
            )}
          </s.OpenReferenceWrapper>
        </s.ProcessArea>
      ) : null}

      {props.category === 3 ? (
        <s.ReferenceArea ref={props.referenceRef}>
          <s.SectionTitle expand={props.openReference} color={"#000000"}>
            {props.items[props.category].referenceAreaTitle}
          </s.SectionTitle>
          <s.ReferenceDataCenterWrapper>
            {props.items[props.category].references.map((item: any) => (
              <s.ReferenceDataCenterItem>
                <s.ReferenceDataCenterItemTitle
                  color={props.items[props.category].colorHex}
                >
                  {item.refereceTitle}
                </s.ReferenceDataCenterItemTitle>
                <s.ReferenceDataCenterItemContent>
                  {item.referenceContent}
                </s.ReferenceDataCenterItemContent>
              </s.ReferenceDataCenterItem>
            ))}
          </s.ReferenceDataCenterWrapper>
        </s.ReferenceArea>
      ) : props.category === 0 ? (
        <>
          <s.ReferenceArea ref={props.referenceRef}>
            <s.SectionTitle expand={props.openReference} color={"#000000"}>
              {props.items[props.category].referenceAreaTitle}
            </s.SectionTitle>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                {props.texts.Physical_Security}
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text1}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                {props.texts.Physical_Security}
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text2}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                {props.texts.Physical_Security}
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text3}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                {props.texts.Physical_Security}
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text4}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                {props.texts.Physical_Security}
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text5}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text6}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text7}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text7} (KIOSK)
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text9}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text10}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text11}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text12}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
            <s.YearlyItemContentWrapper
              align={"flex-start"}
              color={props.items[props.category].colorHex}
            >
              <s.YearlyItemContentDvCd
                color={props.items[props.category].colorHex}
              >
                WEB/APP
              </s.YearlyItemContentDvCd>
              <s.YearlyItemContentMain>
                {props.texts.business_text13}
              </s.YearlyItemContentMain>
            </s.YearlyItemContentWrapper>
          </s.ReferenceArea>
          {/* 개발에 펼쳐보기 협력사 추가 */}
          <s.ProcessArea
            background={props.items[props.category].colorHex}
            ref={props.processRef}
          >
            <s.SectionTitle expand={props.openProcess} color={"#FFFFFF"}>
              {props.items[props.category].partnerSectionName}
            </s.SectionTitle>
            <s.PartnersAreaClickSection expand={props.isExpandReference}>
              {props.items[props.category].partnersImg.map(
                (item: any, index: number) => {
                  return (
                    <s.PartnersItem>
                      <s.PartnersImg src={item} />
                    </s.PartnersItem>
                  );
                }
              )}
            </s.PartnersAreaClickSection>
            <s.OpenReferenceWrapper
              bg={props.isExpandReference}
              onClick={() => {
                props.setIsExpandReference(!props.isExpandReference);
              }}
            >
              {!props.isExpandReference ? (
                <>
                  {props.texts.show_more}
                  <FaChevronDown size={"2rem"} color={"#FFFFFF"} />
                </>
              ) : (
                <>
                  {props.texts.show_less}
                  <FaChevronUp size={"2rem"} color={"#FFFFFF"} />
                </>
              )}
            </s.OpenReferenceWrapper>
          </s.ProcessArea>
        </>
      ) : null}
    </s.Container>
  );
};

export default BusinessPresenter;
